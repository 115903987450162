'use strict';
const DID_API = require('./api.json')
// const DID_API = './api.json'
// import DID_API from './api.json' assert { type: 'json' };

// if (DID_API.key == '🤫') alert('Please put your api key inside ./api.json and restart..');

const RTCPeerConnection = (
  window.RTCPeerConnection ||
  window.webkitRTCPeerConnection ||
  window.mozRTCPeerConnection
).bind(window);

let peerConnection;
let streamId;
let sessionId;
let sessionClientAnswer;

let statsIntervalId;
let videoIsPlaying;
let lastBytesReceived;

let msgChange2;

let img_url = '';
let video_url;
let did;
let isConnected;

const talkVideo = document.getElementById('talk-video');
// talkVideo.setAttribute('playsinline', '');
// const peerStatusLabel = document.getElementById('peer-status-label');
// const iceStatusLabel = document.getElementById('ice-status-label');
// const iceGatheringStatusLabel = document.getElementById('ice-gathering-status-label');
// const signalingStatusLabel = document.getElementById('signaling-status-label');
// const streamingStatusLabel = document.getElementById('streaming-status-label');

// const connectButton = document.getElementById('connect-button');
// console.log('按钮',document.createElement('button'))
// connectButton.onclick = async () => {
export async function connectStream (stateChange,msgChange,img_url2,video_url2,did2) {
  img_url = img_url2;
  video_url = video_url2;
  did = did2;
  isConnected = false;
  console.log('clickc',img_url,video_url,did)
  msgChange2 = msgChange;
  if (peerConnection && peerConnection.connectionState === 'connected') {
    destroy();
    console.log('已经有了')
    msgChange2('isHave')
    isConnected = true;
    return;
  }
  console.log('已经有了海之星吗',isConnected)
  console.log(img_url,video_url,did)
  stopAllStreams();
  closePC();

  const sessionResponse = await fetchWithRetries(`${DID_API.url}/talks/streams`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${DID_API.key}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      // source_url: 'https://d-id-public-bucket.s3.amazonaws.com/or-roman.jpg',
      source_url: img_url,
    }),
  });

  const { id: newStreamId, offer, ice_servers: iceServers, session_id: newSessionId } = await sessionResponse.json();
  streamId = newStreamId;
  sessionId = newSessionId;

  try {
    sessionClientAnswer = await createPeerConnection(offer, iceServers,stateChange);
  } catch (e) {
    console.log('error during streaming setup', e);
    stopAllStreams();
    closePC();
    return;
  }
console.log(sessionClientAnswer)
  const sdpResponse = await fetch(`${DID_API.url}/talks/streams/${streamId}/sdp`, {
    method: 'POST',
    headers: {
      Authorization: `Basic ${DID_API.key}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      answer: sessionClientAnswer,
      session_id: sessionId,
    }),
  });
  console.log(sdpResponse)
}

// const talkButton = document.getElementById('talk-button');
// talkButton.onclick = async () => {
//   // connectionState not supported in firefox
//   if (peerConnection?.signalingState === 'stable' || peerConnection?.iceConnectionState === 'connected') {
//     const talkResponse = await fetchWithRetries(`${DID_API.url}/talks/streams/${streamId}`, {
//       method: 'POST',
//       headers: {
//         Authorization: `Basic ${DID_API.key}`,
//         'Content-Type': 'application/json',
//       },
//       body: JSON.stringify({
//         script: {
//           type: 'audio',
//           audio_url: 'https://d-id-public-bucket.s3.us-west-2.amazonaws.com/webrtc.mp3',
//         },
//         driver_url: 'bank://lively/',
//         config: {
//           stitch: true,
//         },
//         session_id: sessionId,
//       }),
//     });
//   }
// };

export async function sendMsg (text) {
  console.log('文字',text)
  // connectionState not supported in firefox
  if (peerConnection?.signalingState === 'stable' || peerConnection?.iceConnectionState === 'connected') {
    const talkResponse = await fetchWithRetries(`${DID_API.url}/talks/streams/${streamId}`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${DID_API.key}`,
        'Content-Type': 'application/json',
      },
      // body: JSON.stringify({
      //   script: {
      //     // type: 'audio',
      //     // audio_url: 'https://d-id-public-bucket.s3.us-west-2.amazonaws.com/webrtc.mp3',
      //     type: 'text',
      //     text:text
      //   },
      //   driver_url: 'bank://lively/',
      //   config: {
      //     stitch: true,
      //   },
      //   session_id: sessionId,
      // }),
      body: JSON.stringify({
        script: {
          type: 'text',
          input: text,
          subtitles: 'false',
          provider: { type: 'microsoft', voice_id: did },
          ssml: 'false'
        },
        config: { fluent: 'false', pad_audio: '0.0' },
        session_id: sessionId,
      }),
    });
    console.log('talkResponse发文字结果', talkResponse)
  }
}

// const destroyButton = document.getElementById('destroy-button');
// destroyButton.onclick = async () => {
export async function destroy () {
  await fetch(`${DID_API.url}/talks/streams/${streamId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Basic ${DID_API.key}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ session_id: sessionId }),
  });

  stopAllStreams();
  closePC();
}

function onIceGatheringStateChange() {
  // iceGatheringStatusLabel.innerText = peerConnection.iceGatheringState;
  // iceGatheringStatusLabel.className = 'iceGatheringState-' + peerConnection.iceGatheringState;
}
function onIceCandidate(event) {
  console.log('onIceCandidate', event);
  if (event.candidate) {
    const { candidate, sdpMid, sdpMLineIndex } = event.candidate;

    fetch(`${DID_API.url}/talks/streams/${streamId}/ice`, {
      method: 'POST',
      headers: {
        Authorization: `Basic ${DID_API.key}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        candidate,
        sdpMid,
        sdpMLineIndex,
        session_id: sessionId,
      }),
    });
  }
}

function onIceConnectionStateChange(stateChange) {
  stateChange(peerConnection.iceConnectionState)
  console.log('状态',peerConnection.iceConnectionState)
  console.log('状态状态',peerConnection)
  // iceStatusLabel.innerText = peerConnection.iceConnectionState;
  // iceStatusLabel.className = 'iceConnectionState-' + peerConnection.iceConnectionState;
  if (peerConnection.iceConnectionState === 'failed' || peerConnection.iceConnectionState === 'closed') {
    stopAllStreams();
    closePC();
  }
}
function onConnectionStateChange() {
  console.log('状态222',peerConnection.connectionState)
  // not supported in firefox
  // peerStatusLabel.innerText = peerConnection.connectionState;
  // peerStatusLabel.className = 'peerConnectionState-' + peerConnection.connectionState;
}
function onSignalingStateChange() {
  console.log('状333',peerConnection.signalingState)
  // signalingStatusLabel.innerText = peerConnection.signalingState;
  // signalingStatusLabel.className = 'signalingState-' + peerConnection.signalingState;
}

function onVideoStatusChange(videoIsPlaying, stream) {
  let status;
  if (videoIsPlaying) {
    status = 'streaming';
    const remoteStream = stream;
    setVideoElement(remoteStream);
  } else {
    status = 'empty';
    playIdleVideo();
  }
  console.log('视频状态',status)
  msgChange2(status)
  // streamingStatusLabel.innerText = status;
  // streamingStatusLabel.className = 'streamingState-' + status;
}

function onTrack(event) {
  /**
   * The following code is designed to provide information about wether currently there is data
   * that's being streamed - It does so by periodically looking for changes in total stream data size
   *
   * This information in our case is used in order to show idle video while no talk is streaming.
   * To create this idle video use the POST https://api.d-id.com/talks endpoint with a silent audio file or a text script with only ssml breaks 
   * https://docs.aws.amazon.com/polly/latest/dg/supportedtags.html#break-tag
   * for seamless results use `config.fluent: true` and provide the same configuration as the streaming video
   */

  if (!event.track) return;

  statsIntervalId = setInterval(async () => {
    // console.log('是什么这一行',event)
    if (event.track.readyState == 'ended') {
      msgChange2('ended',statsIntervalId)
      clearInterval(statsIntervalId);
      while (statsIntervalId > 0) {
        console.log('循环销毁定时器啊',statsIntervalId)
        clearInterval(statsIntervalId);
        statsIntervalId--
      }
      return
    }
    const stats = await peerConnection.getStats(event.track);
    stats.forEach((report) => {
      if (report.type === 'inbound-rtp' && report.mediaType === 'video') {
        const videoStatusChanged = videoIsPlaying !== report.bytesReceived > lastBytesReceived;

        if (videoStatusChanged) {
          videoIsPlaying = report.bytesReceived > lastBytesReceived;
          onVideoStatusChange(videoIsPlaying, event.streams[0]);
        }
        lastBytesReceived = report.bytesReceived;
      }
    });
  }, 500);
}

async function createPeerConnection(offer, iceServers,stateChange) {
  if (!peerConnection) {
    peerConnection = new RTCPeerConnection({ iceServers });
    peerConnection.addEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
    peerConnection.addEventListener('icecandidate', onIceCandidate, true);
    peerConnection.addEventListener('iceconnectionstatechange', onIceConnectionStateChange(stateChange), true);
    peerConnection.addEventListener('connectionstatechange', onConnectionStateChange, true);
    peerConnection.addEventListener('signalingstatechange', onSignalingStateChange, true);
    peerConnection.addEventListener('track', onTrack, true);
  }

  await peerConnection.setRemoteDescription(offer);
  console.log('set remote sdp OK');

  const sessionClientAnswer = await peerConnection.createAnswer();
  console.log('create local sdp OK');

  await peerConnection.setLocalDescription(sessionClientAnswer);
  console.log('set local sdp OK');

  return sessionClientAnswer;
}

function setVideoElement(stream) {
  if (!stream) return;
  talkVideo.srcObject = stream;
  talkVideo.loop = false;

  // safari hotfix
  if (talkVideo.paused) {
    talkVideo
      .play()
      .then((e) => {console.log(e)})
      .catch((e) => {console.log(e)});
  }
}

function playIdleVideo() {
  talkVideo.srcObject = undefined;
  talkVideo.src = video_url;
  // talkVideo.src = require('./or_idle.mp4');
  talkVideo.loop = true;
  talkVideo
      .play()
}

function stopAllStreams() {
  console.log('stopping video streams??? ',talkVideo,talkVideo == null);
  if (talkVideo == null) return;
  if (talkVideo.srcObject) {
    console.log('stopping video streams');
    talkVideo.srcObject.getTracks().forEach((track) => track.stop());
    talkVideo.srcObject = null;
  }
}

function closePC(pc = peerConnection) {
  console.log('关闭PC')
  isConnected = false
  if (!pc) return;
  console.log('stopping peer connection');
  pc.close();
  pc.removeEventListener('icegatheringstatechange', onIceGatheringStateChange, true);
  pc.removeEventListener('icecandidate', onIceCandidate, true);
  pc.removeEventListener('iceconnectionstatechange', onIceConnectionStateChange, true);
  pc.removeEventListener('connectionstatechange', onConnectionStateChange, true);
  pc.removeEventListener('signalingstatechange', onSignalingStateChange, true);
  pc.removeEventListener('track', onTrack, true);
  clearInterval(statsIntervalId);
  console.log('销毁定时器啊',statsIntervalId)
  while (statsIntervalId > 0) {
    console.log('循环销毁定时器啊',statsIntervalId)
    clearInterval(statsIntervalId);
    statsIntervalId--
  }
  // iceGatheringStatusLabel.innerText = '';
  // signalingStatusLabel.innerText = '';
  // iceStatusLabel.innerText = '';
  // peerStatusLabel.innerText = '';
  console.log('stopped peer connection');
  if (pc === peerConnection) {
    peerConnection = null;
  }
}

const maxRetryCount = 3;
const maxDelaySec = 4;

async function fetchWithRetries(url, options, retries = 1) {
  try {
    return await fetch(url, options);
  } catch (err) {
    if (retries <= maxRetryCount) {
      const delay = Math.min(Math.pow(2, retries) / 4 + Math.random(), maxDelaySec) * 1000;

      await new Promise((resolve) => setTimeout(resolve, delay));

      console.log(`Request failed, retrying ${retries}/${maxRetryCount}. Error ${err}`);
      return fetchWithRetries(url, options, retries + 1);
    } else {
      throw new Error(`Max retries exceeded. error: ${err}`);
    }
  }
}

// module.exports = {
//   closePC(){console.log('111111')}
// }

 