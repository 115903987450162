<template>
  <router-view v-if="routerAlive"></router-view>
</template>

<script>
export default {
  provide() {
        return {
            reload: this.reload,
        }
    },

    data() {
        return { routerAlive: true }
    },

    methods: {
        //重新加载的方法
        reload() {
            this.routerAlive = false
            this.$nextTick(function () {
                this.routerAlive = true
            })
        },
    },
}
</script>

<style>

</style>
