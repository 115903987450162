import Vue from 'vue'
import App from './App.vue'
import './plugins/element.js'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// import 'base.css'
import '@/assets/css/base.css'
import router from './router'//新增
import '@/assets/css/font-awesome.min.css';
import Vuex from 'vuex' // 1 引入vuex
import store from './store';
import VueClipboard from 'vue-clipboard2'
// import { debounce } from 'vue-debounce';  
// Vue.prototype.$debounce = debounce;
Vue.use(ElementUI);
Vue.config.productionTip = false
Vue.use(Vuex) // 2 注册vuex
Vue.use(VueClipboard)


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
