<template>
  <div class="t-content">
    <div class="t-bg-jb">
        <div class="t-bg scroll">
        <div class="close" @click="close()"></div>
        <div class="t-title"></div>
        <div style="clear:both;"></div>
        <div class="lang-block">
                <h3>画质提高</h3>
                <li :class="s_l1.indexOf(item) != -1? 'selected':''" v-for="(item, index) in list1" :key="index"  @click="tip1Click(index)">{{ item }}</li>
         </div>
         <div style="clear:both;"></div>
         <div class="lang-block">
                <h3>镜头构图</h3>
                <li :class="s_l2.indexOf(item) != -1? 'selected':''" v-for="(item, index) in list2" :key="index"  @click="tip2Click(index)">{{ item }}</li>
         </div>
         <div style="clear:both;"></div>
         <div class="lang-block">
                <h3>人物及主体</h3>
                <li :class="s_l3.indexOf(item) != -1? 'selected':''" v-for="(item, index) in list3" :key="index"  @click="tip3Click(index)">{{ item }}</li>
         </div>

    </div>

    </div>
  </div>
</template>

<script>
export default {
    props:['close','newClick','cancelClick'],
    data(){
        return{
            list1:['高分辨率','极高分辨率','壁纸','增加细节'],
            list2:['全景','风景镜头(远景)','全景镜头(广角镜头)','中景','全身项','上半身','下半身','特写','微距摄像','近景','正面视角','侧面视角','景深(协调人景)','镜头光晕','背景虚化/散景'],
            list3:['有领衬衫','T恤','西装','旗袍','毛衣','连衣裙','短裙','背心(正式)','夹克衫','风衣','羽绒服','运动服','短裙','牛仔裤','运动鞋','高跟鞋','马丁靴','单鞋','鸭舌帽','耳环','头巾','贝雷帽','发带','直发','卷发','波浪卷','高马尾','双马尾','丸子头','鸟窝头丨爆炸头','银发'],
            s_l1:[],
            s_l2:[],
            s_l3:[],
        }
    },
    mounted(){
    },
    methods:{
        tip1Click(index){
            var item = this.list1[index];
            var deleItem;
            var addItem;
            if (this.s_l1.indexOf(item) != -1) {
                this.s_l1.splice(this.s_l1.indexOf(item),1);
                deleItem = item;
            }else{
                this.s_l1.push(item);
                addItem = item;
            }
            this.submit(deleItem,addItem)
        },
        tip2Click(index){
            var item = this.list2[index];
            var deleItem;
            var addItem;
            if (this.s_l2.indexOf(item) != -1) {
                this.s_l2.splice(this.s_l2.indexOf(item),1);
                deleItem = item;
            }else{
                this.s_l2.push(item);
                addItem = item;
            }
            this.submit(deleItem,addItem)
        },
        tip3Click(index){
            var item = this.list3[index];
            var deleItem;
            var addItem;
            if (this.s_l3.indexOf(item) != -1) {
                this.s_l3.splice(this.s_l3.indexOf(item),1);
                deleItem = item;
            }else{
                this.s_l3.push(item);
                addItem = item;
            }
            this.submit(deleItem,addItem)
        },
        submit(deleItem,addItem){
            this.$emit('submit',[...this.s_l1,...this.s_l2,...this.s_l3],deleItem,addItem);
        },
    }
}
</script>

<style scoped>
.t-content{
    position: fixed;
    display: flex;
    top: 100px;
    transform: translateX(-50%);
    left: 63%;
    /* width: 100%; */
    /* height: 100%; */
    /* background-color: rgba(0, 0, 0, 0.3); */
    z-index: 9;
    justify-content: center;
    align-items: center;padding: 10px;
}
.t-bg-jb{
    width: 640px;
    height: 600px;
    border-radius: 20px;
    position: relative;
    background: linear-gradient(to bottom, #D6F2D6, #FC7C7D);
    padding: 2px;
}
.t-bg{
    width: 620px;
    height: 540px;
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    padding: 30px 10px;
    /* overflow-y: scroll; */
}
.close{
    background: url('/src/assets/img/textToPicture/close.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 30px;
    right: 20px;
}

.t-title{
    background: url('/src/assets/img/textToPicture/textTipsIcon.png');
    background-size: cover;
    width: 151px;
    height: 26px;
    margin-left: 20px;
}

.lang-block { display: block; position:relative;    padding: 5px 15px;}
        .lang-block h1 {font-size:13px; margin:0 0 7px 0; padding:0 0 0px 5px; border-left: 2px solid #000;}
        .lang-block h3 {font-size:13px; margin:0; padding:0 0 7px 7px; font-weight: 400}
        .lang-block li{
            float: left; 
            padding: 5px 15px; 
            margin: 8px 5px; 
            background: #f2f2f5; 
            border-radius: 30px; font-size: 12px; list-style: none; 
            /* border:1px solid #f2f2f5;  */
            color:#555;
            height: 25px;
            line-height: 25px;
            position: relative;
            cursor: pointer
        }

.selected{
    background-color: #000 !important;
    color: #fff !important;
}
</style>