<template>
  <div class="bgView">
    <div class="czBgView">
      <div class="czTitle">
        <div class="czTitle-t cz-title-1"></div>
        <div class="czTitle-close" @click="cancel"></div>
      </div>
      <div class="line2"></div>
      <div class="czView">
        <div
          :class="sItem == index ? 'czItem-s' : 'czItem'"
          v-for="(item, index) in items"
          :key="index"
          @click="itemClick(index)"
        >
          <div class="czJP">
            {{ item.brush_num }}
            <div class="czJP-icon"></div>
          </div>
          <div class="money">￥{{ item.money_yuan }}</div>
        </div>
      </div>
      <div class="btns">
        <div class="btn-l" @click="cancel">取消</div>
        <div class="btn-r" @click="czClick">充值</div>
      </div>
      <div class="Vipuh" @click="godyClick">会员享受更多优惠,去查看吧</div>
    </div>

    <div class="zfbox" v-if="isCz">
      <div class="czqr">
        <div class="czTitle">
          <div class="czTitle-t cz-title-2"></div>
          <div class="czTitle-close" @click="gbcz"></div>
        </div>
        <div class="line2"></div>
        <div class="qrcode2" ref="qrCodeUrl"></div>
        <div class="qrDesc">请使用微信扫码支付购买胶片</div>
        <div class="czBtn" @click="Finish">完成</div>
      </div>
    </div>
    <tip
      v-show="dialogVisible"
      :tiptext="tiptext"
      :torf="torf"
      :gbtip="gbtip"
    ></tip>
  </div>
</template>

<script>
import api from "@/api/index";
import QRCode from "qrcodejs2";
import _ from "lodash";
import tip from "@/components/tip.vue";

export default {
  // props: {
  //     cancelBtn: {
  //         type: Function,
  //         default: () => { }
  //     }
  // },
  components: {tip},
  props: ["cancel", "godyClick", "czFinish"],
  data() {
    return {
      items: [],
      sItem: 0,
      isCz: false,
      timer: "",
      dialogVisible: false,
      tiptext: "",
      torf: 1,
    };
  },
  created() {
    this.getCzList();
  },
  methods: {
    gbtip() {
      this.dialogVisible = false;
    },
    async getCzList() {
      let res = await api.getCzList();
      console.log("充值列表", res);
      this.items = res.data;
    },
    itemClick(i) {
      console.log(i);
      this.sItem = i;
    },
    // cancel(){
    //     props.cancelBtn();
    // },
    // czClick() {
    //   var item = this.items[this.sItem];
    //   console.log(item);

    //   this.getCzQrcode(item.id);
    // },
    gbcz() {
      this.isCz = false;
      clearInterval(this.timer);
      this.getUserInfo();
      this.czFinish();
    },
    Finish() {
      console.log(this.props);
      this.isCz = false;
      clearInterval(this.timer);
      this.getUserInfo();
      this.czFinish();
    },

    czClick: _.throttle(function () {
      this.shengc();
    }, 1000),

    shengc() {
      var item = this.items[this.sItem];
      console.log(item);

      this.getCzQrcode(item.id);
    },

    async getUserInfo() {
      let res = await api.getUserInfo();
      console.log("用户信息", res);
      var user = res.data;
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.$store.commit("updateUser", user);
    },

    async getCzQrcode(id) {
      let res = await api.pay(id);
      let order_no = res.data.order_no;
      console.log("充值二维码", res, order_no);

      var url = res.data.url;
      this.isCz = true;

      var that = this;
      this.$nextTick(() => {
        // 清除qrcode
        if (that.$refs.qrCodeUrl.innerHTML) {
          that.$refs.qrCodeUrl.innerHTML = "";
        }
        new QRCode(that.$refs.qrCodeUrl, {
          text: url,
          width: 150,
          height: 150,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });

      this.timer = setInterval(() => {
        this.getOrderStatusByOrderNos(res.data.order_no);
      }, 3000);

      // this.timer=window.setInterval(() => {
      //   setTimeout(() => {
      //     api.getOrderStatusByOrderNo({order_no:res.data.order_no}).then((res) => {
      //         if (res.data.code == '500') {
      //           this.$msgByResponse(res.data);
      //           clearInterval(this.timer);
      //         }
      //     })
      //   }, 1)
      //   }, 5000)

      // setTimeout(() => {
      //   console.log("二维码", that.$refs);
      //   new QRCode(that.$refs.qrCodeUrl, {
      //     text: url, //需要转换为二维码的内容
      //     width: 150, //二维码宽度
      //     height: 150, //二维码高度
      //     colorDark: "#000000", //前景色
      //     colorLight: "#ffffff", //后景色
      //     correctLevel: QRCode.CorrectLevel.L, //容错级别
      //   });
      // }, 0);
    },

    async getOrderStatusByOrderNos(value) {
      const data = await api.getOrderStatusByOrderNo({ order_no: value });
      if (data.data.status === 1) {
        this.dialogVisible = true;
        this.tiptext = "充值成功";
        this.torf = 1;
        clearInterval(this.timer);
        setTimeout(() => {
          this.Finish();
        }, 1000);
      }
      if (data.data.status === 2) {
        this.dialogVisible = true;
        this.tiptext = "订单已取消";
        this.torf = 0;
        clearInterval(this.timer);
        setTimeout(() => {
          this.Finish();
        }, 1000);
      }
    },
  },
};
</script>

<style >
.bgView {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.czTitle {
  width: 80%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cz-title-1 {
  background: url("/src/assets/img/czTitle.png");
  background-size: cover;
  height: 23px;
  width: 59px;
}
.cz-title-2 {
  background: url("/src/assets/img/zfTitle.png");
  background-size: cover;
  height: 20px;
  width: 50px;
}
.Vipuh {
  width: 100%;
  text-align: center;
  margin-top: 20px;
  color: #f4c657;
  cursor: pointer;
}
/* .czVIPList {
  background: url("/src/assets/img/VIPbg.png");
  background-repeat: no-repeat;
  background-size: 70%;
  width: 23%;
  height: 533px;
  background-color: #fafafa;
  border-radius: 15px;
} */
.line2 {
  width: 80%;
  height: 1px;
  background-color: #d0c8c8;
}
/* .VIPTitle {
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60%;
  height: 41px;
  line-height: 45px;
  text-align: center;
  color: #fff;
}
.VIPTitle img {
  margin-top: 9px;
  height: 25px;
} */
.czBgView {
  background-color: #fff;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  border-radius: 20px;
}
.czView {
  width: 500px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.czItem {
  width: 150px;
  height: 80px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 1px dashed;
  box-sizing: border-box;
}
.czItem-s {
  width: 150px;
  height: 80px;
  background-color: #ffedc1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 2px solid;
  box-sizing: border-box;
}
.czJP {
  display: flex;
  align-items: center;
  font-size: 30px;
}
.czJP-icon {
  background: url("/src/assets/img/cash.png");
  background-size: cover;
  width: 25px;
  height: 24px;
}
.btns {
  height: 50px;
  width: 500px;
  margin-top: 20px;
  display: flex;
  font-size: 16px;
}
.btn-l {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c7c7c7 1px solid;
  color: #c7c7c7;
  cursor: pointer;
  /* border: 0; */
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.btn-l:hover {
  letter-spacing: 3px;
  /* background: #c7c7c7; */
  color: #000;
  transform: scaleX(1.02) scaleY(1.02);
}

.btn-l:active {
  letter-spacing: 3px;
  /* background: #c7c7c7; */
  color: #000;
  transform: scaleX(0.95) scaleY(1.15);
  transition: 100ms;
}
.btn-r {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
  cursor: pointer;
  border: 0;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.btn-r:hover {
  letter-spacing: 3px;
  background: #f4c657;
  color: #000(0, 0%, 100%);
  transform: scaleX(1.02) scaleY(1.02);
}

.btn-r:active {
  letter-spacing: 3px;
  background: #f4c657;
  color: #000(0, 0%, 100%);
  transform: scaleX(0.95) scaleY(1.15);
  transition: 100ms;
}
.zfbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.czqr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  width: 600px;
  padding-bottom: 30px;
  border-radius: 16px;
}
.qrcode2 {
  width: 150px;
  height: 150px;
  margin-top: 40px;
}
.qrDesc {
  font-size: 16px;
  color: #000;
  font-weight: 800;
  margin-top: 20px;
}
.czBtn {
  position: relative;
  height: 50px;
  width: 150px;
  top: 20px;
  border-radius: 50px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
  color: #000;
  margin-bottom: 30px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}
.czBtn:hover {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 7px 29px 0px; */
  transform: scaleX(1.05) scaleY(1.05);
}

.czBtn:active {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 0px 0px 0px; */
  transform: scaleX(0.95) scaleY(1.15);
  transition: 100ms;
}
</style>