<template>
  <div class="bgView">
    <div class="czBgView">
      <div class="czTitle">
        <div class="supTitle">修改昵称</div>
        <div class="czTitle-close" @click="gbcancel"></div>
      </div>
      <div class="input-container">
        <input type="text" id="input" required="" v-model="newName" />
        <label for="input" class="label">输入新的昵称</label>
        <div class="underline"></div>
      </div>
      <div class="tiptext">{{ sbTip }}</div>
      <div class="btns">
        <div class="btn-l" @click="gbcancel">取消</div>
        <div class="btn-r" @click="changeName">确定</div>
      </div>
    </div>
  </div>
</template>
    
    <script>
import api from "@/api/index";

export default {
  // props: {
  //     cancelBtn: {
  //         type: Function,
  //         default: () => { }
  //     }
  // },
  props: ["cancel", "okClick", "getUserInfo"],
  // props: {
  //   cancel,
  //   okClick,
  // },
  data() {
    return {
      newName: "",
      sbTip: "",
    };
  },
  created() {},
  methods: {
    gbcancel() {
      this.cancel();
      this.newName = "";
      this.sbTip = "";
    },
    async changeName() {
      const data = await api.updateName(this.newName);
      console.log(data);
      if (data.msg === "SUCCESS") {
        this.gbcancel();
        this.getUserInfo();
      } else {
        this.sbTip = data.msg;
        setTimeout(() => {
          this.sbTip = "";
        }, 3000);
      }
    },
  },
};
</script>
    
<style scoped>
.supTitle {
  font-size: 18px;
  font-weight: 800;
  color: #000000;
  border-bottom: #f4c657 4px solid;
  width: 78px;
  height: 26px;
  margin-bottom: 10px;
  cursor: default;
}
.bgView {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.czTitle {
  width: 80%;
}
.cz-title {
  /* background: url("/src/assets/img/tipsTitle.png");
  background-size: cover; */
  height: 20px;
  width: 50px;
}
.line2 {
  width: 80%;
  height: 1px;
  background-color: #d0c8c8;
}
.czBgView {
  background-color: #fff;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  border-radius: 16px;
}
.czItem {
  width: 150px;
  height: 80px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 1px dashed;
  box-sizing: border-box;
}
.czItem-s {
  width: 150px;
  height: 80px;
  background-color: #ffedc1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 2px solid;
  box-sizing: border-box;
}
.czJP {
  display: flex;
  align-items: center;
  font-size: 30px;
}
.czJP-icon {
  background: url("/src/assets/img/cash.png");
  background-size: cover;
  width: 25px;
  height: 24px;
}
.btns {
  height: 50px;
  width: 400px;
  margin-top: 20px;
  display: flex;
  font-size: 16px;
}
.btn-l {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c7c7c7 1px solid;
  color: #c7c7c7;
}
.btn-r {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
}
.czqr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  position: fixed;
  background-color: #fff;
  width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  border-radius: 16px;
}
.qrcode2 {
  width: 150px;
  height: 150px;
  margin-top: 40px;
}
.qrDesc {
  font-size: 16px;
  color: #000;
  font-weight: 800;
  margin-top: 20px;
}
.czBtn {
  position: relative;
  height: 50px;
  width: 150px;
  top: 20px;
  border-radius: 50px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
  color: #000;
  margin-bottom: 30px;
}
.czBtn:hover {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 7px 29px 0px; */
  transform: scaleX(1.05) scaleY(1.05);
}

.czBtn:active {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 0px 0px 0px; */
  transform: scaleX(0.9) scaleY(1.15);
  transition: 100ms;
}

.input-container {
  position: relative;
  margin: 50px auto 10px;
  width: 360px;
}

.input-container input[type="text"] {
  font-size: 20px;
  width: 100%;
  border: none;
  border-bottom: 2px solid #ccc;
  padding: 5px 0;
  background-color: transparent;
  outline: none;
}

.input-container .label {
  position: absolute;
  top: 0;
  left: 0;
  color: #ccc;
  transition: all 0.3s ease;
  pointer-events: none;
}

.input-container input[type="text"]:focus ~ .label,
.input-container input[type="text"]:valid ~ .label {
  top: -20px;
  font-size: 16px;
  color: #333;
}

.input-container .underline {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 2px;
  width: 100%;
  background-color: #333;
  transform: scaleX(0);
  transition: all 0.3s ease;
}

.input-container input[type="text"]:focus ~ .underline,
.input-container input[type="text"]:valid ~ .underline {
  transform: scaleX(1);
}
.tiptext {
  height: 20px;
  width: 360px;
  font-size: 13px;
  color: red;
  font-weight: 800;
  text-align: center;
}
</style>