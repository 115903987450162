<template>
  <div class="t-content22">
    <div class="t-bg2">
        <div class="close2" @click="close()"></div>
        <!-- <el-select v-model="value" placeholder="请选择">
        <el-option class="option" size="25"
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value">
        </el-option>
      </el-select> -->
        <div class="t-title2"></div>
        <div style="clear:both;"></div>
        <div class="line-tips"></div>

        <div class="fgView3 fgView2">
            <div class="fgCell3 fgCell2" :class="sfg == item? 'fgCell2-selected':''" v-for="(item, index) in fgList" :key="index" @click="fgClick(item)">
                <div class="fgCellImg2"><img :src="item.url" alt=""></div>
                <div class="fgCell3-title fgCell-title2">{{ item.name }}</div>
                <div class="fgCell-desc">{{ item.desc }}</div>
                <div v-show="sfg == item" class="photo-s"></div>
            </div>
        </div>

    </div>
  </div>
</template>

<script>
export default {
    props:['close','newClick','cancelClick','fgList','sfg'],
    data(){
        return{
            fgList2:['全景','风景镜头(远景)','全景镜头(广角镜头)','中景','全身项','上半身','下半身','特写','微距摄像','近景','正面视角','侧面视角','景深(协调人景)','镜头光晕','背景虚化/散景'],
            // s_fg:null,
            options: [{
                value: '选项1',
                label: '黄金糕'
            }, {
                value: '选项2',
                label: '双皮奶'
            }, {
                value: '选项3',
                label: '蚵仔煎'
            }, {
                value: '选项4',
                label: '龙须面'
            }, {
                value: '选项5',
                label: '北京烤鸭'
            }],
            value: ''
        }
    },
    mounted(){
    },
    methods:{
        fgClick(item){
            console.log(item);
            // this.sfg = item;
            this.$emit('sfgClick',item)
        },
    }
}
</script>

<style>
.t-content22{
    position: fixed;
    display: flex;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 9;
    justify-content: center;
    align-items: center;
}
.t-bg2{
    width: 900px;
    height: 550px;
    border-radius: 20px;
    background-color: #fff;
    position: relative;
    padding: 30px 10px;
}
.close2{
    background: url('/src/assets/img/textToPicture/close.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    position: absolute;
    top: 30px;
    right: 40px;
}
.el-select{
    position: absolute;
    top: 30px;
    right: 80px;
    width: 150px; 
    height: 25px;
}
.form-update .a2 .el-input{
      width: 220px;
}

.t-title2{
    background: url('/src/assets/img/textToPicture/fgTipTitle.png');
    background-size: cover;
    width: 96px;
    height: 20px;
    margin-left: 30px;
}
.line-tips{
    height: 1px;
    background-color: #D0C8C8;
    margin: 0 30px;
    margin-top: 12px;
}

.fgView2{
    display: grid;
    grid-template-columns: repeat(auto-fill,130px);
    justify-content: space-between;
    gap: 0px;
    padding-bottom: 28px;

    margin: 0 30px;
    grid-template-columns: repeat(auto-fill,190px);
    overflow-y: scroll;
    height: 507px;
}
.fgView2::-webkit-scrollbar {
  width: 0;
}
.fgCell2{
    width: 190px;
    margin-top: 20px;
    background-color: #FAFAFA;
    position: relative;
    border-radius: 16px;
    height: 260px;
    overflow: hidden;
}
.fgCell2-selected{
    border: #000 4px solid;
    box-sizing: border-box;
    overflow: hidden;
}
.fgCellImg2{
    width: 100%;
    height: 120px;
    height: 190px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.fgCell2 img{
    height: 103%;
}

.fgCell-title2{
    font-size: 12px;
    color:#000;
    font-weight: 800;
    margin: 5px 0;
    margin-left: 8px;

    font-size: 16px;
    margin-left: 18px;
}
.fgCell-desc{
    font-size: 14px;
    color: #9D9D9D;
    margin: 5px 0;
    margin-left: 18px;
    margin-bottom: 19px;
}
.photo-s{
    background: url('/src/assets/img/textToPicture/select.png');
    background-size: cover;
    position: absolute;
    top: 9px;
    right: 9px;
    width: 26px;
    height: 26px;
}

</style>