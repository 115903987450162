<template>
  <div class="fkBox">
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span class="fktitle">反馈获积分</span>
        <div @click="close" class="closeBox"></div>
      </div>
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="10px"
        class="demo-ruleForm"
      >
        <el-form-item prop="phone">
          <!-- :rules="[
            {
              required: this.require,
              message: '请输入手机号',
              trigger: 'blur',
              type: 'number',
            },
            {
              pattern: /^1[3456789]\d{9}$/,
              message: '请输入有效的手机号',
              trigger: ['blur', 'change'],
            },
          ]" -->
          <el-input
            type="text"
            class="wight"
            :clearable="true"
            v-model="ruleForm.phone"
            placeholder="请输入手机号"
          ></el-input>
        </el-form-item>
        <el-form-item prop="checkPass">
          <el-input
            type="textarea"
            class="wight"
            v-model="ruleForm.checkPass"
            placeholder="填写你的意见或建议,建议经过审核后被采纳，将赠送胶片/免费会员"
          ></el-input>
        </el-form-item>
        <el-form-item prop="avatar">
          <el-upload
            v-model="ruleForm.avatar"
            ref="adModel"
            class="avatar-uploader"
            list-type="picture-card"
            action="#"
            :show-file-list="true"
            :limit="3"
            :on-change="handleAvatarChange"
            :auto-upload="false"
            :class="ptindix == 2 ? 'gb' : ''"
            :on-remove="handleAvatarRemove"
          >
            <!-- :auto-upload="false" -->
            <!-- :on-change="handleAvatarChange" -->
            <!-- :on-success="handleAvatarSuccess"
            :before-upload="beforeAvatarUpload" -->
            <img v-if="imageUrl" :src="imageUrl" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"><span style="font-size: small;position: fixed;margin-top: 30px;margin-left: -50px;">上传相关图片</span></i>
          </el-upload>
        </el-form-item>
        <el-form-item
          prop="radio"
          style="display: flex; justify-content: space-around; margin: 0px auto"
        >
          <!-- <el-checkbox-group> -->
          <el-radio v-model="ruleForm.radio" label="1" text-color="#000"
            >功能吐槽</el-radio
          >
          <el-radio v-model="ruleForm.radio" label="2">系统故障</el-radio>
          <el-radio v-model="ruleForm.radio" label="3">期望功能</el-radio>
          <!-- </el-checkbox-group> -->
        </el-form-item>
        <el-form-item
          style="width: 100%; display: flex; justify-content: center"
        >
          <el-button
            type="primary"
            @click="submitForm('ruleForm')"
            style="
              width: 300px;nfocus
              border-radius: 20px;
              background-color: #f4c657;
              border: 0px;
            "
            >提交</el-button
          >
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import api from "@/api/index";
import _ from "lodash";

export default {
  props: ["fkclose", "fkSuccess"],
  data() {
    // var validatePhone = (rule, value, callback) => {
    //   const reg = /^1[34578]\d{9}$/; // 正则表达式，验证手机号
    //   if (value)
    //   {if (!reg.test(value)) {
    //     callback(new Error('请输入正确的手机号'));
    //   } else {
    //     callback();
    //   }  }else {
    //     callback(new Error('手机号不能为空'));
    //   }
    // };
    let validatePhone = (rule, value, callback) => {
      if (!value) {
        callback(new Error("手机号不能为空！"));
      } else if (!/^1[3456789]\d{9}$/.test(value)) {
        callback(new Error("手机号不正确！"));
      } else {
        callback();
      }
    };

    let validatePhone2 = (rule, value, callback) => {
      if (!value) {
        callback(new Error("备注不能为空！"));
      } else {
        callback();
      }
    };
    return {
      isShow: false,
      require: true,
      // radio: null,
      imageUrl: "",
      avatars: null,
      avatarss: null,
      avatarsss: null,
      dialogImageUrl: "",
      dialogVisible: false,
      ptindix: null,
      disabled: false,
      tiptext: "",
      torf: 1,
      ruleForm: {
        phone: "",
        checkPass: "",
        radio: null,
        // avatar:"https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/archive/5e8ffebabf574a6889c1694a4b45caa9.png",
        avatar: "",
      },
      rules: {
        phone: [{ validator: validatePhone, trigger: ["blur", "change"] }],
        checkPass: [{ validator: validatePhone2, trigger: ["blur", "change"] }],
      },
    };
  },
  methods: {
    close() {
      console.log("关闭", this.$refs.ruleForm);
      this.fkclose();
      this.$refs.ruleForm.resetFields();
      this.$refs.adModel.clearFiles();
    },
    gbtip() {
      this.dialogVisible = false;
    },
    handleAvatarChange(a, b) {
      console.log(b.length, "handleAvatarChange");
      this.avatars = b[0].raw;
      this.ptindix = 0;
      if (b[1]?.raw) {
        this.avatarss = b[1].raw;
        this.ptindix = 1;
      }
      if (b[2]?.raw) {
        this.avatarsss = b[2].raw;
        this.ptindix = 2;
      }
      // console.log(a,b,"handleAvatarChange")
    },
    handleAvatarRemove() {
      this.ptindix--;
    },
    uploadHttpRequest(data) {
      console.log(data, "datatatatat");
      this.avatars = data.file;
    },
    submitForm: _.throttle(function (formName) {
      this.$refs[formName].validate((valid) => {
        console.log(valid, "valid");
        if (valid) {
          this.submittOk();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    }, 1000),
    async submittOk() {
      const params = this.ruleForm;
      const data = await api.feedbackAdd({
        mobile: params.phone,
        content: params.checkPass,
        image: this.avatars,
        images: this.avatarss,
        imagess: this.avatarsss,
        type: params.radio,
      });
      if (data.msg === "SUCCESS") {
        this.fkSuccess("反馈成功", 1);
        this.close();
      } else {
        this.fkSuccess(data.msg, 0);
      }
      console.log(data);
    },
  },
};
</script>
<style >
.fkBox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.el-textarea__inner {
  font-size: 14px;
}
.el-input__inner {
  font-size: 14px;
}
.fktitle {
  font-size: 16px;
  font-weight: 800;
  color: #000000;
  /* border-bottom: #f4c657 4px solid; */
  width: 67px;
  height: 21px;
  /* margin-bottom: 10px; */
  cursor: default;
}
.demo-ruleForm {
  padding-left: 5px;
}
.gb .el-upload--picture-card {
  display: none;
}
.box-card {
  width: 530px;
  height: 650px;
  /* margin-left: -50px; */
  margin-top: -50px;
  z-index: 9;
  position: absolute;
  top: 20%;
  /* right: 40%; */
  border-radius: 15px;
}
.clearfix:before,
.clearfix:after {
  display: table;
  content: "";
}
.clearfix:after {
  clear: both;
}
.el-input__inner:focus {
  border: 1px solid #f4c657;
}
.el-textarea__inner:focus {
  border: 1px solid #f4c657;
}
.el-textarea__inner {
  width: 455px;
  min-height: 130px !important;
}
.el-card__header {
  width: 85%;
  margin: 10px auto;
  padding: 10px 0px 20px;
}
.wight {
  width: 455px;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #f4c657;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 100px;
  text-align: center;
}
.el-form-item__content {
  margin: 0 0 10px;
}
.el-checkbox__inner {
  border-radius: 20px;
}
.el-radio__inner:hover {
  border-color: #f4c657;
}
.el-radio__input.is-focus .el-checkbox__inner {
  border-color: #f4c657;
}
.el-radio__input.is-checked .el-radio__inner,
.el-checkbox__input.is-indeterminate .el-checkbox__inner {
  background-color: #f4c657;
  border-color: #f4c657;
}
.el-radio__input.is-checked + .el-radio__label {
  color: #f4c657;
}
.avatar {
  width: 100px;
  height: 100px;
  display: block;
}
.closeBox {
  width: 24px;
  height: 24px;
  background-image: url("../assets/img/textToPicture/close.png");
  background-size: cover;
  position: absolute;
  top: 19px;
  right: 37px;
}
</style>