<template>
   <div>
    <div class="content">
        <div class="op-zone">
            <div v-show="type == 0" class="itemCell startChat" :class="s == 0 ? 'itemCell-s':'itemCell-n'" @click="startClick()" v-loading.fullscreen.lock="fullscreenLoading">
                <div class="start-icon" :class="s == 0 ? 'start-icon-s':''"></div>
                <div class="start-title">开始对话</div>
            </div>
            <div v-show="type == 0" class="itemCell creat" :class="s == 1 ? 'itemCell-s':'itemCell-n'" @click="createNew()">
                <div class="creat-icon" :class="s == 1 ? 'creat-icon-s':''"></div>
                <div class="creat-title">创建新的聊天对象</div>
            </div>
            <selectHead v-show="type == 1" class="selectHead" 
                :items="heads" 
                :sx="syList" 
                @inputTitle="inputTitleChange"
                @inputContent="inputContentChange"
                @headClick="headClickChange"
                @syClick="syClickChange"
                :back="selectHeadBack" 
                @saveClick="saveSelectHead"></selectHead>
            <selectHead2 v-show="type == 2" class="selectHead" 
                :s_head="s_head"
                :s_sy="s_sy"
                :text="inputTitle"
                :content="inputContent"
                :back="selectHead2Back" 
                :new-click="newChat" 
                :cancel-click="cancelNewChat"></selectHead2>

        </div>
        <div class="result-zone">
            <div class="msg">
                <div class="msg-head">
                    <!-- <img src="" alt=""> -->
                    <video id="talk-video" ref="talkvideo" src=""></video>
                    <!-- <el-image class="msg-head-img"
                        style="width: 100%: height: 100%"
                        :src="s_head.img_url"
                        :fit="fill"></el-image> -->
                    <img v-show="s_head.video_url == ''" class="msg-head-img"  :src="s_head.img_url" mode="aspectFill" alt="">
                </div>
                <div class="msg-name" @click="createNew()">
                    <div class="msg-name-icon"></div>
                    <div class="msg-name-name">{{ name }}</div>
                    <div class="msg-name-add"></div>
                </div>
               <div class="msg-content" ref="msg">
                    <div class="msg-container">
                        <div v-for="message in messages" :key="message.id" class="message">
                            <div v-if="message.isMe" class="message-text mine">
                                <div class="msg-text-content mine-text">{{ message.text }}</div>
                                <!-- {{ message.text }} -->
                            </div>
                            <div v-else class="message-text other">
                                <div class="msg-text-content other-text">{{ message.text }}</div>    
                                <!-- {{ message.text }} -->
                            </div>
                         </div>
                         <div v-show="msgLoading" class="message">
                            <div class="message-text other">
                                <div class="msg-text-content other-text msg-loader">
                                    <div class="ball"></div>
                                    <div class="ball"></div>
                                    <div class="ball"></div>
                                </div>
                            </div>
                         </div>
                    </div>
               </div>
            </div>
            <div class="msgTool">
                <!-- 输入消息的表单 -->
                 <form @submit.prevent="sendMessage" class="input-form">
                    <input v-model="inputText" type="text" placeholder="输入消息" />
                    <!-- <button class="sendBtn" type="submit">发送</button> -->
                    <div class="sendBtn" @click="sendMessage()">
                        <div class="sendIcon"></div>
                    </div>
                </form>
            </div>
            
        </div>

    </div>
    <!-- <div class="cz" v-show="isCz">
        <div class="qrcode" ref="qrCodeUrl"></div>
    </div> -->

    <!-- <czView v-show="isCz" :cancel="czCancel" :czFinish="czFinish"> -->

    <!-- </czView> -->

  </div>
</template>

<script>
import selectHead from '@/components/selectHead.vue'
import selectHead2 from '@/components/selectHead2.vue'
import  api  from "@/api/index";
export default {
    components: {
        selectHead,
        selectHead2,
    },
    data() {
        return {
            s:0,
            name:'AI机器人',
            inputText: '',
            messages:[],
             messages2: [
                { id: 1, text: '你好', isMe: false },
                { id: 2, text: 'Hi', isMe: true },
                { id: 3, text: 'sdjivhzosdhfioghsdifidxhfbvhxdkzfjbhvkz说过话死而后个色弱价格还是客家话人刚开始监护人头盔哥 海克斯然后给考核人开个会', isMe: true },
                { id: 4, text: 'asfhosihfohsuogh', isMe: false },
            ],
            type:0,
            stream:null,
            peerConnection: null,
            streamId:null,
            sessionId:null,
            sessionClientAnswer:null,
            statsIntervalId:null,
            videoIsPlaying:null,
            lastBytesReceived:null,
            status:'',
            fullscreenLoading:false,
            msgLoading:false,
            lastMsg:'',
            heads:[],
            syList:[],
            inputTitle:'',
            inputContent:'',
            s_head:{img_url:null,video_url:null},
            s_sy:null,
        }
    },
    mounted(){
        this.ff = require('@/utils/streaming-client-api.js')
    },
    created() {
        // this.createANewStream()
        this.getStreamImage()
        this.getStreamVoice()
    },
    deactivated(){
        console.log('离开页面')
        // alert('离开页面')
    },
    methods: {
        async getStreamImage(){
            let res = await api.getStreamImage()
            this.heads = res.data
        },
        async getStreamVoice(){
            let res = await api.getStreamVoice()
            this.syList = res.data
        },

        inputTitleChange(text){
            this.inputTitle = text;
        },
        inputContentChange(text){
            this.inputContent = text
        },
        headClickChange(item){
            this.s_head = item
        },
        syClickChange(item){
            this.s_sy = item
        },


        startClick(){
            this.s = 0;
            this.ff.connectStream(this.stateChange,this.msgChange,this.s_head.img_url,this.s_head.video_url == '' ? 'https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/files/2022_1698054685.mp4' : this.s_head.video_url,this.s_sy.did)
            this.fullscreenLoading = true
        },
        stateChange(state){
            this.fullscreenLoading = false
            this.$notify({
                title: '成功',
                offset: 88,
                message: state == 'new' ? '建立对话成功' : '创建失败'+state,
                type: 'success'
            });
        },
        msgChange(state){
            if (state == 'streaming') {
                this.msgLoading = false;
                this.messages.push({ id: Date.now(), text: this.lastMsg, isMe: false });
                setTimeout(() => {
                    this.$refs.msg.scrollTop = this.$refs.msg.scrollHeight;
                }, 10);
            }else if (state == 'ended') {
                this.msgLoading = false;
                this.fullscreenLoading = false
            }else if (state == 'isHave') {
                this.$notify({
                    message: "正在对话中",
                    type: "error",
                    offset:88,
                    });
                setTimeout(() => {
                    this.fullscreenLoading = false
                }, 10);
            }
            
        },
        createNew(){
            this.s = 1;
            this.type = 1;
            // this.ff.connectStream()
            // this.ff.destroy()
        },
        sendMessage() {
        if (this.inputText.trim()) {
            this.lastMsg = this.inputText
            this.ff.sendMsg(this.inputText)
            this.messages.push({ id: Date.now(), text: this.inputText, isMe: true });
            this.inputText = '';
            this.msgLoading = true;
            
            setTimeout(() => {
                this.$refs.msg.scrollTop = this.$refs.msg.scrollHeight;
            }, 10);
        }
        },
        selectHeadBack(){
            this.type = 0;
        },
        saveSelectHead(data){
            if (this.s_sy == null) {
                this.$notify({
                    message: "请选择声音",
                    type: "error",
                    offset:88,
                    });
                return
            }
            this.s_head = data;
            this.inputTitle = data.name;
            this.inputContent = data.desc;
            this.type = 2;
        },
        selectHead2Back(){
            
            this.type = 1;
        },
        newChat(){

            this.type = 0;
            this.startClick()

        },
        cancelNewChat(){
            this.type = 0;
        },
    }
}
</script>

<style>
@import "../styles/AiChat.css";
</style>