import textTips from "@/components/textTips";
import fengGeTips from "@/components/fengGeTips";
import picRefer from "@/components/picRefer";
import api from "@/api/index";
import download from "downloadjs";
import czView from "@/components/cz.vue";
import xieyi from "@/components/xieyi.vue";
import shareH5 from "@/components/shareH5.vue";
import deleteview from "@/components/deleteAlert.vue";
import scz from "@/components/shengChengZhong.vue";
import scSuccess from "@/components/scSuccess.vue";
import tip from "@/components/tip.vue";
import _ from "lodash";

// import sczLoading from "@/components/sczLoading.vue";
export default {
  components: {
    textTips,
    fengGeTips,
    picRefer,
    czView,
    xieyi,
    scz,
    scSuccess,
    shareH5,
    deleteview,
    tip,
    // sczLoading,
  },
  data() {
    return {
      sczloadingtime: false,
      sczloadingcount: "1",
      dialogVisible: false,
      token: "",
      form: {
        title: "",
        content: "",
        tips: [],
        fgTitle: "机甲展示",
        price: null,
      },
      sourceItems: [],
      items: [],
      s_photo: [],
      ss_photo: 0,
      selectedItem: { image_url: "" },
      cc: [
        {
          title: "3:2",
          width: 1152,
          height: 896,
          ratio: "3_2",
          image: require("/src/assets/img/textToPicture/3：2.png"),
          image2: require("/src/assets/img/textToPicture/3：2-h.png"),
        },
        {
          title: "16:9",
          width: 1216,
          height: 832,
          ratio: "16_9",
          image: require("/src/assets/img/textToPicture/16：9.png"),
          image2: require("/src/assets/img/textToPicture/16：9-h.png"),
        },
        {
          title: "1:1",
          width: 1024,
          height: 1024,
          ratio: "1_1",
          image: require("/src/assets/img/textToPicture/1：1.png"),
          image2: require("/src/assets/img/textToPicture/1：1-h.png"),
        },
        {
          title: "3:4",
          width: 896,
          height: 1152,
          ratio: "3_4",
          image: require("/src/assets/img/textToPicture/3：4.png"),
          image2: require("/src/assets/img/textToPicture/3：4-h.png"),
        },
        {
          title: "9:16",
          width: 832,
          height: 1216,
          ratio: "9_16",
          image: require("/src/assets/img/textToPicture/9：16.png"),
          image2: require("/src/assets/img/textToPicture/9：16-h.png"),
        },
      ],
      // cc:['3:2','16:9','1:1','3:4','16:9'],
      // cc2: [
      //   { title: " 1胶片/张", scale: 1, price: 1, size: "small" },
      //   { title: " 4胶片/张", scale: 2, price: 4, size: "big" },
      // ],
      cc2: [
        { title: " 4胶片/张", scale: 1, price: 4, size: "big" }
      ],
      s_cc: null,
      s_cc2: null,
      fgList: ["1", "2", "3", "4", "5"],
      s_fg: { en_name: "", name: "" },
      ckList2: [
        { url: "", title: "AI造字" },
        { url: "", title: "费二号房" },
        { url: "", title: "返回" },
        { url: "", title: "无法" },
        { url: "", title: "东风街" },
      ],
      ckList: [],
      ckListPic: [],
      s_ckPic: {
        type: 1,
        name: "边缘检测",
        url: "",
        desc: "识别图像中边缘信息，再次生成具有清晰边缘",
      },
      scCountList: [],
      s_scCount: "1",
      isShowTextTips: false,
      isFocusTextarea: false,
      isShowFengGeTips: false,
      isShowPicRefer: false,
      isShowXieyi: false,
      isShowShare: false,
      isShowDelete: false,
      isShowLoading: false,
      fullscreenLoading: false,
      image_url: require("/src/assets/img/textToPicture/1：1.png"),
      showViewer: false,
      srcList: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      uploadPic: null,
      referValue: 0.5,
      isCz: false,
      isCzVip: false,
      isSuccess: false,
      isScz: false,
      totaltext: null,
      pagetext: null,
      dqpage: null,
      pageimgtion: 1,
      tiptext: "",
      torf: 1,
      sczpt: null,
      dschax: null,
      newScPt: 0,
      havemrpt: false,
      buttonIs: false,
      timers: null,
      timesrandom: null,
      timesfloor: Math.floor(Math.random() * 3) + 1,
    };
  },
  beforeRouteLeave(to, from, next) {
    // 在离开当前组件之前调用
    // 可以进行确认提示等操作
    clearInterval(this.dschax);
    console.log("清除路由了");
    next();
  },
  created() {
    clearInterval(this.timers);
    this.sczloadingcount = localStorage.getItem("ldingcount")
    if (this.sczloadingcount <= 1) {
      this.sczloadingcount = 1;
      localStorage.setItem('ldingcount', this.sczloadingcount);
      clearInterval(this.timers);
    } else {
      this.timers = setInterval(() => {
        this.sczloadingcount = Math.ceil(this.sczloadingcount) - 1;
        localStorage.setItem('ldingcount', this.sczloadingcount);
        if (this.sczloadingcount === 1) {
          clearInterval(this.timers);
        }
      }, 1000)
    }
    console.log("222是否登录", localStorage.getItem("token"));
    if (localStorage.getItem("token") == null) {
      this.$store.commit("updateUser", {
        name: "",
        avatar: "",
        brush_num: 0,
        token: "",
      });
    }

    this.token = localStorage.getItem("token");
    this.s_cc = this.cc[0];
    this.s_cc2 = this.cc2[0];
    this.getConfig();
    this.getLibrary(1);
    // this.getDefaultImages()
    // var smallValue =
    // this.$store.state.getChargeConf.txt_to_img_1_1_small.value || 5;
    var bigValue =
      this.$store.state.getChargeConf.txt_to_img_1_1_big.value || 10;
    this.cc2[0].title = " " + bigValue + "胶片/张";
    this.cc2[0].price = bigValue;
    this.cc2[1].title = " " + bigValue + "胶片/张";
    this.cc2[1].price = bigValue;
  },
  mounted() {
    var that = this;
    var ta = this.$refs.textarea;
    ta.onfocus = function () {
      that.isFocusTextarea = true;
    };
    ta.onblur = function () {
      that.isFocusTextarea = false;
    };
  },
  methods: {
    gocz() {
      this.isCz = true;
      this.dialogVisible = false;
    },
    /**分页函数 */
    handleCurrentChange(val) {
      this.getLibrary(val);
      this.dqpage = val;
      clearInterval(this.dschax);
    },
    gbtip() {
      this.dialogVisible = false;
    },
    /**获取默认的图片列表 */
    async getDefaultImages() {
      let res = await api.getDefaultImages();
      var array = res.data;
      this.sourceItems = res.data;
      this.havemrpt = true;
      this.items = [];
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        this.items.push(item);
        console.log(item);
      }
      this.s_photo = this.items[0];
    },
    /**获取生成的图片列表 */
    async getLibrary(val) {
      let res = await api.getWorkImages({
        type: "txt_to_img",
        page: val,
        limit: "12",
      });
      // this.list = res.data.data
      (this.havemrpt = false), (this.totaltext = res.data.total);
      var array = res.data.data;
      this.sourceItems = res.data.data;
      this.pagetext =
        this.totaltext % 12 == 0
          ? this.totaltext / 12
          : Math.ceil(this.totaltext / 12);
      this.items = [];
      this.sczpt = [];
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        if (item.is_success == 0) {
          item.img_url = 'https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/images/0231228110852.png'
        }
        // if(item.is_success == -1){
        //   item.img_url = 'https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/images/0231228110852.png'
        // }
        this.items.push(item);
        if (item.img_url === "") {
          this.sczpt.push(item);
        }
      }
      this.sczpt = this.sczpt.reverse();
      for (let i = 0; i < this.newScPt; i++) {
        let data = this.items[i];
        var wgindex = 0;
        if (data.is_success === 0) {
          wgindex = wgindex + 1;
        }
      }
      if (wgindex > 0) {
        if (this.dialogVisible === false) {
          clearInterval(this.timers);
          this.sczloadingtime = false;
          // this.dialogVisible = true;
          this.tiptext =
            "有图片违规啦，胶片已退还至账户，建议添加更详细的描述后重试哦";
          this.torf = 0;
          wgindex = 0;
        }
      }
      setTimeout(() => {
        this.newScPt = this.sczpt.length;
      }, 0);
      clearInterval(this.dschax);
      if (this.sczpt.length > 0) {
        console.log(this.sczpt[0]?.img_url, this.sczpt[0], "sczpttttt")
        let ldcount = localStorage.getItem("ldingcount")
        console.log(ldcount, "ldcount")
        if (this.sczpt[0]?.img_url === "") this.gitsczpt(0, 1);
      }
      // this.pagetext = this.items.length%12==0?this.items.length/12:this.items.length/12+1;
      this.ss_photo = 0;
      this.s_photo = this.items[0];
      if (array.length == 0 && this.totaltext === 0) {
        this.getDefaultImages();
      } else if (array.length == 0 && this.totaltext > 0) {
        this.getLibrary(val);
      }
    },
    async getConfig() {
      let res = await api.getConfig();
      this.fgList = res.data.play_style;
      this.scCountList = res.data.image_num;
      this.s_scCount = this.scCountList[1];
      this.form.price = this.s_cc2.price * this.s_scCount;
    },
    /**
     * 轮询判断图片是否生成成功
     */
    gitsczpt(n, s) {
      // let ldcount = localStorage.getItem("ldingcount")
      if (this.sczpt[n]?.img_url === "") {
        console.log("1111");
        this.dschax = setInterval(async () => {
          s = s + 1;
          console.log(s, n, "ssss223e")
          if (s === 10) {
            clearInterval(this.dschax);
          }
          let id = this.sczpt[n]?.work_id;
          let data = await api.getWorkImageById(id);
          if (data.data?.is_success == 1) {
            // if (data.data?.is_success !== -1 ) {
            setTimeout(() => {
              this.getLibrary(1);
            }, 0);
            clearInterval(this.dschax);
            if (this.sczpt.length > 0) {
              if (n < this.sczpt.length - 1) {
                // 检查 n 是否达到了最后一个元素的索引
                n++;
                // let ldcount = localStorage.getItem("ldingcount")
                if (this.sczpt[n]?.img_url === "") {
                  this.gitsczpt(n, s);
                }
              }
            }
          }
        }, 6000);
      }
    },
    txtToImg: _.throttle(function () {
      // console.log("shezhi")
      // this.$store.commit('updateUser',{});
      // return
      if (this.s_fg.name == '') {
        this.dialogVisible = true;
        this.tiptext = "请选择风格玩法";
        this.torf = 0;
      }
      else if (this.form.content != "") {
        // if (this.$store.state.user.level_id > 1) {
        if (
          this.$store.state.user.brush_num >=
          this.s_cc2.price * this.s_scCount
        ) {
          this.txtToImg2();
          this.handleCurrentChange(1);
          this.buttonIs = true;
          setTimeout(() => {
            this.buttonIs = false;
          }, 2500);
        } else {
          this.dialogVisible = true;
          this.tiptext = "哦，糟糕！您的胶片不足了，快去充值吧";
          this.torf = 3;
        }
        // } else {
        //   this.$notify({
        //     type: "error",
        //     offset: 90,
        //     message: "您的会员等级不足，无法使用此功能",
        //   });
        // }
      }
      else {
        this.dialogVisible = true;
        this.tiptext = "请为画面添加描述";
        this.torf = 0;
      }
    }, 3000),
    async txtToImg2() {
      clearInterval(this.timesrandom);
      var dic = {
        prompt: this.form.content,
        width: this.s_cc.width * this.s_cc2.scale,
        height: this.s_cc.height * this.s_cc2.scale,
        ratio: this.s_cc.ratio,
        size: this.s_cc2.size,
        control_enabled: false,
        n_iter: Number(this.s_scCount),
        steps: 30,
        init_img: this.uploadPic,
        model_checkpoint: this.s_fg.en_name || "",
        control_type: 0, // 1.边缘检测 2.深度检测 3.动作控制 4.线稿绘画
      };
      if (dic.height === 1360) {
        dic.height = 1364;
      }
      if (this.uploadPic != null) {
        dic = {
          prompt: this.form.content,
          width: this.s_cc.width * this.s_cc2.scale,
          height: this.s_cc.height * this.s_cc2.scale,
          ratio: this.s_cc.ratio,
          size: this.s_cc2.size,
          control_enabled: true,
          n_iter: Number(this.s_scCount),
          steps: 30,
          init_img: this.uploadPic,
          model_checkpoint: this.s_fg.en_name || "",
          control_type: this.s_ckPic.type, // 1.边缘检测 2.深度检测 3.动作控制 4.线稿绘画
        };
      }
      // localStorage.setItem("dics", JSON.stringify(dic));
      let res = await api.txtToImg(dic);

      if (res.msg === "success" || res.msg === "SUCCESS") {
        // localStorage.getItem("ldingcount")
        this.sczloadingcount = Math.ceil(res.data.time * 12);
        this.timers = setInterval(() => {
          this.sczloadingcount = this.sczloadingcount - 1;
          localStorage.setItem('ldingcount', this.sczloadingcount);
          if (this.sczloadingcount === 1) {
            clearInterval(this.timers);
          }
        }, 1000)
        this.timesfloor = Math.floor(Math.random() * 6) + 15;
        this.timesrandom = setInterval(() => {
          this.timesfloor = this.timesfloor - (Math.floor(Math.random() * 3) + 1);
          console.log(this.timesfloor, "this.timefloor", this.timesfloor - (Math.floor(Math.random() * 3) + 1))
          // timenum = timenum-1;
          if (this.timesfloor <= 1) {
            clearInterval(this.timesrandom);
            this.timesfloor = Math.floor(Math.random() * 5) + 1;
          }
        }, 5000);
        // this.sczloadingtime = true;
        // this.isShowLoading = true;
        if (res.data.has_error === true) {
          this.sczloadingtime = false;
          this.dialogVisible = true;
          this.tiptext = "有图片出错啦，消耗的胶片已返回到余额";
          this.torf = 0;
        }
      } else {
        this.sczloadingtime = false;
        this.dialogVisible = true;
        this.tiptext = res.msg.split("!")[0] + "!";
        this.torf = 0;
        this.isSuccess = false;
      }
      // this.isShowLoading = false;
      this.getUserInfo();
      this.getLibrary(this.dqpage);

      // let array = res.data.images
      // for (let index = 0; index < array.length; index++) {
      //     const element = array[index];
      //     this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      //     // this.items.splice(0, 0, element);
      // }

    },
    sczloadbtn() {
      this.sczloadingtime = false;
      // clearInterval(this.timers);
    },
    async getUserInfo() {
      let res = await api.getUserInfo();
      var user = res.data;
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.$store.commit("updateUser", user);
    },
    /**获取用户上传图片 */
    getFile(file, fileList) {
      console.log(file, fileList);
      let img = document.getElementById('ReferencePicture');
      let naturalHeight = 0;
      let naturalWidth = 0;
      img.onload = () => {
        naturalHeight = img.naturalHeight;
        naturalWidth = img.naturalWidth;
        console.log(naturalWidth, naturalHeight);
        if (!((naturalHeight >= 512 && naturalHeight <= 1280) && (naturalWidth >= 512 && naturalWidth <= 1280))) {
          this.uploadPic = null
          this.dialogVisible = true;
          this.tiptext = "上传图分辨率请在512*512 和1280*1280之间";
          this.torf = 0;
        }
      };
      this.getBase64(file.raw).then((res) => {
        console.log(res);
      });
    },
    getBase64(file) {
      var that = this;
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          that.uploadPic = imgResult;
          resolve(imgResult);
        };
      });
    },
    deleteUploadPoc() {
      this.uploadPic = null;
    },
    handleAvatarSuccess(res, file) {
      this.imageUrl = URL.createObjectURL(file.raw);
      this.imageUrl = res.data.image_url;
      this.dialogVisible = true;
      this.tiptext = "上传成功";
      this.torf = 1;
    },
    /**上传图片限制 */
    beforeAvatarUpload(file) {
      console.log('上传图片限制');
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isJPG && !isPNG) {
        this.dialogVisible = true;
        this.tiptext = "上传图片只能是 JPG 或 PNG 格式!";
        this.torf = 0;
        return;
      }
      if (!isLt2M) {
        this.dialogVisible = true;
        this.tiptext = "上传图片大小不能超过 2MB!";
        this.torf = 0;
      }
      return isLt2M;
    },
    async godyClick() {
      this.isShowCzList = false;
      this.isCzVip = true;
      const data = await api.levelList();
      console.log(data);
    },
    ccClick(item) {
      this.s_cc = item;
    },
    cc2Click(item) {
      this.s_cc2 = item;
      this.form.price = this.s_cc2.price * this.s_scCount;
    },
    fgClick(item) {
      console.log(this.s_fg);
      this.s_fg = item;
      this.form.fgTitle = item.name;
    },
    spicReferClick(item) {
      this.s_ckPic = item;
    },

    scCountClick(item) {
      this.s_scCount = item;
      this.form.price = this.s_cc2.price * this.s_scCount;
    },
    photoClick(item, index) {
      this.ss_photo = index;
      this.s_photo = item;
    },
    textTipsClick() {
      this.isShowTextTips = true;
    },
    fengGeTipsClick() {
      this.isShowFengGeTips = true;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
    },
    showPicRefer() {
      this.isShowPicRefer = true;
    },
    closeTextTips() {
      this.isShowTextTips = false;
      this.isShowFengGeTips = false;
      this.isShowPicRefer = false;
      this.isShowXieyi = false;
      this.isShowShare = false;
      this.isShowDelete = false;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "auto";
    },
    textTipsChange(value, deleItem, addItem) {
      console.log(value, deleItem, this.form.content);
      var arr = [];
      if (this.form.content != undefined) {
        arr = this.form.content.length > 0 ? this.form.content.split("，") : [];
      }
      if (addItem != undefined) {
        arr.push(addItem);
      }
      if (deleItem != undefined) {
        arr.splice(arr.indexOf(deleItem), 1);
      }
      var str = "";
      str = arr.length == 1 ? arr[0] : arr.join("，");
      this.form.content = str;
    },
    toolClick(i) {
      if (this.s_photo == null && i != 5) return;
      if (i == 1) {
        download(this.s_photo.img_url);
      } else if (i == 2) {
        this.showViewer = true;
      } else if (i == 3) {
        const message = this.s_photo.img_url;
        this.$copyText(message)
          .then((res) => {
            console.log(res);
            this.dialogVisible = true;
            this.tiptext = "复制链接成功!";
            this.torf = 1;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (i == 4) {
        this.dele();
      } else if (i == 5) {
        this.isShowShare = true;
        // const message = this.s_photo;
        // this.$copyText(message).then(res => {
        //     console.log(res)
        //     this.$notify({
        //         type: 'success',
        //         message: '复制链接成功!'
        //     });
        // }).catch(err => {
        //     console.log(err)
        // })
      }
    },
    dele() {
      clearInterval(this.dschax);
      clearInterval(this.timers);
      this.isShowDelete = true;
      // this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     var id = 0;
      //     for (let i = 0; i < this.sourceItems.length; i++) {
      //         const item = this.sourceItems[i];
      //         for (let j = 0; j < item.ret.length; j++) {
      //             const element = item.ret[j];
      //             if (element == this.s_photo) {
      //                 id = item.id
      //             }
      //         }
      //     }
      //     this.deleteByUrl(id)

      // }).catch(() => {
      //     this.$notify({
      //         type: 'info',
      //         message: '已取消删除'
      //     });
      // });
    },
    deleteClick() {
      var id = 0;
      for (let i = 0; i < this.sourceItems.length; i++) {
        const item = this.sourceItems[i];

        if (item == this.s_photo) {
          id = item.id;
        }
      }
      this.deleteByUrl(id);
    },
    async deleteByUrl(id) {
      this.isShowDelete = false;
      let res = await api.deleteByUrl({
        id: id,
        url: this.s_photo?.img_url
      });
      if (res.ret != 0) {
        this.dialogVisible = true;
        this.tiptext = "删除失败！";
        this.torf = 0;
      }
      this.items.splice(this.items.indexOf(this.s_photo), 1);
      this.s_photo = null;
      this.dialogVisible = true;
      this.tiptext = "删除成功！";
      this.torf = 1;
      if (this.items.length === 0 && this.dqpage > 1) {
        this.dqpage--;
      }
      this.getLibrary(this.dqpage);
    },
    hidImg() {
      this.showViewer = false;
    },
    cancelClick() {
      this.isScz = false;
    },
    okClick() {
      this.isScz = false;
      this.$router.push({ name: "videoLibrary", query: { id: 0 } });
    },
    scSussessClick() {
      this.isSuccess = false;
      this.$router.push({ name: "videoLibrary", query: { id: 0 } });
    },
    czCancel() {
      this.isCz = false;
      this.isSuccess = false;
    },
    czFinish() {
      console.log("充值完成");
    },
    xieyiClick() {
      this.isShowXieyi = true;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
    },

    beforeRouteLeave(to, from, next) {
      next();
      clearInterval(this.dschax);
      this.timer = null;
    },
  },
};