<template>
  <div class="VipView">
    <div class="czVipView">
      <div class="czTitle">
        <div class="czTitle-t cz-title-1"></div>
        <div class="czTitle-close" @click="cancel"></div>
      </div>
      <div class="line2"></div>
      <div class="czView">
        <div
          class="czVIPList"
          v-for="(item, index) in items"
          :key="index"
          :class="activeIndex === index ? 'czVIPList selected' : 'czVIPList'"
          @click="itemClick(index)"
        >
          <div class="VIPTitle">
            <div
              class="logoimg"
              :class="
                item.id === 1
                  ? 'logo1'
                  : item.id === 2
                  ? 'logo2'
                  : item.id === 3
                  ? 'logo3'
                  : 'logo4'
              "
            ></div>
            {{ item.name }}
          </div>
          <div class="VipText">
            <div class="textBox">
              <div
                style="
                  font-size: 18px;
                  font-weight: 800;
                  transform: translateY(30px) translateX(-12px);
                  position: relative;
                "
              >
                ￥
                <div v-if="index !== 0"
                  style="
                    width: 100px;
                    font-size: 12px;
                    color: #c7c7c7;
                    position: absolute;
                  "
                >
                  每月
                </div>
              </div>
              <div class="Price">{{ item.money }}</div>
            </div>
            <ul class="VipInfo">
              <li v-for="(item, indexs) in jieshao[index]" :key="indexs">
                {{ item }}
              </li>
            </ul>
          </div>
          <div
            style="
              width: 100%;
              display: flex;
              justify-content: center;
              margin-top: 30px;
            "
          >
            <el-button
              class="dybtn"
              type="primary"
              @click="dyClick(item.id)"
              round
              :style="index === 0 ? 'display:none' : ''"
              >现在订阅</el-button
            >
          </div>
        </div>
      </div>
    </div>

    <div class="zfbox" v-if="isCz">
      <div class="czqr">
        <div class="czTitle">
          <div class="czTitle-t cz-title-2"></div>
          <div class="czTitle-close" @click="guanbi"></div>
        </div>
        <div class="line2"></div>
        <div class="qrcode2" ref="qrCodeUrl"></div>
        <div class="qrDesc">请使用微信扫码支付购买胶片</div>
        <div class="czBtn" @click="Finish">完成</div>
      </div>
    </div>
    <tip
      v-show="dialogVisible"
      :tiptext="tiptext"
      :torf="torf"
      :gbtip="gbtip"
    ></tip>
  </div>
</template>

<script>
import api from "@/api/index";
import QRCode from "qrcodejs2";
// import {throttle} from "@/utils/user"
import _ from "lodash";
import tip from "@/components/tip.vue";

export default {
  // props: {
  //     cancelBtn: {
  //         type: Function,
  //         default: () => { }
  //     }
  // },
  props: ["cancel", "czFinish", "loginClick"],
  components: { tip },
  data() {
    return {
      items: [],
      sItem: 0,
      activeIndex: null,
      isCz: false,
      timer: "",
      jieshao: [],
      dialogVisible: false,
      tiptext: "",
      torf: 1,
    };
  },
  created() {
    this.levelLists();
    // this.getCzList()
    // this.dyClick()
  },
  methods: {
    // async getCzList() {
    //   let res = await api.getCzList();
    //   console.log("充值列表", res);
    //   this.items = res.data;
    // },
    gbtip() {
      this.dialogVisible = false;
    },
    async itemClick(i) {
      console.log(i);
      if (i != 0) this.activeIndex = i;
    },
    // throttling: throttle(function (...args) {
    // 	if (!this.dyClickExecuted || this.dyClickCount < 1) { this.dyClick(...args); this.dyClickExecuted = true; this.dyClickCount++; }
    //   // this.dyClick(...args);
    // }, 3000),
    dyClick: _.throttle(function (i) {
      var token = localStorage.getItem("token");
      if (token) {
        this.shengc(i);
      } else {
        this.dialogVisible = true;
        this.tiptext = "请先登录";
        this.torf = 0;
        this.loginClick();
      }
    }, 1000),
    async shengc(i) {
      const data = await api.orderPayLevel({ id: i });
      var url = data.data.url;
      this.isCz = true;

      this.$nextTick(() => {
        // 清除qrcode
        if (this.$refs.qrCodeUrl.innerHTML) {
          this.$refs.qrCodeUrl.innerHTML = "";
        }
        new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 150,
          height: 150,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });

      this.timer = setInterval(() => {
        this.getOrderStatusByOrderNos(data.data.order_no);
      }, 3000);

      // var that = this;
      // setTimeout(() => {
      //    new QRCode(this.$refs.qrCodeUrl, {
      //     text: url, //需要转换为二维码的内容
      //     width: 150, //二维码宽度
      //     height: 150, //二维码高度
      //     colorDark: "#000000", //前景色
      //     colorLight: "#ffffff", //后景色
      //     correctLevel: QRCode.CorrectLevel.L, //容错级别
      //   });
      // }, 0);
    },

    async getOrderStatusByOrderNos(value) {
      const data = await api.getOrderStatusByOrderNo({ order_no: value });
      if (data.data.status === 1) {
        this.dialogVisible = true;
        this.tiptext = "充值成功";
        this.torf = 1;
        clearInterval(this.timer);
        setTimeout(() => {
          this.Finish();
        }, 1000);
      }
      if (data.data.status === 2) {
        this.dialogVisible = true;
        this.tiptext = "订单已取消";
        this.torf = 0;
        clearInterval(this.timer);
        setTimeout(() => {
          this.Finish();
        }, 1000);
      }
    },
    // cancel(){
    //     props.cancelBtn();
    // },
    czClick() {
      var item = this.items[this.sItem];
      this.getCzQrcode(item.id);
    },
    guanbi() {
      this.isCz = false;
      clearInterval(this.timer);
      this.getUserInfo();
    },
    Finish() {
      this.isCz = false;
      clearInterval(this.timer);
      this.getUserInfo();
    },
    async levelLists() {
      let res = await api.levelList();
      this.items = res.data;
      for (var i = 0; i < this.items.length; i++) {
        this.jieshao[i] = this.items[i].desc.split("\r\n");
      }
    },
    async getUserInfo() {
      let res = await api.getUserInfo();
      console.log("用户信息", res);
      var user = res.data;
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.$store.commit("updateUser", user);
    },
  },
};
</script>

<style scoped>
.VipView {
  position: fixed;
  top: 0;
  z-index: 10;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.czTitle {
  width: 88%;
  height: 66px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.cz-title-1 {
  background: url("/src/assets/img/VIPLv.png");
  background-size: cover;
  height: 20px;
  width: 100px;
}
.dybtn {
  width: 130px;
  height: 45px;
  border: none;
  border-radius: 30px;
  background-color: #f4c657;
  margin: 10px;
  cursor: pointer;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.dybtn:hover {
  letter-spacing: 3px;
  background: #f4c657;
  color: hsl(0, 0%, 100%);
  transform: scaleX(1.05) scaleY(1.05);
  /* box-shadow: #f4c657 0px 7px 29px 0px; */
}

.dybtn:active {
  letter-spacing: 3px;
  background: #f4c657;
  color: hsl(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 0px 0px 0px; */
  transform: scaleX(0.95) scaleY(1.15);
  transition: 100ms;
  /* color: black; */
  font-size: 15px;
  font-weight: bolder;
}
.VipInfo {
  width: 80%;
  height: 60%;
  margin: 30px auto;
  /* list-style: disc; */
}
.VipInfo li {
  width: 100%;
  margin: 10px auto;
  margin-left: 6px;
  font-size: 14px;
  float: left;
  position: relative;
}
.VipInfo li:before {
  content: "";
  display: block;
  position: absolute;
  top: 35%;
  width: 5px;
  height: 5px;
  left: -15px;
  border-radius: 50%;
  background-color: #f4c657;
  pointer-events: none;
}
.selected {
  background-color: #fafafa;
  border: 2px solid;
  background: url("/src/assets/img/selected.png");
  background-repeat: no-repeat;
  background-size: 9%;
  background-position-x: 95%;
  background-position-y: 2%;
}
.cz-title-2 {
  background: url("/src/assets/img/zfTitle.png");
  background-size: cover;
  height: 20px;
  width: 50px;
}
.czVIPList {
  width: 23%;
  height: 470px;
  background-color: #fafafa;
  border-radius: 20px;
  box-sizing: border-box;

}
.duihao {
  width: 20px;
  height: 20px;
  position: relative;
  left: 198px;
  top: -30px;
}
.line2 {
  width: 88%;
  height: 1px;
  background-color: #d0c8c8;
}
.VIPTitle {
  background: url("/src/assets/img/VIPbg.png");
  background-repeat: no-repeat;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  width: 60%;
  height: 41px;
  line-height: 45px;
  text-align: center;
  font-size: 16px;
  color: #fff;
  padding-right: 25px;
}
.VIPTitle .logoimg {
  margin-top: 9px;
  height: 25px;
  width: 25px;
  background-size: cover;
}
.logo1 {
  background-image: url("../assets/img/jichu.png");
}
.logo2 {
  background-image: url("../assets/img/jinjieVIP.png");
}
.logo3 {
  background-image: url("../assets/img/gaojiVIP.png");
}
.logo4 {
  background-image: url("../assets/img/superVIP.png");
}
.VipText {
  width: 90%;
  height: 60%;
  margin: 20px auto;
}
.textBox {
  width: 100%;
  height: 50px;
  font-size: 13px;
  font-weight: 900;
  color: #000000;
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;
}
.textBox .Price {
  height: 100%;
  font-size: 50px;
  color: #f4c657;
  transform: translateX(-5px);
}
.czVipView {
  background-color: #fff;
  width: 1100px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: 30px;
  border-radius: 16px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
}
.czView {
  width: 1000px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-content: center;
  margin-top: 30px;
  margin-bottom: 30px;
}
.czItem {
  width: 150px;
  height: 80px;
  background-color: #fafafa;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 1px dashed;
  box-sizing: border-box;
}
.czItem-s {
  width: 150px;
  height: 80px;
  background-color: #ffedc1;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-left: 10px;
  margin-top: 10px;
  border-radius: 20px;
  border: #f4c657 2px solid;
  box-sizing: border-box;
}
.czJP {
  display: flex;
  align-items: center;
  font-size: 30px;
}
.czJP-icon {
  background: url("/src/assets/img/cash.png");
  background-size: cover;
  width: 25px;
  height: 24px;
}
.btns {
  height: 50px;
  width: 500px;
  margin-top: 20px;
  display: flex;
  font-size: 16px;
}
.btn-l {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: #c7c7c7 1px solid;
  color: #c7c7c7;
}
.btn-r {
  border-radius: 50px;
  flex: 1;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
}
.zfbox {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
}
.czqr {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: fixed;
  background-color: #fff;
  width: 600px;
  padding-bottom: 30px;
  border-radius: 16px;
}
.qrcode2 {
  width: 150px;
  height: 150px;
  margin-top: 40px;
}
.qrDesc {
  font-size: 16px;
  color: #000;
  font-weight: 800;
  margin-top: 20px;
}
.czBtn {
  position: relative;
  height: 50px;
  width: 150px;
  top: 20px;
  border-radius: 50px;
  margin: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f4c657;
  color: #000;
  margin-bottom: 30px;
  cursor: pointer;
  border: 0;
  box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  transition: all 0.5s ease;
}

.czBtn:hover {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 7px 29px 0px; */
  transform: scaleX(1.05) scaleY(1.05);
}

.czBtn:active {
  letter-spacing: 3px;
  background: #f4c657;
  color: #fff(0, 0%, 100%);
  /* box-shadow: #f4c657 0px 0px 0px 0px; */
  transform: scaleX(0.95) scaleY(1.15);
  transition: 100ms;
}
</style>