<template>
  <div class="ipreactor-big">
    <div class="ipreactor-one">
      <div class="ipreactor-spthon">
        <div class="ipreactor-jingao">
          <img src="../assets/img/jinggao.png" alt="" />
        </div>
        <div class="ipreactor-textp1">
          <p class="ipreactor-p2">视频脚本</p>
          <p title="11111111111111111" class="ipreactor-p3">
            咖啡店宣传视频脚本 镜头1：根据…
          </p>
        </div>
      </div>
      <div class="ipreactor-border"></div>
      <div class="ipreactor-ipimage">
        <div class="ipreactor-ipmun1">
          <img
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/IP形象wnekjie4.png"
            alt=""
            style="width: 100%"
          />
        </div>
        <div class="ipreactor-ipmun2" @click="adddialogue()">
          <img
            src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/新对话2568@2x.png"
            alt=""
          />
        </div>
      </div>

      <div v-for="item in newdialogue" :key="item.id" class="ipreactor-spthon1">
        <div class="ipreactor-jingao">
          <img :src="item.img" alt="" />
        </div>
        <div class="ipreactor-textp1">
          <p class="ipreactor-p2">{{ item.name }} {{ item.id }}</p>
          <p class="ipreactor-p3">{{ item.text }}</p>
        </div>
      </div>
    </div>
    <div :class="this.textnums1 == 2 ? 'ipreactor-twoview' : 'ipreactor-two'">

      <!-- 对话界面 -->
      <div class="ipreactor-dialogue">
        <div class="">
          你好我是您的IP形象助理，在接下来我将和您一同打造您的专属形象，开始之前我会先问你3个问题：1·我想了解一下你们公司的业务范围和目标市场是什么?这将有助于我们确定适合的IP定位和形象。
        </div>
      </div>
      
      <div :class="this.textnums1 == 2 ? 'postioninputview' : 'postioninput'">
        <div class="ipreactor-threetextnums">
          <div
            :class="
              this.textnums1 == 1
                ? 'ipreactor-textnums1'
                : 'ipreactor-textnums2'
            "
            @click="textnums1 = 1"
          >
            生成IP图片
          </div>
          <div
            @click="viewIpchange"
            :class="
              this.textnums1 == 2
                ? 'ipreactor-textnums1'
                : 'ipreactor-textnums2'
            "
            style="margin-left: 15px; margin-right: 15px"
          >
            查看IP档案
          </div>
          <div
            :class="
              this.textnums1 == 3
                ? 'ipreactor-textnums1'
                : 'ipreactor-textnums2'
            "
            @click="textnums1 = 3"
          >
            添加参考形象
          </div>
          <div class="ipreactor-textnums4" @click="slidebut">
            <img
              :class="
                viewIpnums == 1 ? 'ipreactor-text4imgs' : 'ipreactor-text4imgs1'
              "
              src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/滑动按钮k2ejrf.png"
              alt=""
            />
          </div>
        </div>
        <div
          :class="
            viewIpnums == 1 ? 'ipreactor-inputtexts' : 'ipreactor-inputtexts1'
          "
        >
          <div
            class="ipreactor-inputs"
            :style="viewIpnums == 1 ? '' : 'display:none'"
          >
            <el-input v-model="inputtext" placeholder="请输入内容"></el-input>
          </div>
          <div
            class="ipreactor-describe"
            :style="viewIpnums == 1 ? '' : 'display:none'"
          >
            <div
              :class="
                this.describe1 == 1
                  ? 'ipreactor-describe1'
                  : 'ipreactor-describe2'
              "
              @click="describe1 = 1"
            >
              <img
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/IP描述jnrweif.png"
                alt=""
              />IP描述
            </div>
            <div
              :class="
                this.describe1 == 2
                  ? 'ipreactor-describe1'
                  : 'ipreactor-describe2'
              "
              @click="describe1 = 2"
            >
              <img
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/IP描述jnrweif.png"
                alt=""
              />IP容貌
            </div>
            <div class="ipreactor-describe3">
              <img
                src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/立即生成ewkfjj.png"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ipreactor-ipiew" v-if="this.textnums1 == 2">
      <div
        class="ipreactor-imgsdel"
        @click="textnums1 = 1"
        style="cursor: pointer"
      >
        <img
          src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/IconRightk3enfhj.png"
          alt=""
        />
      </div>
      <div class="ipreactor-ipimgview">
        <div class="ipreactor-name">
          <img src="../assets/img/jinggao.png" alt="" />
        </div>
        <div class="ipreactor-name1">IP形象名称</div>
      </div>
      <div class="ipreactor-contenttext">
        <div class="ipreactor-ctent">
          <div>描述</div>
          <div style="cursor: pointer">
            <img
              style="width: 16px"
              src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/复制kfneroi.png"
              alt=""
            />复制
          </div>
        </div>
        <div class="ipreactor-neirg">
          <!-- <div class="ipreactor-wenzi"> -->
          这位女性宛如一件杰作，拥有最好的质量。
          这位女性宛如一件杰作，拥有最好的质量。
          她的高分辨率8K壁纸展现了她非常精致和美丽的面容，闭嘴微笑时，翘起的眉毛和长长的睫毛格外迷人，蓝眼睛犹如宝石般闪耀，白色大理石发光的皮肤令人赞叹。
          她的高分辨率8
          k壁纸展现了她非常精致和美丽的面容,闭嘴微笑时,翘起的眉毛和长长的睫毛格外迷的人,蓝眼睛犹如宝石般闪耀,白色大理石发光的皮肤令人赞叹。
          金色的头发垂落在肩上，穿着灰色毛衣和牛仔裤，侧身站立，胸部特写，散发着迷人的魅力。
          金色的头发垂落在肩上，穿着灰色毛衣和牛仔裤，侧身站立，胸部特写，散发着迷人的魅力。
          <!-- </div> -->
        </div>
        <div class="ipreactor-ctent">
          <div>容貌</div>
          <div style="cursor: pointer">
            <img
              style="width: 16px"
              src="https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/复制kfneroi.png"
              alt=""
            />复制
          </div>
        </div>
        <div class="ipreactor-neirg">
          <!-- <div class="ipreactor-wenzi"> -->
          杰作,最好的质量, (最好的质量), ((杰作)), (高分辨率),
          (原作:0.9),非常详细的8K壁纸,
          (一个非常精致和美丽的)，闭嘴，口红，微笑，翘眉，长睫毛，蓝眼睛，白色大理石发光的皮肤,金发,向上看,灰色毛衣牛仔裤,侧面，胸部，特写
          杰作,最好的质量(最好的质量),((杰作)),(高分辨率),(原作:0.9),非常详细的8
          k壁纸,(一个非常精致和美丽的),闭嘴,口红,微笑,翘眉,长睫毛,蓝眼睛,白色大理石发光的皮肤,金发,向上看,灰色毛衣牛仔裤,侧面,胸部,特写

          <!-- </div> -->
        </div>
        <div class="ipreactor-ctent">
          <div>照片</div>
          <!-- <div>复制</div> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      newdialogue: [
        {
          id: this.idnum,
          name: "IP形象",
          img: "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/滑动按钮k2ejrf.png",
          text: "咖啡店宣传视频脚本    镜头1：根据…",
          time: "2020-07-20",
          status: "已发布",
        },
      ],
      idnum: 2,
      inputtext: "",
      describe1: 1,
      textnums1: 1, // 档案图片形象切换
      viewIpnums: 1, // 查看ip档案
    };
  },

  created() {
    console.log("this.newdialogue");
  },

  methods: {
    adddialogue() {
      const newForm = {
        id: this.idnum,
        name: "IP形象",
        img: "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/uploads/滑动按钮k2ejrf.png",
        text: "咖啡店宣传视频脚本    镜头1：根据…",
        time: "2020-07-20",
        status: "已发布",
      };

      if (this.idnum < 6) {
        // 将新的表单对象添加到forms数组中
        this.newdialogue.push(newForm);

        // 递增num以便下一次使用
        this.idnum++;
      }
      if (this.idnum > 6) {
        console.log("over");
      }
    },
    viewIpchange() {
      this.textnums1 = 2;
      console.log(this.textnums1 == 2, this.describe1);
      // this.viewIpnums =2
    },
    slidebut() {
      if (this.viewIpnums == 1) {
        this.viewIpnums = 2;
      } else if (this.viewIpnums == 2) {
        this.viewIpnums = 1;
      }
    },
  },
};
</script>

<style>
@import "../styles/IPreactor.css";
</style>