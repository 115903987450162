import { render, staticRenderFns } from "./downAlert.vue?vue&type=template&id=3d349f58&"
import script from "./downAlert.vue?vue&type=script&lang=js&"
export * from "./downAlert.vue?vue&type=script&lang=js&"
import style0 from "./downAlert.vue?vue&type=style&index=0&id=3d349f58&prod&sc=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports