import api from "@/api/index";
import download from "downloadjs";
import deleteview from "@/components/deleteAlert.vue";
import downview from "@/components/downAlert.vue";
import shareH52 from "@/components/shareH52.vue";
import { ref } from "vue";
import tip from "@/components/tip.vue";
// import { del } from 'vue';

export default {
  components: {
    deleteview,
    downview,
    shareH52,
    tip,
  },
  data() {
    return {
      srcLists: [
        "https://fuss10.elemecdn.com/e/5d/4a731a90594a4af544c0c25941171jpeg.jpeg",
        "https://fuss10.elemecdn.com/8/27/f01c15bb73e1ef3793e64e6b7bbccjpeg.jpeg",
        "https://fuss10.elemecdn.com/1/8e/aeffeb4de74e2fde4bd74fc7b4486jpeg.jpeg",
      ],
      isShowVideo: false,
      searchKey: "",
      list: [],
      // videoUrl:'',
      selectItem: { name: "", result_url: "", id: "", video_url: "" },
      type: 0,
      items: [],
      itemurl: [],
      photos: [],
      xzList: ref([]),
      srcList: [],
      s_photo: null,
      s_xzphoto: null,
      ss_photo: 0,
      s_profile: null,
      slphoto: "",
      isShowDelete: false,
      deleteItem: null,
      isShowBox: false,
      isShowpicture: false,
      isShowShare: false,
      deleurl: null,
      deleid: null,
      portrayurl: null,
      portrayid: null,
      pages: [],
      pageindex: 0,
      pageindexs: null,
      value: false,
      shareH5url: null,
      showurl: null,
      okindex: false,
      delindex: "",
      totaltext: 0,
      pagetext: null,
      dqpage: 1,
      xiezhentotal: 0,
      dialogVisible: false,
      tiptext: "",
      torf: 1,
      sczpt: [],
      dschax: null,
      prev: null,
      next: null,
      slptIndex: null,
      showViewer: false,
      photowork_id: null,
      userdel_imgId: null,
      delindex_img: null,
      amount: null,
      isShowDown: false,
      userInfo: null,
      styleNameimgList: 0,
      styleName: [1, 2, 3],
      stylecount: 1,
      stylephotos: [],
      stylephotoId: [],
      ckXzlist: [],
      photos2: [],
      uploadphotoId: [],
      selectedIndex: 0,
      selectedPhotos: [],
      newpadding: "",
      photos2id: null,
      xzckIndex: null,
      idxzIndex: null,
      successtzid: null,
    };
  },
  created() {
    this.getClips();
    this.getLibrary();
    this.photoPages();
    this.photoFirsts();
    if (this.$route.query.id) {
      this.type = this.$route.query.id;
      if (this.$route.query.id === 2) {
        this.successtz();
      }
    }
    this.profileClick(-1);
    this.gdfuc();
  },
  beforeRouteLeave(to, from, next) {
    // 在离开当前组件之前调用
    // 可以进行确认提示等操作
    clearInterval(this.dschax);
    console.log("清除路由了");
    next();
  },
  methods: {
    async successtz() {
      console.log(this.successtzid);
      this.profileClick(-1);

      // this.profileClick(0, this.successtzid);
      setTimeout(() => {
        this.ckXzClick(this.photos[0], 0);
      }, 1000);
    },
    gdfuc() {
      this.$nextTick(() => {
        window.addEventListener("scroll", function () {
          var scrollPosition = document.documentElement.scrollTop;
          this.newpadding = scrollPosition;
          // console.log(this.newpadding);
        });
      });
    },
    xzback() {
      this.type = 2;
    },
    load() {
      if (this.stylecount < this.pages.pages) {
        this.stylecount += 1;
        this.photoPages(this.stylecount);
      }
    },
    async Downfileimg(item, index) {
      console.log(item, index, "kjwefbeur");
      this.isShowDown = true;
      const data = await api.photoFirst();
      this.amount = data.data.tbAmount.frameAmount;
    },
    async DownimgClick() {
      this.isShowDown = true;
      const data = await api.photoFirst();
      this.amount = data.data.tbAmount.frameAmount;
    },
    downClick() {
      if (this.$store.state.user.brush_num >= this.amount) {
        this.isShowDown = false;
        const data = api.getCheckAmount(this.amount);
        console.log(data);
        this.getUserInfo();
        this.boxDownload(3);
      } else {
        this.dialogVisible = true;
        this.tiptext = "下载失败，胶片余额不足。";
        this.torf = 0;
      }
    },
    async getUserInfo() {
      let res = await api.getUserInfo();
      this.userInfo = res.data;
      console.log(this.userInfo);
      localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      this.$store.commit("updateUser", this.userInfo);
    },
    deleteImg() {
      this.delindex = 2;
      this.delindex_img = this.photowork_id;
      this.isShowDelete = true;
      // if
    },
    delepohto() {
      // 妙笔生画 delindex = 1
      this.delindex = 1;
      this.isShowDelete = true;
    },
    hidImg() {
      this.showViewer = false;
    },
    toolClick() {
      this.showViewer = true;
    },
    beforeDestroy() {
      clearInterval(this.dschax);
    },
    boxSharephoto() {
      this.isShowShare = true;
    },
    gbtip() {
      this.dialogVisible = false;
    },
    closeTextTips() {
      this.isShowShare = false;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "auto";
    },

    handlePreviewImg() {
      this.$nextTick(() => {
        let domImageView = document.querySelector(".el-image-viewer__mask"); // 获取遮罩层dom
        let closeEle = document.querySelector(".el-image-viewer__close"); // 获取遮罩层关闭按钮dom
        if (!domImageView || !closeEle) {
          return;
        }
        domImageView.addEventListener("click", () => {
          this.isShowBox = false;
          this.isShowpicture = false;
        });
        closeEle.addEventListener("click", () => {
          this.isShowBox = false;
          this.isShowpicture = false;
        });
      });
    },
    handleCurrentChange1(val) {
      console.log("当前页", val);
      if (!val) val = 1;
      this.getLibrary(val);
      this.dqpage = val;
      clearInterval(this.dschax);
      // console.log("当前页", val);
    },
    handleCurrentChange(val) {
      this.pageindex = val;
      this.photoPages(val);
    },
    clickImage() {
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "el-icon-download");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          // this.cusClickHandler();
        }
      });
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "el-icon-link");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          // this.cusClickHandler();
        }
      });
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "icon-trash");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          // this.cusClickHandler();
        }
      });
      this.$nextTick(() => {
        let wrapper = document.getElementsByClassName(
          "el-image-viewer__actions__inner"
        );
        let downImg = document.createElement("i");
        downImg.setAttribute("class", "el-icon-top-right");
        wrapper[0].appendChild(downImg);
        if (wrapper.length > 0) {
          this.wrapperElem = wrapper[0];
          // this.cusClickHandler();
        }
      });
      setTimeout(() => {
        this.wrapperElem.addEventListener("click", (event) => {
          if (event.target.className === "el-icon-download") {
            this.DownimgClick();
          } else if (event.target.className === "el-icon-link") {
            this.boxCopyLink(1);
          } else if (event.target.className === "icon-trash") {
            if (this.isShowpicture === true) {
              this.delindex = 1;
              this.isShowDelete = true;
            } else {
              this.delindex = this.portrayid;
              this.isShowDelete = true;
            }
          } else if (event.target.className === "el-icon-top-right") {
            this.boxSharephoto();
          }
        });
      }, 0);
    },
    /***获取全部写真集 */
    async photoPages(i) {
      const data = await api.photoPage(i);
      this.pages = data.data;
      this.pageindexs = data.data.pages;
      if (data.data.records[0]) {
        this.styleNameimgList = data.data.records[0]?.imgList.length;
      }
      this.photos = [];
      for (let i = 0; i < data.data.records.length; i++) {
        const item = data.data.records[i];
        this.photos.push(item);
        this.photos = [...new Set(this.photos)];
        // this.stylephotos.push(item)
      }
      for (let i = 0; i < this.photos.length; i++) {
        this.selectedPhotos.push(this.photos[i].imgList);
      }
      if (this.pages.pages != 1) {
        this.value = true;
      }
    },
    /**获取写真标题图片 */
    async photoFirsts() {
      const data = await api.photoFirst();
      this.uploadphotoId = [];
      this.successtzid = data.data.imgList[0];
      data.data.imgList.forEach((item) => {
        this.uploadphotoId.push({
          url: item.url,
          id: item.id,
        });
      });
      this.uploadphotoId.reverse();
    },
    /**获取文生图列表 */
    async getLibrary(val) {
      if (val === undefined) val = 1;
      let res = await api.getWorkImages({
        type: "txt_to_img",
        page: val,
        limit: "12",
      });
      console.log("历史记录", res);
      this.totaltext = res.data.last_page;
      this.xiezhentotal = res.data.total;
      var array = res.data.data;
      // this.pagetext = this.totaltext%12==0?this.totaltext/12:Math.ceil(this.totaltext/12);
      this.itemurl = [];
      this.items = [];
      this.sczpt = [];
      clearInterval(this.dschax);
      array.forEach((item) => {
        this.itemurl.push(item.img_url);
        if (item.error_info == "图片不合法，请重新操作。") {
          item.img_url = 'https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/images/0231228110852.png'
        }
        this.items.push({ element: item.img_url, id: item.id });
        if (item.img_url === "") {
          this.sczpt.push(item);
        }
      });
      if (this.sczpt.length > 0) {
        let ldcount = localStorage.getItem("ldingcount")
        if (this.sczpt[0]?.img_url === "" && ldcount != 1) {
          this.gitsczpt(0);
        }
      }
      this.ss_photo = 0;
      this.slphoto = this.itemurl[0];
      this.showurl = this.slphoto;
      this.s_photo = this.itemurl[0];
      this.photowork_id = this.items[0]?.id;
    },
    gitsczpt(n) {
      if (this.sczpt[n]?.img_url === "") {
        console.log("1111");
        var lxindex = 0;
        this.dschax = setInterval(async () => {
          let id = this.sczpt[n]?.work_id;
          let data = await api.getWorkImageById(id);
          if (data.data?.img_url !== "") {
            this.getLibrary(1);
            clearInterval(this.dschax);
            if (this.sczpt.length > 0) {
              if (n < this.sczpt.length - 1) {
                // 检查 n 是否达到了最后一个元素的索引
                n++;
                if (this.sczpt[n]?.img_url === "") {
                  this.gitsczpt(n);
                }
              }
            }
          }
          lxindex = lxindex + 1;
          if (lxindex === 12) {
            clearInterval(this.dschax);
          }
        }, 5000);
      }
    },
    /**
     * 写真合集中图片列表
     */
    ckXzClick(item, index) {
      this.type = -1;
      this.ckXzlist = [];
      this.s_xzphoto = item;
      this.xzckIndex = index;
      for (let i = 0; i < item?.imgList.length; i++) {
        this.ckXzlist.push(item.imgList[i]);
      }
      console.log(this.photos[this.xzckIndex].imgList);
      this.photoClick(this.ckXzlist[0].url, "", this.ckXzlist[0].id);
    },
    /**通过id查看写真 */
    async profileClick(index, item) {
      this.s_profile = index;
      this.s_xzphoto = item;
      this.xzList = [];
      this.srcList = [];
      if (index === -1) {
        this.photoAllImg();
        this.photoPages(1);
      } else {
        console.log(index, item.id);
        this.idxzIndex = item.id;
        const data = await api.photoHistory(item.id);
        this.photos2 = [];
        this.photos2id = data.data[0];
        for (let i = 0; i < data.data[0].styleImgList.length; i++) {
          this.photos2.push(data.data[0].styleImgList[i]);
        }
      }
      if (this.photos[index]) {
        this.styleNameimgList = this.photos[index].imgList.length;
      } else {
        this.selectedIndex = index;
      }
      // console.log(item.imgList.length,"wke[nfiuejr]")

      if (this.photos[index]?.imgList.length > 0) {
        for (let i = 0; i < this.photos[index].imgList.length; i++) {
          if (this.photos[index].imgList[i].url) {
            this.xzList.push(this.photos[index].imgList[i]);
            this.srcList.push(
              this.photos[index].imgList[i].url +
              "?imageView2/1/w/600/h/600/q/85|watermark/2/text/UGl4TW9ycGg=/fill/IzdBN0E3QQ==/fontsize/20/dissolve/30/gravity/northwest/dx/120/dy/20/batch/1/degree/45/spacing/99"
            );
          }
        }
      }
    },
    async photoAllImg() {
      const data = await api.photoAll();
      console.log(data);
    },
    dakai(item) {
      (this.portrayurl = item.url),
        (this.portrayid = item.id),
        (this.isShowBox = true);
      this.slphoto = item.url;
      this.showurl = this.slphoto;
    },
    dakai2(item, id, index) {
      this.shareH5url = item;
      this.slptIndex = index;
      (this.deleurl = item),
        (this.deleid = id),
        (this.isShowpicture = !this.isShowpicture);
      this.slphoto = item;
      this.showurl = this.slphoto;
      this.$nextTick(() => {
        let wrapper = document.querySelector(".el-image-viewer__prev");
        let wrapper2 = document.querySelector(".el-image-viewer__next");
        wrapper.addEventListener("click", () => {
          if (this.slptIndex > 0) {
            this.slptIndex = this.slptIndex - 1;
            this.deleurl = this.items[this.slptIndex].element;
            this.deleid = this.items[this.slptIndex].id;
          } else {
            this.slptIndex = this.items.length - 1;
            this.deleurl = this.items[this.slptIndex].element;
            this.deleid = this.items[this.slptIndex].id;
          }
        });
        wrapper2.addEventListener("click", () => {
          if (this.slptIndex < this.items.length - 1) {
            this.slptIndex = this.slptIndex + 1;
            this.deleurl = this.items[this.slptIndex].element;
            this.deleid = this.items[this.slptIndex].id;
          } else {
            this.slptIndex = 0;
            this.deleurl = this.items[this.slptIndex].element;
            this.deleid = this.items[this.slptIndex].id;
          }
        });
      });
    },
    photoClick(item, index, id) {
      this.s_photo = item;
      this.ss_photo = index;
      this.slphoto = item;
      this.showurl = this.slphoto;
      this.photowork_id = id;
    },
    menuClick(i) {
      this.type = i;
      clearInterval(this.dschax);
      if (i === 0) {
        this.getLibrary();
      }
      if (i === 2) {
        this.profileClick(-1);
        this.photoFirsts();
      }
    },
    async getClips() {
      // let res = await api.getClips({page:'1',limit:'20'})
      let res = await api.getWorkList({});
      this.list = res.data;
    },
    itemClick(e) {
      if (e.status == "error" && e[0].video_url != "") {
        this.dialogVisible = true;
        this.tiptext = "出错啦！请稍后";
        this.torf = 0;
        return;
      }
      if (e.video_url == "" && e.error_info === "") {
        this.dialogVisible = true;
        this.tiptext = "正在生成中";
        this.torf = 0;
        return;
      }
      if (e.error_info !== "") {
        this.dialogVisible = true;
        this.tiptext = "生成失败";
        this.torf = 0;
        return;
      }

      // this.videoUrl = 'http://vjs.zencdn.net/v/oceans.mp4'
      // this.videoUrl = e.result_url

      this.selectItem = e;
      this.isShowVideo = true;
      setTimeout(() => {
        this.$refs.videoPlayer.play();
      }, 1000);
    },
    closeVideo() {
      this.$refs.videoPlayer.pause();
      this.isShowVideo = false;
      this.isShowDelete = false;
      this.isShowDown = false;
    },
    download(item) {
      if (this.selectItem.status == "done") {
        window.open(item);
      }
    },
    dele(item) {
      this.delindex = -1;
      this.isShowDelete = true;
      this.deleteItem = item;
    },
    deleteClick() {
      if (this.delindex === -1) {
        this.deleteTalk(this.deleteItem.id);
      }
      if (this.delindex === 1) {
        this.boxDeletephoto(this.delindex);
        // this.delindex = ""
        // this.boxDeletephoto(1);
      }
      if (this.delindex === 2) {
        this.boxDeletephoto(this.delindex_img);
      }
      if (this.delindex === 3) {
        this.userDeleimg();
      }
      if (this.delindex === 5) {
        this.deleteTalk(this.selectItem.id);
        this.closeVideo();
      }
    },
    // id照删除
    async deleteIdUrl(id) {
      console.log(this.uploadphotoId);
      this.isShowDelete = false;
      const data = await api.photoDelAifirst({ id: id });
      if (data.code === 200) {
        this.dialogVisible = true;
        this.uploadphotoId = [];
        this.tiptext = "删除成功";
        this.torf = 1;
        this.photoFirsts();
      }
    },
    // 写真删除集合
    async userDeleimg() {
      this.isShowDelete = false;
      const data = await api.userdel({ id: this.userdel_imgId });
      if (data.code === 200) {
        this.isShowpicture = false;
        this.dialogVisible = true;
        this.tiptext = "删除成功";
        this.torf = 1;
        this.photos = [];
        this.profileClick(-1);
      }
    },
    // 图片会说话删除
    async deleteTalk(id) {
      this.isShowDelete = false;
      let res = await api.deleteWork(id);
      if (res != undefined) {
        this.getClips();
      }
      this.dialogVisible = true;
      this.tiptext = "删除成功";
      this.torf = 1;
    },
    // 下载视频URL
    downloadFile(r) {
      const fileContent = r; // 替换为实际文件内容
      const blob = new Blob([fileContent], {
        type: "application/octet-stream",
      });
      const url = URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = url;
      link.style.display = "none";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);

      URL.revokeObjectURL(url);
    },
    downVideo(item) {
      // window.open('','_blank')
      if (item.video_url != "") {
        download(item.video_url);
        setTimeout(() => {
          this.dialogVisible = true;
          this.tiptext = "下载成功";
          this.torf = 1;
        }, 1000);
      }
    },

    async deleteClip(id) {
      let res = await api.deleteClip(id);
      if (res != undefined) {
        this.getClips();
      }
      this.dialogVisible = true;
      this.tiptext = "删除成功";
      this.torf = 1;
    },
    /**下载 */
    boxDownload(i) {
      if (i === 0) {
        download(this.selectItem.video_url);
        setTimeout(() => {
          this.dialogVisible = true;
          this.tiptext = "下载成功";
          this.torf = 1;
        }, 1000);
      } else if (i === 1) {
        if (
          this.s_photo !=
          "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/images/0231228110852.png"
        ) {
          download(this.slphoto);
          setTimeout(() => {
            this.dialogVisible = true;
            this.tiptext = "下载成功";
            this.torf = 1;
          }, 1000);
        }
      } else if (i === 3) {
        download(this.s_photo);
        setTimeout(() => {
          this.dialogVisible = true;
          this.tiptext = "下载成功";
          this.torf = 1;
        }, 1000);
      }
    },
    /**获取链接 */
    boxCopyLink(i) {
      if (i === 0) {
        const message = this.selectItem.video_url;
        this.$copyText(message)
          .then((res) => {
            console.log(res);
            this.dialogVisible = true;
            this.tiptext = "复制链接成功";
            this.torf = 1;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (i === 1) {
        if (
          this.s_photo !=
          "https://yantoo-ailab-1320473685.cos.ap-shanghai.myqcloud.com/images/0231228110852.png"
        ) {
          const message = this.slphoto;
          this.$copyText(message)
            .then((res) => {
              console.log(res);
              this.dialogVisible = true;
              this.tiptext = "复制链接成功";
              this.torf = 1;
            })
            .catch((err) => {
              console.log(err);
            });
        }
      }
    },
    boxShare() {
      const message = this.selectItem.video_url;
      this.$copyText(message)
        .then((res) => {
          console.log(res);
          this.dialogVisible = true;
          this.tiptext = "复制链接成功";
          this.torf = 1;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    boxDeleteportray(item) {
      this.delindex = 3;
      this.isShowDelete = true;
      if (this.s_profile === -1) {
        this.userdel_imgId = item.id;
      } else {
        this.userdel_imgId = item.taskId;
      }
    },
    /**文生图图片删除 */
    async boxDeletephoto(value) {
      this.isShowDelete = false;
      if (value === 1) {
        const data = await api.deleteByUrl({
          id: this.photowork_id,
          url: this.s_photo,
        });
        if (data.msg === "SUCCESS") {
          this.isShowpicture = false;
          this.dialogVisible = true;
          this.tiptext = "删除成功";
          this.torf = 1;
          this.items = [];
          this.getLibrary();
        }
      } else if (value !== 1) {
        const res = await api.delPhotoUrl({ id: value });
        if (res.msg === "SUCCESS" || res.msg === "success") {
          this.isShowBox = false;
          this.dialogVisible = true;
          this.tiptext = "删除成功";
          this.torf = 1;
          this.photos = [];
          this.photos2 = [];
          const data = await api.photoPage();
          for (let i = 0; i < data.data.records.length; i++) {
            const item = data.data.records[i];
            this.photos.push(item);
            this.photos = [...new Set(this.photos)];
          }
          if (this.s_profile === -1) {
            if (this.ckXzlist.length - 1 > 0) {
              this.ckXzlist = [];
              for (
                let i = 0;
                i < this.photos[this.xzckIndex]?.imgList.length;
                i++
              ) {
                this.ckXzlist.push(this.photos[this.xzckIndex].imgList[i]);
              }
              this.photoClick(this.ckXzlist[0].url, "", this.ckXzlist[0].id);
            } else {
              this.xzback();
              this.photoFirsts();
              this.profileClick(-1);
            }
          } else {
            const data = await api.photoHistory(this.idxzIndex);
            console.log(this.ckXzlist.length);
            if (this.ckXzlist.length - 1 > 0) {
              this.photos2 = [];
              this.photos2id = data.data[0];
              for (let i = 0; i < data.data[0].styleImgList.length; i++) {
                this.photos2.push(data.data[0].styleImgList[i]);
              }
              if (this.photos2[this.xzckIndex]?.imgList.length > 0) {
                this.ckXzlist = [];
                for (
                  let i = 0;
                  i < this.photos2[this.xzckIndex]?.imgList.length;
                  i++
                ) {
                  this.ckXzlist.push(this.photos2[this.xzckIndex].imgList[i]);
                }
                this.photoClick(this.ckXzlist[0].url, "", this.ckXzlist[0].id);
              }
            } else {
              this.xzback();
              this.photoFirsts();
              this.profileClick(-1);
            }
          }
        }
      }
    },
    boxDelete() {
      this.isShowDelete = true;
      this.delindex = 5;
    },
  },
};