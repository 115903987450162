import api from "@/api/index";
import download from "downloadjs";
import czView from "@/components/cz.vue";
import xieyi from "@/components/xieyi.vue";
import shareH5 from "@/components/shareH5.vue";
import scz from "@/components/shengChengZhong.vue";
import scSuccess from "@/components/scSuccess.vue";
import deleteview from "@/components/deleteAlert.vue";
import tip from "@/components/tip.vue";
import czVip from "@/components/czVip.vue";
// import sczLoading from "@/components/sczLoading.vue";

export default {
  components: {
    // textTips,
    // fengGeTips,
    // picRefer,
    czView,
    xieyi,
    scz,
    scSuccess,
    shareH5,
    deleteview,
    tip,
    czVip
    // sczLoading,
  },
  data() {
    return {
      token: "",
      bzindex: 1,
      loadtext: "图片上传中，请耐心等待",
      sourceItems: [],
      items: [],
      s_photo: null,
      selectedItem: { image_url: "" },
      sex: 1,
      fgList: ["1", "2", "3", "4", "5"],
      fgindex: -1,
      styleId: 0,
      fgPhoto: [],
      ckList: [],
      ckListPic: [],
      s_ckPic: {
        type: 1,
        name: "边缘检测",
        url: "",
        desc: "识别图像中边缘信息，再次生成具有清晰边缘",
      },
      scCountList: [],
      s_scCount: "1",
      isShowTextTips: false,
      isFocusTextarea: false,
      isShowFengGeTips: false,
      isShowPicRefer: false,
      isShowXieyi: false,
      isShowShare: false,
      isShowDelete: false,
      isShowLoading: false,
      isSuccess: false,
      fullscreenLoading: false,
      image_url: require("/src/assets/img/textToPicture/1：1.png"),
      showViewer: false,
      uploadPic: null,
      referValue: 0.5,
      isimg: false,
      isScz: false,
      isCz: false,
      isCzVip: false,
      typeList: [],
      typePhoto: {},
      aiAmount: null,
      ptindex: 0,
      showPhoto: "",
      xzptlist: "",
      customer: "",
      dialogVisible: false,
      tiptext: "",
      torf: 1,
      uploadphotoId: [],
      ss_photoimg: null,
      s_photoimg: null,
      s_photoid: null,
      s_photogener: null,
      s_phototrue: false,
      isShow: true,
      loadImage: false,
      loadInterval: null,
      showfgpt: "",
      xizhendelIndex: null,
      xizhenindex: "",
      loadtime: "",
      loadtimeunit: "",
      loadperson: "",
      time: null,
      timerandom: null,
      timefloor: Math.floor(Math.random() * 6) + 15,
    };
  },
  created() {
    console.log("222是否登录", localStorage.getItem("token"));
    if (localStorage.getItem("token") == null) {
      this.$store.commit("updateUser", {
        name: "",
        avatar: "",
        brush_num: 0,
        token: "",
      });
    }
    this.token = localStorage.getItem("token");
    this.getTypeLists();
    this.photoFirsts();
    this.getStylePages(-1, this.sex);
    // this.kaujvjc();
    // this.getDefaultImages()
  },
  mounted() {
    // var that = this;
    // var ta = this.$refs.textarea;
    // ta.onfocus = function () {
    //   that.isFocusTextarea = true;
    // };
    // ta.onblur = function () {
    //   that.isFocusTextarea = false;
    // };
  },
  methods: {
    gocz() {
      this.isCz = true;
      this.dialogVisible = false;
    },
    // 删除id照片
    xizhenDeleimg(item) {
      console.log(item);
      this.isShowDelete = true;
      this.xizhenindex = 1;
      this.photoFirsts();
    },
    photoClick(item, index) {
      this.ss_photoimg = index;
      this.s_photoimg = item.url;
      this.s_photoid = item.id;
      this.s_photogener = item.gender;
      console.log(item);
      this.typePhoto = { id: item.id, gender: item.gender, aiImg: item.url };
      this.sex = item.gender;
      this.showPhoto = item.url;
      this.s_phototrue = true;
    },
    async photoFrames() {
      const data = await api.photoFrame({ styleId: this.styleId });
      console.log(data);
    },
    async photoFirsts() {
      const data = await api.photoFirst();
      this.uploadphotoId = [];
      data.data.imgList.forEach((item) => {
        this.uploadphotoId.push({
          url: item.url,
          id: item.id,
          gender: item.gender,
        });
      });
      this.uploadphotoId.reverse();
      this.aiAmount = data.data.tbAmount.aiAmount;
    },
    gbtip() {
      this.dialogVisible = false;
    },
    cancelClick() {
      this.bzindex = 1;
      this.isScz = false;
      this.typePhoto.aiImg = "";
      this.showPhoto = "";
      this.isimg = false;
      this.ss_photoimg = null;
      this.s_phototrue = false;
      this.showfgpt = "";
      (this.ptindex = 0), clearInterval(this.loadInterval);
      clearInterval(this.timerandom);
      clearInterval(this.time);
      this.getUserInfo();
    },
    nextTick() {
      if (this.isimg === true || this.s_phototrue === true) {
        this.bzindex = 2;
        this.getStylePages(this.fgindex, this.sex);
        // this.isimg = false;
      } else {
        this.dialogVisible = true;
        this.tiptext = "请上传图片";
        this.torf = 0;
      }
      // if(this.s_phototrue === true){
      //   // this.dialogVisible = true;
      //   // this.tiptext = "已使用参考图上传";
      //   // this.torf = 1;
      //   this.bzindex = 2;
      //   this.getStylePages(this.fgindex, this.s_photogener);
      // }
    },
    lesttick() {
      this.bzindex = 1;
    },
    /**图片上传 */
    handleAvatarSuccess(response) {
      this.typePhoto = response.data;
      if (response.code === 200) {
        this.dialogVisible = true;
        this.tiptext = "上传成功";
        this.torf = 1;
        this.isimg = true;
        // this.uploadphotoId.push(response.data.aiImg)
        // let reader = new FileReader();
        // let imgResult = "";
        // reader.onload = function () {
        //   imgResult = reader.result;
        // };
        // reader.onloadend = function () {
        //   this.showPhoto = imgResult;
        // };
        // this.showPhoto = this.typePhoto.aiImg;
        this.sex = this.typePhoto.gender;
        this.getStylePages(this.fgindex, this.sex);
      }
    },
    /**获取已上传的图片 */
    getFile(file) {
      // let lunxun = setInterval(() => {
      //   if (this.isimg == true)
      //    { setTimeout(() => {
      //     this.showPhoto = '';
      //     this.getBase64(file.raw)
      //     this.fullscreenLoading = false;
      //     clearInterval(lunxun)
      //    }, 0);
      //   }
      // }, 1000);
      if (this.isimg === true) {
        this.getBase64(file.raw);
        this.fullscreenLoading = false;
      }
    },
    getBase64(file) {
      var that = this;
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          that.showPhoto = imgResult;
          resolve(imgResult);
        };
      });
    },
    /**上传限制 */
    beforeAvatarUpload(file) {
      this.fullscreenLoading = true;
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.dialogVisible = true;
        this.tiptext = "上传图片只能是 JPG 或 PNG 格式!";
        this.torf = 0;
        this.isimg = false;
        return;
      }
      if (!isLt2M) {
        this.dialogVisible = true;
        this.tiptext = "上传图片大小不能超过 2MB!";
        this.torf = 0;
        this.isimg = false;
        this.fullscreenLoading = false;
      }
      return isLt2M;
    },

    handleAvatarError(err) {
      console.log(err)
      let error = String(err).split(":")[1];
      this.fullscreenLoading = false;
      (this.showPhoto = ""), (this.dialogVisible = true);
      this.tiptext = "上传失败;" +error;
      this.torf = 0;
      this.isimg = false;
    },
    async GenerateAi() {
      if (!this.typePhoto.id) {
        this.dialogVisible = true;
        this.tiptext = "请上传图片";
        this.torf = 0;
        this.isimg = false;
        return;
      }
      if (this.styleId == 0) {
        this.dialogVisible = true;
        this.tiptext = "请选择风格";
        this.torf = 0;
        return;
      }
      // this.isScz = true;
      if (this.$store.state.user.brush_num > this.aiAmount) {
        this.bzindex = 3;
        const data = await api.photoAi({
          id: this.typePhoto.id,
          gender: this.sex,
          styleId: this.ptindex,
          amount: this.aiAmount,
        });
        this.loadtime = data.data.time;
        this.loadtimeunit = data.data.unit;
        // this.loadperson = data.data.person;
        this.loadperson = 10;
        this.loadInterval = setInterval(() => {
          this.loadImage = !this.loadImage;
        }, 1000);
        if (!data) {
          this.dialogVisible = true;
          this.tiptext = "生成失败请重新尝试";
          this.torf = 0;
          this.typePhoto.aiImg = "";
          this.showPhoto = "";
          this.isimg = false;
          this.isScz = false;
          this.ss_photoimg = null;
          this.s_phototrue = false;
          this.bzindex = 1;
        } else if (data.msg === "success" || data.msg === "SUCCESS") {
          setTimeout(() => {
            let cxjp = setInterval(async () => {
              this.xzptlist = await api.photoPage(1);
              this.customer = await api.getOrderList();
              if (this.customer.data[0].type !== "制作失败退款") {
                if (this.xzptlist.data.records[0].imgList.length !== 0) {
                  this.bzindex = 1;
                  this.isSuccess = true;
                  this.typePhoto.aiImg = "";
                  this.showPhoto = "";
                  this.isimg = false;
                  this.ss_photoimg = null;
                  this.s_phototrue = false;
                  this.showfgpt = "";
                  (this.ptindex = 0), clearInterval(cxjp);
                  clearInterval(this.loadInterval);
                }
              } else {
                this.isScz = false;
                this.dialogVisible = true;
                this.tiptext = "生成失败请重新尝试";
                this.torf = 0;
                this.typePhoto.aiImg = "";
                this.showPhoto = "";
                this.isimg = false;
                this.isScz = false;
                this.s_phototrue = false;
                this.bzindex = 1;
                this.ss_photoimg = null;
                this.s_phototrue = false;
                this.showfgpt = "";
                (this.ptindex = 0), clearInterval(cxjp);
                clearInterval(this.loadInterval);
              }
            }, 5000);
          }, this.loadtime * 1000);
          this.time = setInterval(() => {
            // this.loadtime = this.loadtime - 1;
            this.loadtime = this.loadtime - 1;
            // console.log(this.loadtime);
            if (this.loadtime === 1) {
              clearInterval(this.time);
            }
          }, 1000);
          
          // this.timefloor = 20;
          this.timerandom = setInterval(() => {
          this.timefloor = this.timefloor - (Math.floor(Math.random() * 3) + 1);
          console.log(this.timefloor,"this.timefloor",this.timefloor - (Math.floor(Math.random() * 3) + 1))
          // timenum = timenum-1;
          if (this.timefloor <= 1) {
              clearInterval(this.timerandom);
              this.timefloor = Math.floor(Math.random() * 6) + 15
            }
          }, 5000);
        }
        this.photoFirsts();
        this.photoFrames();
      } else {
        this.dialogVisible = true;
        this.tiptext = "哦，糟糕！您的胶片不足了，快去充值吧";
        this.torf = 3;
      }
    },
    async kaujvjc() {
      let data = await api.photoPage(1);
      console.log(data);
      if (data.data.records[0].imgList.length === 0) {
        this.bzindex = 3;
      }
    },
    /**风格图片 */
    async getDefaultImages() {
      let res = await api.getDefaultImages();
      var array = res.data;
      this.sourceItems = res.data;
      this.items = [];
      for (let i = 0; i < array.length; i++) {
        const item = array[i];
        this.items.push(item.url);
      }
      this.s_photo = this.items[0];
    },
    async itemClick(i) {
      this.sex = i;
      this.getStylePages(this.fgindex, this.sex);
    },
    // async getLibrary() {
    //   let res = await api.getClips({
    //     type: "txt_to_img",
    //     page: "1",
    //     limit: "20",
    //   });
    //   console.log("历史记录", res);
    //   var array = res.data.data;
    //   this.sourceItems = res.data.data;
    //   this.items = [];
    //   for (let i = 0; i < array.length; i++) {
    //     const item = array[i];
    //     for (let j = 0; j < item.ret.length; j++) {
    //       const element = item.ret[j];
    //       this.items.push(element);
    //       console.log(element);
    //     }
    //   }
    //   if (array.length == 0) {
    //     this.getDefaultImages();
    //   }
    // },
    /**风格标题 */
    async getTypeLists() {
      let res = await api.getTypeList();
      this.fgList = [];
      for (let i = 0; i < res.data.length; i++) {
        this.fgList.push(res.data[i]);
      }
    },
    async getUserInfo() {
      let res = await api.getUserInfo();
      var user = res.data;
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.$store.commit("updateUser", user);
    },
    async godyClick() {
      this.isShowCzList = false;
      this.isCz = false;
      this.isCzVip = true;
      const data = await api.levelList();
      console.log(data);
    },
    ccClick(item) {
      this.s_cc = item;
    },
    fgClick(item) {
      this.fgindex = item;
      this.getStylePages(item, this.sex);
    },
    async getStylePages(id, sex) {
      let res = await api.getStylePage({ typeId: id, gender: sex });
      this.fgPhoto = [];
      for (let i = 0; i < res.data.records.length; i++) {
        this.fgPhoto.push(res.data.records[i]);
      }
    },
    ptClick(i) {
      this.ptindex = i.id;
      this.styleId = i.styleId;
      this.showfgpt = i.img;
    },
    textTipsClick() {
      this.isShowTextTips = true;
    },
    fengGeTipsClick() {
      this.isShowFengGeTips = true;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
    },
    showPicRefer() {
      this.isShowPicRefer = true;
    },
    closeTextTips() {
      this.isShowTextTips = false;
      this.isShowFengGeTips = false;
      this.isShowPicRefer = false;
      this.isShowXieyi = false;
      this.isShowShare = false;
      this.isShowDelete = false;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "auto";
    },
    textTipsChange(value, deleItem, addItem) {
      var arr = [];
      if (this.form.content != undefined) {
        arr = this.form.content.length > 0 ? this.form.content.split("，") : [];
      }
      if (addItem != undefined) {
        arr.push(addItem);
      }
      if (deleItem != undefined) {
        arr.splice(arr.indexOf(deleItem), 1);
      }
      var str = "";
      str = arr.length == 1 ? arr[0] : arr.join("，");
      this.form.content = str;
    },
    czCancel() {
      this.isCz=false;
      this.isCzVip = false;
      this.isSuccess = false;
      this.bzindex = 1;
      this.typePhoto.aiImg = "";
      this.showPhoto = "";
      this.getUserInfo();
      clearInterval(this.timerandom);
      clearInterval(this.time);
    },
    async scSussessClick() {
      this.isSuccess = false;
      clearInterval(this.timerandom);
      clearInterval(this.time);
      this.getUserInfo();
      this.$router.push({ name: "videoLibrary", query: { id: 2 } });
      // this.$router.push({ name: "videoLibrary", query: { id: 2 } });
    },
    toolClick(i) {
      if (this.s_photo == null && i != 5) return;
      if (i == 1) {
        download(this.s_photo);
      } else if (i == 2) {
        this.showViewer = true;
      } else if (i == 3) {
        const message = this.s_photo;
        this.$copyText(message)
          .then((res) => {
            console.log(res);
            this.dialogVisible = true;
            this.tiptext = "复制链接成功";
            this.torf = 1;
          })
          .catch((err) => {
            console.log(err);
          });
      } else if (i == 4) {
        this.dele();
      } else if (i == 5) {
        this.isShowShare = true;
        // const message = this.s_photo;
        // this.$copyText(message).then(res => {
        //     console.log(res)
        //     this.$notify({
        //         type: 'success',
        //         message: '复制链接成功!'
        //     });
        // }).catch(err => {
        //     console.log(err)
        // })
      }
    },
    dele() {
      this.isShowDelete = true;
      // this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     var id = 0;
      //     for (let i = 0; i < this.sourceItems.length; i++) {
      //         const item = this.sourceItems[i];
      //         for (let j = 0; j < item.ret.length; j++) {
      //             const element = item.ret[j];
      //             if (element == this.s_photo) {
      //                 id = item.id
      //             }
      //         }
      //     }
      //     this.deleteByUrl(id)

      // }).catch(() => {
      //     this.$notify({
      //         type: 'info',
      //         message: '已取消删除'
      //     });
      // });
    },
    deleteClick() {
      if (this.xizhenindex === 1) {
        this.deleteIdUrl();
      }
      if (this.xizhenindex !== 1) {
        var id = 0;
        for (let i = 0; i < this.sourceItems.length; i++) {
          const item = this.sourceItems[i];
          for (let j = 0; j < item.ret.length; j++) {
            const element = item.ret[j];
            if (element == this.s_photo) {
              id = item.id;
            }
          }
        }
        this.deleteByUrl(id);
      }
    },
    async deleteIdUrl() {
      this.isShowDelete = false;
      const data = await api.photoDelAifirst({ id: this.s_photoid });
      if (data.code === 200) {
        this.dialogVisible = true;
        this.uploadphotoId = [];
        this.tiptext = "删除成功";
        this.torf = 1;
        this.showPhoto = "";
        this.ss_photoimg = null;
        this.s_phototrue = false;
        this.photoFirsts();
      }
    },
    okClick() {
      this.isScz = false;
      this.$router.push({ name: "videoLibrary", query: { id: 2 } });
    },
    async deleteByUrl(id) {
      this.isShowDelete = false;
      let res = await api.deleteByUrl({ id: id, url: this.s_photo });
      if (res.ret != 0) {
        this.dialogVisible = true;
        this.tiptext = "删除失败";
        this.torf = 0;
        return;
      }
      this.items.splice(this.items.indexOf(this.s_photo), 1);
      this.s_photo = null;
      this.dialogVisible = true;
      this.tiptext = "删除成功";
      this.torf = 1;
    },
    czFinish() {
      this.isCz = false;
      console.log("充值完成");
    },
    xieyiClick() {
      this.isShowXieyi = true;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
    },
  },
};