<template>
    <div class="t-content2" @touchmove.prevent.stop @wheel.prevent.stop>
      <div class="picRefer-bg">
          <div class="close2" @click="close()"></div>
          <div class="t-title2"></div>
          <div style="clear:both;"></div>
          <div class="line-pr"></div>
  
          <div class="fgView3 fgView-pr">
              <div class="fgCell3 fgCell-pr" :class="sfg == item? 'fgCell2-selected':''" v-for="(item, index) in items" :key="index" @click="fgClick(item)">
                  <div class="fgCellImg-pr">
                    <img :src="item.url" alt="">
                    <div class="fgCellImg-pr-title">{{ item.name }}</div>
                </div>
                  <div class="fgCell-desc">{{ item.desc }}</div>
                  <div v-show="sfg == item" class="photo-s"></div>
              </div>
          </div>
  
      </div>
    </div>
  </template>
  
  <script>
  export default {
      props:['close','newClick','cancelClick','fgList','sfg'],
      data(){
          return{
            // 1.边缘检测 2.深度检测 3.动作控制 4.线稿绘画
              items:[{type:1,name:'边缘检测',url:require('../assets/img/textToPicture/查询参考图片01.png'),desc:'识别图像中边缘信息，再次生成具有清晰边缘'},
                     {type:2,name:'深度检测',url:require('../assets/img/textToPicture/参考图片2000099.png'),desc:'识别图中的深度信息，重新生产一张相同深度'},
                     {type:3,name:'动作控制',url:require('../assets/img/textToPicture/参考图片2.png'),desc:'识别主体的动作和姿势，重新生成具有相同动'},
                     {type:4,name:'线稿绘画',url:require('../assets/img/textToPicture/参考图片4.png'),desc:'识别图片中线条信息，根据模型风格，进行线'}],
              value: ''
          }
      },
      mounted(){
      },
      methods:{
          fgClick(item){
              console.log(item);
              // this.sfg = item;
              this.$emit('sfgClick',item)
          },
      }
  }
  </script>
  
  <style>
  .t-content2{
      position: fixed;
      display: flex;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      z-index: 9000;
      justify-content: center;
      align-items: center;
  }
  .picRefer-bg{
      width: 590px;
      height: 600px;
      border-radius: 20px;
      background-color: #fff;
      position: relative;
      padding: 30px 10px;
  }
  .close2{
      background: url('/src/assets/img/textToPicture/close.png');
      background-size: cover;
      width: 25px;
      height: 25px;
      position: absolute;
      top: 30px;
      right: 40px;
  }
  .el-select{
      position: absolute;
      top: 30px;
      right: 80px;
      width: 150px; 
      height: 25px;
  }
  .form-update .a2 .el-input{
        width: 220px;
  }
  
  .t-title2{
      background: url('/src/assets/img/textToPicture/参考图片玩法.png');
      background-size: cover;
      width: 144px;
      height: 20px;
      margin-left: 30px;
      /* background-size: contain; */
      /* background-repeat: no-repeat; */
  }
  .line-pr{
      height: 1px;
      background-color: #D0C8C8;
      margin: 0 30px;
      margin-top: 12px;
  }
  
  .fgView-pr{
      display: grid;
      grid-template-columns: repeat(auto-fill,130px);
      justify-content: space-between;
      gap: 0px;
      padding-bottom: 28px;
  
      margin: 0 30px;
      grid-template-columns: repeat(auto-fill,250px);
      /* overflow-y: scroll; */
      height: 547px;
  }
  .fgCell-pr{
      width: 250px;
      margin-top: 20px;
      background-color: #FAFAFA;
      position: relative;
      border-radius: 16px;
      height: 250px;
  }
  .fgCell2-selected{
      border: #000 4px solid;
      box-sizing: border-box;
      overflow: hidden;
  }
  .fgCellImg-pr{
      width: 100%;
      height: 183px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
  }
  .fgCell-pr img{
      height: 100%;
  }
  .fgCellImg-pr-title{
    position: absolute;
    bottom: 10px;
    width: 190px;
    height: 36px;
    border-radius: 12px;
    background-color: rgba(0, 0, 0, 0.7);
    text-align: center;
    line-height: 36px;
    color:#fff;
    font-size: 16px;
    font-weight: 600;
  }
  
  .fgCell-title2{
      font-size: 12px;
      color:#000;
      font-weight: 800;
      margin: 5px 0;
      margin-left: 8px;
  
      font-size: 16px;
      margin-left: 18px;
  }
  .fgCell-desc{
      font-size: 14px;
      color: #9D9D9D;
      margin: 5px 0;
      margin-left: 18px;
      margin-right: 18px;
      margin-bottom: 19px;
  }
  .photo-s{
      background: url('/src/assets/img/textToPicture/select.png');
      background-size: cover;
      position: absolute;
      top: 9px;
      right: 9px;
      width: 26px;
      height: 26px;
  }
  
  </style>