var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"VipView"},[_c('div',{staticClass:"czVipView"},[_c('div',{staticClass:"czTitle"},[_c('div',{staticClass:"czTitle-t cz-title-1"}),_c('div',{staticClass:"czTitle-close",on:{"click":_vm.cancel}})]),_c('div',{staticClass:"line2"}),_c('div',{staticClass:"czView"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"czVIPList",class:_vm.activeIndex === index ? 'czVIPList selected' : 'czVIPList',on:{"click":function($event){return _vm.itemClick(index)}}},[_c('div',{staticClass:"VIPTitle"},[_c('div',{staticClass:"logoimg",class:item.id === 1
                ? 'logo1'
                : item.id === 2
                ? 'logo2'
                : item.id === 3
                ? 'logo3'
                : 'logo4'}),_vm._v(" "+_vm._s(item.name)+" ")]),_c('div',{staticClass:"VipText"},[_c('div',{staticClass:"textBox"},[_c('div',{staticStyle:{"font-size":"18px","font-weight":"800","transform":"translateY(30px) translateX(-12px)","position":"relative"}},[_vm._v(" ￥ "),(index !== 0)?_c('div',{staticStyle:{"width":"100px","font-size":"12px","color":"#c7c7c7","position":"absolute"}},[_vm._v(" 每月 ")]):_vm._e()]),_c('div',{staticClass:"Price"},[_vm._v(_vm._s(item.money))])]),_c('ul',{staticClass:"VipInfo"},_vm._l((_vm.jieshao[index]),function(item,indexs){return _c('li',{key:indexs},[_vm._v(" "+_vm._s(item)+" ")])}),0)]),_c('div',{staticStyle:{"width":"100%","display":"flex","justify-content":"center","margin-top":"30px"}},[_c('el-button',{staticClass:"dybtn",style:(index === 0 ? 'display:none' : ''),attrs:{"type":"primary","round":""},on:{"click":function($event){return _vm.dyClick(item.id)}}},[_vm._v("现在订阅")])],1)])}),0)]),(_vm.isCz)?_c('div',{staticClass:"zfbox"},[_c('div',{staticClass:"czqr"},[_c('div',{staticClass:"czTitle"},[_c('div',{staticClass:"czTitle-t cz-title-2"}),_c('div',{staticClass:"czTitle-close",on:{"click":_vm.guanbi}})]),_c('div',{staticClass:"line2"}),_c('div',{ref:"qrCodeUrl",staticClass:"qrcode2"}),_c('div',{staticClass:"qrDesc"},[_vm._v("请使用微信扫码支付购买胶片")]),_c('div',{staticClass:"czBtn",on:{"click":_vm.Finish}},[_vm._v("完成")])])]):_vm._e(),_c('tip',{directives:[{name:"show",rawName:"v-show",value:(_vm.dialogVisible),expression:"dialogVisible"}],attrs:{"tiptext":_vm.tiptext,"torf":_vm.torf,"gbtip":_vm.gbtip}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }