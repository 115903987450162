<template>
  <div class="div">
    <div class="bgdiv">
        <div class="star1 star">
            <img style="height: 100%;" src="../assets/img/Star1.png" alt="">
        </div>
        <div class="star2 star">
            <img style="height: 100%;" src="../assets/img/Star2.png" alt="">
        </div>
        <div class="star3 star">
            <img style="height: 100%;" src="../assets/img/Star3.png" alt="">
        </div>
        <div class="star4 star">
            <img style="height: 100%;" src="../assets/img/Star4.png" alt="">
        </div>
        <div class="star5 star">
            <img style="height: 100%;" src="../assets/img/Star5.png" alt="">
        </div>

        <img class="scz-image" src="../assets/img/scz.png" alt="">
        <div class="scz-title">AI正在生成中,请稍等..</div>
        <!-- <div class="scz-title"> -->
            <!-- 前方有1人正在排队，预计时间{{ sczloadingcount }} -->
          <!-- </div> -->

        <div class="btns2">
            <!-- <div class="cancel" @click="cancelClick">去我的作品看</div> -->
            <div class="submit2" @click="submitClick">继续制作</div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    // props: ["sczloadingcount"],
    data() {
      return {};
    },
    methods: {
        cancelClick(){
            this.$emit('cancel')
        },
        submitClick(){
            this.$emit('submit')
        },
    }
}
</script>

<style scoped>

.div {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
}

.bgdiv {
    background-color: #fff;
    height: 280px;
    position: absolute;
    width: 300px;
    border-radius: 12px;
    margin-top: -80px;
    text-align: center;
    overflow: hidden;
}
.scz-image{
	width: 100px;
	margin-top: 40px;
}
.scz-title{
	font-size: 10px;
	font-weight: 500;
	margin-top: 15px;
}
.star{
	animation: star 1.5s ease-in infinite;
}
.star1{
	width: 13px;
	height: 13px;
	position: absolute;
	top: 35px;
	left: 50%;
	transform: translateX(-75px);
	/* background: url('/src/assets/img/Star1.png') no-repeat center center;
    background-size: cover; */
}
.star2{
	width: 20px;
	height: 20px;
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(-25px);
	/* background: url('../assets/img/Star2.png') no-repeat center center; animation-delay: .8s;
    background-size: cover; */
}
.star3{
	width: 15px;
	height: 15px;
	position: absolute;
	top: 60px;
	left: 50%;
	transform: translateX(-65px);
	/* background: url('/src/assets/img/Star3.png') no-repeat center center; animation-delay: 1.7s;
    background-size: cover; */
}
.star4{
	width: 23px;
	height: 23px;
	position: absolute;
	top: 45px;
	left: 50%;
	transform: translateX(40px);
	/* background: url('../assets/img/Star4.png') no-repeat center center; animation-delay: .6s; */
    /* background-size: cover; */
}
.star5{
	width: 11px;
	height: 11px;
	position: absolute;
	top: 15px;
	left: 50%;
	transform: translateX(30px);
	/* background: url('../assets/img/Star5.png') no-repeat center center; animation-delay: 1.2s;
    background-size: cover; */
}
@keyframes star {
    10% {
        opacity: 0;
    }

    90% {
        opacity: 1;
    }
}

.btns2 {
    position: absolute;
    left: 30px;
    right: 30px;
    bottom: 19px;
    display: flex;
    height: 37px;
    align-items: center;
    justify-content: center;
}
.btns2 div{
    flex: 1;
    border-radius: 37px;
    height: 100%;
    line-height: 37px;
    font-size: 12px;
}
.cancel{
    /* flex: 1; */
    border: #C7C7C7 1px solid;
	color: #818181;
    cursor: default;
}
.submit2{
    /* flex: 1; */
    background-color: #F4C657;
    color: #000;
    margin-left: 13px;
    cursor: default;
}
</style>