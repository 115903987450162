<template>
  <div class="div-iframe">
      <!-- <iframe src="https://chat.yantootech.com/#/yantoo" style="width: 100vw; height: 100vh; border: 0; overflow: hidden;"></iframe> -->
        <!-- src="/feishGPT/index.html" -->
      <iframe
        ref="iframeModel"
        frameborder="0"
        src="/feishGPT/index.html"
        class="iframe-model"
      >
      </iframe>
    </div>
</template>

<script>
export default {};
</script>

<style>
.div-iframe{
  width: 100vw;
  height: 97vh;
  overflow: hidden;
  margin-top:3vh;
}
.iframe-model{
width:100vw;
height:100vh;
overflow: hidden;
}
</style>