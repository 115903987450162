<template>
  <div class="sharecontent">
    <div class="shareBg">
      <!-- <div :class="showpt === '' ? 'noshow' : 'showpt'">
        <img :src="showurl" style="height: 100%" alt="" />
      </div> -->
      <div
        v-if="showload"
        style="
          width: 100%;
          height: 100%;
          background-color: rgba(0, 0, 0, 0.3);
          z-index: 101;
          position: fixed;
          display: flex;
          justify-content: center;
        "
      >
        <div class="dot-spinner">
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
          <div class="dot-spinner__dot"></div>
        </div>
      </div>
      <canvas
        class="canvas"
        id="codereport"
        canvas-id="codereport"
        ref="canvas"
        ClipToBounds="True"
        :style="'width:' + reportWidth + 'px;height:' + reportHeight + 'px'"
      ></canvas>
      <div class="sharebtns">
        <div class="btn copy" @click="copyClick()"></div>
        <div class="btn down" @click="downClick()"></div>
      </div>
      <div class="shareClose" @click="close"></div>
      <!-- <img class="tImg" ref="tImg" :src="require('../assets/img/textToPicture/shareImage.png')" alt=""> -->

      <div class="qrcode2" ref="qrCodeUrl"></div>
    </div>
    <tip
      v-show="dialogVisible"
      :tiptext="tiptext"
      :torf="torf"
      :gbtip="gbtip"
    ></tip>
  </div>
</template>

<script>
import tip from "@/components/tip.vue";

import QRCode from "qrcodejs2";
// import download from "downloadjs";
export default {
  props: ["showurl"],
  components: { tip },
  data() {
    return {
      shareLink: "https://www.pixmorph.cn/web",
      reportHeight: 0,
      reportWidth: 0,
      temporarycodeUrl: "",
      showpt: "",
      dialogVisible: false,
      tiptext: "",
      torf: 1,
      showload: true,
    };
  },
  created() {
    var userInfo = JSON.parse(localStorage.getItem("userInfo"));
    console.log(userInfo);
    if (userInfo != null) {
      this.shareLink =
        "https://www.pixmorph.cn/web?share_user_id=" + userInfo.id;
      // "https://www.pixmorph.cn/home#/"
    }
    this.getQrcode();
    setTimeout(() => {
      this.showload = false;
    }, 2000);
  },
  methods: {
    close() {
      this.$emit("close");
    },
    gbtip() {
      this.dialogVisible = false;
    },
    canvasCode() {
      let that = this;
      // let modal = uni.getSystemInfoSync(); // 获取手机信号以iphone 6s为例
      // console.log(modal)
      let modal = {
        windowWidth: 300,
      };
      let width = modal.windowWidth; // 获取手机屏幕的宽度
      // 根据手机屏幕比例计算出画布的高度
      // let height2 = (modal.windowWidth * 16) / 9; // 这里的手机屏幕比例我以16:9为例
      // let height = width;
      // let scale = modal.windowWidth / 375;

      (this.maskshow = true),
        // 显示装载海报的容器
        (this.reportHeight = (width * 530) / 300),
        // 设置二维码海报的高度
        (this.reportWidth = width);

      // const ctx = uni.createCanvasContext('codereport', this); // 创建画布
      // ctx = ctx.getContext('2d')
      var canvas = document.getElementById("codereport");
      // var canvas = this.$refs.canvas
      console.log("绘制用到的canvas", canvas);
      var canvas2 = document.createElement("canvas");
      console.log(canvas2);
      var ctx = canvas.getContext("2d");
      // console.log('cccctx',ctx)
      canvas.width = 300;
      canvas.height = (300 * 530) / 300;
      // ctx.rect(0, 0, 400, 1168)

      // ctx.fill()

      // let img = new Image()
      // img.src = require('../assets/logo.png')
      // // ctx.drawImage(img, 0, 0, 400, 1168)
      // img.onload = () => {
      //     console.log('111img', img)
      //     ctx.drawImage(img, 0, 0, 400, 1168)
      // }
      // ctx.translate(0.5, 0.5);

      console.log("绘制宽", width);
      var iWidth = width;
      var iHeight = (width * 530) / 300;
      // var rol = 1168 / 926
      let bgImg = new Image();
      bgImg.src = require("../assets/img/textToPicture/shareImage3.png");
      this.showpt = this.showurl;
      console.log(this.showpt);
      // var tImg = this.$refs.tImg;
      bgImg.onload = () => {
        // canvas.setAttribute('width', iWidth)
        // canvas.setAttribute('height', iHeight)

        var dpr =
          window.devicePixelRatio ||
          window.webkitDevicePixelRatio ||
          window.mozDevicePixelRatio ||
          1;
        console.log("dpr", dpr, bgImg.width, bgImg.height);
        // dpr = 0.1
        canvas.width = bgImg.width * dpr;
        canvas.height = bgImg.height * dpr;
        // canvas.height =  bgImg.width*dpr * 1168 / 926
        canvas.style.width = bgImg.width + "px";
        canvas.style.height = bgImg.height + "px";
        canvas.style.overflow = "hidden";
        // canvas.width=300
        // canvas.height=iHeight
        // canvas.height =  bgImg.width*dpr * 1168 / 926
        // canvas.style.width = 300 + 'px';
        // canvas.style.height = iHeight + 'px';
        // ctx.drawImage(path, 24, 16, width, height)         // 将海报绘制进画布 top为0，left为0，设置海报的宽高
        // let codewidth = modal.windowWidth * 0.2; // 设置二维码宽度
        // let codeheight = codewidth; // 设置二维码高度
        // let top = height * 0.83; // 设置二维码在海报里的向上偏移量
        // let left = modal.windowWidth * 0.15; // 设置二维码在海报里的向左偏移量
        //对绘制的图像(进行缩放)
        ctx.scale(dpr, dpr);
        // setTimeout(() => {
        ctx.drawImage(bgImg, 0, 0, iWidth, iHeight);
        // ctx.clip();
        // },100)
      };
      // ctx.drawImage(path, 24, 16, width, height)         // 将海报绘制进画布 top为0，left为0，设置海报的宽高
      // let codewidth = modal.windowWidth * 0.2; // 设置二维码宽度
      // let codeheight = codewidth; // 设置二维码高度
      // let top = height * 0.83; // 设置二维码在海报里的向上偏移量
      // let left = modal.windowWidth * 0.15; // 设置二维码在海报里的向左偏移量

      //绘制并填充一个圆角矩形
      // this.fillRoundRect(ctx, iWidth - 60 - 50 - 8, iHeight - 66 - 60 - 8, 76, 76, 6, 'rgba(255,100,10,0.7)');
      // 	// this.roundRect(ctx, iWidth - 60 - 50 - 8, iHeight - 66 - 60 - 8, 76, 76, 6);
      //     setTimeout(() => {
      //         this.fillRoundRect(ctx, iWidth - 60 - 50 - 8, iHeight - 66 - 60 - 8, 76, 76, 6, 'rgba(255,100,10,0.7)');

      //     }, 100);
      // 二维码
      var data = this.$refs.qrCodeUrl.getElementsByTagName("canvas");
      var qrImage = data[0].toDataURL("image/png");
      let qrImg = new Image();
      qrImg.src = qrImage;
      qrImg.onload = () => {
        setTimeout(() => {
          ctx.drawImage(qrImg, 210, iHeight - 66 - 30, 60, 60);
        }, 100);
      };
      let showImg = new Image();
      showImg.src =
        this.showpt +
        "?imageView2/1/w/600/h/600/q/85|watermark/2/text/UGl4TW9ycGg=/fill/IzdBN0E3QQ==/fontsize/20/dissolve/30/gravity/northwest/dx/120/dy/20/batch/1/degree/45/spacing/99";
      console.log("showImg", showImg.src);
      showImg.crossOrigin = "Anonymous";
      showImg.onload = () => {
        // setTimeout(() => {
        ctx.drawImage(
          showImg,
          (canvas.width - (showImg.width / showImg.height) * 408) / 2,
          0,
          (showImg.width / showImg.height) * 408,
          408
        );
        // }, 100);
      };

      // ctx.draw(that); // 把绘制好的图形画进canvas

      setTimeout(() => {
        var base64 = canvas.toDataURL("image/png");
        if (base64) {
          this.$set(that, "temporarycodeUrl", base64);
        }

        // that.saveImg(base64)
      }, 500);

      // setTimeout(() => {
      // 	uni.canvasToTempFilePath({
      // 		// 把当前画布指定区域的内容导出生成指定大小的图片，并返回文件路径
      // 		canvasId: 'codereport',
      // 		success: function(
      // 			res) {
      // 			let tempFilePath =
      // 				res
      // 				.tempFilePath;
      // 			that.setData({
      // 				temporarycodeUrl: tempFilePath
      // 			});
      // 		},
      // 		fail: function(res) {
      // 			console.log(
      // 				res);
      // 		}
      // 	});
      // }, 1000);
    },

    fillRoundRect(cxt, x, y, width, height, radius, /*optional*/ fillColor) {
      //圆的直径必然要小于矩形的宽高
      if (2 * radius > width || 2 * radius > height) {
        return false;
      }

      cxt.save();
      cxt.translate(x, y);
      //绘制圆角矩形的各个边
      this.drawRoundRectPath(cxt, width, height, radius);
      cxt.fillStyle = fillColor || "#000"; //若是给定了值就用给定的值否则给予默认值
      cxt.fill();
      cxt.restore();
    },
    drawRoundRectPath(cxt, width, height, radius) {
      cxt.beginPath(0);
      //从右下角顺时针绘制，弧度从0到1/2PI
      cxt.arc(width - radius, height - radius, radius, 0, Math.PI / 2);

      //矩形下边线
      cxt.lineTo(radius, height);

      //左下角圆弧，弧度从1/2PI到PI
      cxt.arc(radius, height - radius, radius, Math.PI / 2, Math.PI);

      //矩形左边线
      cxt.lineTo(0, radius);

      //左上角圆弧，弧度从PI到3/2PI
      cxt.arc(radius, radius, radius, Math.PI, (Math.PI * 3) / 2);

      //上边线
      cxt.lineTo(width - radius, 0);

      //右上角圆弧
      cxt.arc(width - radius, radius, radius, (Math.PI * 3) / 2, Math.PI * 2);

      //右边线
      cxt.lineTo(width, height - radius);
      cxt.closePath();
    },
    roundRect(ctx, x, y, w, h, r) {
      // 开始绘制
      ctx.setLineDash();
      ctx.save(); // 保存当前已绘制，不然后面绘制的都显示不了
      ctx.beginPath();
      ctx.setFillStyle("#fff");
      ctx.setStrokeStyle("#979797");
      // 左上角
      ctx.arc(x + r, y + r, r, Math.PI, Math.PI * 1.5);
      // 右上角
      ctx.arc(x + w - r, y + r, r, Math.PI * 1.5, Math.PI * 2);
      // 右下角
      ctx.arc(x + w - r, y + h - r, r, 0, Math.PI * 0.5);
      // 左下角
      ctx.arc(x + r, y + h - r, r, Math.PI * 0.5, Math.PI);
      // border-left
      ctx.lineTo(x, y + r);

      ctx.fill();
      ctx.stroke();
      ctx.closePath();
      // 剪切
      ctx.clip();
      ctx.restore(); // 恢复clicp的绘制区域，后面的都能绘制
      ctx.draw(true);
    },

    saveImg(e) {
      // let url = e.currentTarget.dataset.url; // 获取海报的文件路径
      let url = e;

      // h5保存图片
      var oA = document.createElement("a");
      oA.download = ""; // 设置下载的文件名，默认是'下载'
      oA.href = url;
      document.body.appendChild(oA);
      oA.click();
      oA.remove(); // 下载之后把创建的元素删除
    },

    getQrcode() {
      var that = this;
      setTimeout(() => {
        console.log("链接是：", this.shareLink);
        new QRCode(that.$refs.qrCodeUrl, {
          text: this.shareLink, //需要转换为二维码的内容
          width: 66, //二维码宽度
          height: 66, //二维码高度
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //后景色
          correctLevel: QRCode.CorrectLevel.L, //容错级别
        });
        this.canvasCode();
      }, 0);
    },
    copyClick() {
      var userInfo = JSON.parse(localStorage.getItem("userInfo"));
      this.shareLink = "https://www.pixmorph.cn/?share_user_id=" + userInfo.id;
      this.$copyText(this.shareLink).then(
        (res) => {
          console.log("复制成功", res);
          this.dialogVisible = true;
          this.tiptext = "复制成功";
          this.torf = 1;
          this.shareLink =
            "https://www.pixmorph.cn/web/?share_user_id=" + userInfo.id;
        },
        (err) => {
          console.log("复制失败", err);
        }
      );
    },
    downClick() {
      this.saveImg(this.temporarycodeUrl);
      // download(this.temporarycodeUrl)
      // uni.previewImage({
      // 	urls: [this.temporarycodeUrl]

      // })
      // var data = this.$refs.qrCodeUrl.getElementsByTagName('canvas')
      // var image = data[0].toDataURL('image/png');
      // console.log('二维码图片', image)
    },
  },
};
</script>


<style scoped>
.sharecontent {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 100;
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
.shareBg {
  width: 350px;
  height: 680px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.noshow {
  display: none;
}
.showpt {
  width: 300px;
  height: 408px;
  position: relative;
  display: flex;
  justify-content: center;
  /* background-color: aliceblue; */
  z-index: 10;
  left: 24px;
  top: 10px;
  overflow: hidden;
  border-radius: 25px 25px 0px 0px;
}
.sharebtns {
  display: flex;
  margin: 0px 23px;
  /* padding-bottom: 50px; */
  position: absolute;
  bottom: 60px;
  width: 300px;
}
.btn {
  height: 45px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  /* width: 200px; */
}

.copy {
  /* flex: 1; */
  width: 141px;

  margin-right: 20px;

  /* background: url('../assets/img/textToPicture/copy.png'); */
  background-image: url("../assets/img/textToPicture/copy.png");
  background-size: cover;
  object-fit: cover;
  /* 背景图垂直、水平均居中 */
  background-position: center center;
  /* 背景图不平铺 */
  background-repeat: no-repeat;
  /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
  /* background-attachment: fixed; */
  /* 让背景图基于容器大小伸缩 */
  background-size: cover;
}

.down {
  flex: 1;
  background: url("../assets/img/textToPicture/sharedown.png");
  background-size: cover;
  background-position: center center;
}
.down image {
  height: 12px;
}

.shareClose {
  background: url("../assets/img/textToPicture/shareClose.png");
  background-size: cover;
  background-position: center center;
  width: 30px;
  height: 30px;
  position: absolute;
  bottom: 0;
  left: 45%;
}

.qrcode2 {
  padding: 5px;
  opacity: 0;
  
}
.canvas {
  position: absolute;
  left: 24px;
  right: 24px;
  top: 16px;
  /* background-color: red; */
  /* opacity: 0.3; */
  border-radius: 23px;
  overflow: hidden;
  /* background-color: #fff; */
  /* box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.1); */
  -webkit-backface-visibility: hidden;

  -webkit-transform: translate3d(0, 0, 0);
}
.dot-spinner {
  --uib-size: 2.8rem;
  --uib-speed: 0.9s;
  --uib-color: #f4bf00;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: var(--uib-size);
  width: var(--uib-size);
  margin-top: 20%;
}

.dot-spinner__dot {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
}

.dot-spinner__dot::before {
  content: "";
  height: 20%;
  width: 20%;
  border-radius: 50%;
  background-color: var(--uib-color);
  transform: scale(0);
  opacity: 0.5;
  animation: pulse0112 calc(var(--uib-speed) * 1.111) ease-in-out infinite;
  box-shadow: 0 0 20px rgba(18, 31, 53, 0.3);
}

.dot-spinner__dot:nth-child(2) {
  transform: rotate(45deg);
}

.dot-spinner__dot:nth-child(2)::before {
  animation-delay: calc(var(--uib-speed) * -0.875);
}

.dot-spinner__dot:nth-child(3) {
  transform: rotate(90deg);
}

.dot-spinner__dot:nth-child(3)::before {
  animation-delay: calc(var(--uib-speed) * -0.75);
}

.dot-spinner__dot:nth-child(4) {
  transform: rotate(135deg);
}

.dot-spinner__dot:nth-child(4)::before {
  animation-delay: calc(var(--uib-speed) * -0.625);
}

.dot-spinner__dot:nth-child(5) {
  transform: rotate(180deg);
}

.dot-spinner__dot:nth-child(5)::before {
  animation-delay: calc(var(--uib-speed) * -0.5);
}

.dot-spinner__dot:nth-child(6) {
  transform: rotate(225deg);
}

.dot-spinner__dot:nth-child(6)::before {
  animation-delay: calc(var(--uib-speed) * -0.375);
}

.dot-spinner__dot:nth-child(7) {
  transform: rotate(270deg);
}

.dot-spinner__dot:nth-child(7)::before {
  animation-delay: calc(var(--uib-speed) * -0.25);
}

.dot-spinner__dot:nth-child(8) {
  transform: rotate(315deg);
}

.dot-spinner__dot:nth-child(8)::before {
  animation-delay: calc(var(--uib-speed) * -0.125);
}

@keyframes pulse0112 {
  0%,
  100% {
    transform: scale(0);
    opacity: 0.5;
  }

  50% {
    transform: scale(1);
    opacity: 1;
  }
}
</style>