
// 公共请求方法
// import base from './base'
import base from "@/api/base";
import axios from '../utils/request';


const api = {

    getVoices(dic) {
        console.log('贝斯', base, dic)
        return axios.get('api/voices/getVoicesList', dic)
            .then(data => {

                return data;
            })
    },
    //v3 语音语种
    getAllLang() {
        return axios.get('api/heygen/getAllLang', {})
            .then(data => {

                return data;
            })
    },
    //v3 声音声线
    getVoiceList(dic) {
        return axios.get('api/heygen/getVoiceList', dic)
            .then(data => {

                return data;
            })
    },
    //v3 语气语调
    getLabelList(dic) {
        return axios.get('api/heygen/getLabelList', dic)
            .then(data => {

                return data;
            })
    },
    //v3 获取头像
    getPhoto() {
        return axios.get('api/heygen/getPhoto', {})
            .then(data => {

                return data;
            })
    },
    //v3 删除头像
    deleteImageV3(id) {
        return axios.post('api/heygen/deleteImage', { id })
            .then(data => {

                return data;
            })
    },
    //v3 创建视频
    createPhotoVideo(dic) {
        return axios.post('api/heygen/createPhotoVideo', dic)
            .then(data => {

                return data;
            })
    },
    //v3 获取作品
    getWorkList(dic) {
        return axios.get('api/heygen/getWorkList', dic)
            .then(data => {

                return data;
            })
    },
    //v3 删除作品
    deleteWork(id) {
        return axios.post('api/heygen/deleteWork', { id })
            .then(data => {

                return data;
            })
    },

    getimages() {
        return axios.get('api/did/images', {})
            .then(data => {

                return data;
            })
    },
    getAListOfThePresenters() {
        return axios.get(base.getAListOfThePresenters, {})
            .then(data => {

                return data;
            })
    },
    createAClip(dic) {
        console.log(dic);
        return axios.post(base.createAClip, dic)
            .then(data => {

                return data;
            })
    },
    createATalk(dic) {
        return axios.post('api/did/createATalk', dic)
            .then(data => {

                return data;
            })
    },

    getClips(dic) {
        // " page=1&limit=20"
        return axios.get(base.getClips, dic)
            .then(data => {

                return data;
            })
    },

    getDefaultImages() {
        return axios.get('api/aigc/getDefaultImages', {})
            .then(data => {

                return data;
            })
    },

    getLoginToken() {
        return axios.get('api/getLoginToken ', {})
            .then(data => {

                return data;
            })
    },
    getLoginQr() {
        return axios.get('api/getLoginQr ', {})
            .then(data => {

                return data;
            })
    },

    checkTokenStatus(token) {
        return axios.post('api/checkTokenStatus ', { 'token': token })
            .then(data => {

                return data;
            })
    },

    pay(type) {//1-基础版,2-高级,3-专业
        return axios.post('api/order/pay', { 'type': type })
            .then(data => {

                return data;
            })
    },

    getCzList() {
        return axios.get('api/order/rechargeType', {})
            .then(data => {

                return data;
            })
    },

    getUserInfo() {
        return axios.get('api/user/info', {})
            .then(data => {

                return data;
            })
    },

    getChargeConf() {
        return axios.get('api/config/getChargeConf', {})
            .then(data => {

                return data;
            })
    },


    getOrderList() {// api/order/list?limit=10&page=1 {{URL}}/api/user/brushLogs
        return axios.get('api/user/brushLogs', { page: '1', limit: '100' })
            .then(data => {

                return data;
            })
    },

    deleteByUrl(dic) {
        return axios.post('api/work/deleteById', dic)
            .then(data => {

                return data;
            })
    },

    deleteTalk(id) {
        return axios.post('api/work/delete', { id })
            .then(data => {

                return data;
            })
    },

    deleteClip(id) {
        return axios.post('api/did/deleteASpecificClip', { id })
            .then(data => {

                return data;
            })
    },

    deleteImage(id) {
        return axios.post('api/image/delete', { id })
            .then(data => {

                return data;
            })
    },

    // 文生图
    txtToImg(dic) {
        return axios.post('api/aigc/txtToImg', dic)
            .then(data => {

                return data;
            })
    },
    // 文生图配置
    getConfig() {
        return axios.get('api/aigc/getConfig', {})
            .then(data => {

                return data;
            })
    },
    // 创建一个媒体流
    createANewStream(dic) {
        return axios.post('api/did/createANewStream', dic)
            .then(data => {

                return data;
            })
    },
    startAStream(dic) {
        return axios.post('api/did/startAStream', dic)
            .then(data => {

                return data;
            })
    },
    createATalkStream(dic) {
        return axios.post('api/did/createATalkStream', dic)
            .then(data => {

                return data;
            })
    },
    submitNetworkInformation(dic) {
        return axios.post('api/did/submitNetworkInformation', dic)
            .then(data => {

                return data;
            })
    },

    getStreamImage() {
        return axios.get('api/did/getStreamImage', {})
            .then(data => {

                return data;
            })
    },
    getStreamVoice() {
        return axios.get('api/did/getStreamVoice', {})
            .then(data => {

                return data;
            })
    },

    uploadStreamImage(dic, file) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post('api/did/uploadStreamImage', dic, formData)
            .then(data => {

                return data;
            })
    },

    // 获取用户会员等级列表
    levelList() {
        return axios.get('api/level/list', {})
            .then(data => {

                return data;
            })
    },


    // 购买会员等级
    orderPayLevel(id) {
        return axios.post('api/order/payLevel', id)
            .then(data => {

                return data;
            })
    },
    getTypeList() {
        return axios.get('wpi/style/getTypeList', {})
            .then(data => {

                return data;
            })
    },

    // wpi/photo/upload

    photoUpload(file) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post('wpi/photo/upload', formData)
            .then(data => {

                return data;
            })
    },

    // /photo/ai
    photoAi(params) {
        return axios.post('wpi/photo/ai', { id: params.id, gender: params.gender, styleId: params.styleId, amount: params.amount })
            .then(data => {

                return data;
            })
    },
    getStylePage(params) {
        return axios.post(`/wpi/style/getStylePage?current=1&size=30&typeId=${params.typeId}&gender=${params.gender}`, {
            current: 1,
            size: 30,
            typeId: params.typeId,

        })
            .then(data => {

                return data;
            })
    },

    // /photo/first
    photoFirst() {
        return axios.get('wpi/photo/first', {})
            .then(data => {

                return data;
            })
    },
    // /photo/frame?styleId=xxx
    photoFrame(params) {
        return axios.get(`wpi/photo/frame?styleId=${params.styleId}`, {})
            .then(data => {

                return data;
            })
    },

    // /wpi/user/del

    userdel(params) {
        return axios.get(`wpi/user/del?id=${params.id}`, {})
            .then(data => {

                return data;
            })
    },

    // /photo/page
    photoPage(params) {
        return axios.get('wpi/photo/page', {
            current: params,
            size: 12,
        })
            .then(data => {

                return data;
            })
    },

    delPhotoUrl(params) {
        return axios.get('wpi/photo/delPhoto', {
            id: params.id,
        })
            .then(data => {

                return data;
            })
    },

    getOrderStatusByOrderNo(params) {
        return axios.get('api/order/getOrderStatusByOrderNo', {
            order_no: params.order_no,
        })
            .then(data => {

                return data;
            })
    },

    // https://www.pixmorph.cn/api/aigc/getWorkImages 
    getWorkImages(params) {
        return axios.get('api/aigc/getWorkImages', {
            type: params.type,
            page: params.page,
            limit: params.limit,
        })
            .then(data => {
                return data;
            })
    },


    // api/feedback/add
    feedbackAdd(params) {
        console.log(params, "paapapapap")
        let formData = new FormData();
        formData.append("mobile", params.mobile);
        formData.append("content", params.content);		// 新建一个FormData()对象，这就相当于你新建了一个表单
        formData.append("image", params.image);
        if (params.images) formData.append("image", params.images);
        if (params.imagess) formData.append("image", params.imagess);
        formData.append("type", params.type);
        return axios.post('api/feedback/add', formData)
            .then(data => {

                return data;
            })
    },
    getWorkImageById(params) {
        return axios.get('api/aigc/getWorkImageById', {
            id: params,
        })
            .then(data => {
                return data;
            })
    },
    // /wpi/photo/del 删除id照片
    photoDelAifirst(params) {
        return axios.get('wpi/photo/del', {
            id: params.id,
        })
            .then(data => {
                return data;
            })

    },
    getCheckAmount(params) {
        return axios.get('wpi/user/checkAmount', {
            amount: params,
            event: 3,
        })
            .then(data => {
                return data;
            })
    },
    // /wpi/photo/all
    photoAll() {
        return axios.get('wpi/photo/all', {
        })
            .then(data => {
                return data;
            })
    },
    photoHistory(params) {
        return axios.get('wpi/photo/history', {
            photoId: params
        })
            .then(data => {

                return data;
            })
    },

    /**用户更新头像 */
    updateAvatar(file) {
        const formData = new FormData();
        formData.append("file", file);
        return axios.post('api/user/updateAvatar', formData)
            .then(data => {
                return data;
            })
    },
    updateName(params) {
        return axios.post('api/user/updateName', { name:params })
            .then(data => {

                return data;
            })
    },

}

export default api