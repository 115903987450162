import Vue from 'vue'
import Vuex from 'vuex'
// import createPersistedstate  from 'vuex-persistedstate'

Vue.use(Vuex)

const state = {
    user:{
        name:'',
        avatar:'',
        brush_num:0,
        token: '',
        level_id:'',
    },
    getChargeConf:{}
}

// 实时监听 state 值 的最新状态，注意这里的getters 可以理解为计算属性
const getters = {
    // 在组件中是通过 this.$store.getters.getUser 来获取
    getUser(state){
        return state.user
    },
    getChargeConf(state){
        return state.getChargeConf
    }
}

// 定义改变state初始值的方法，这里是唯一可以改变state的地方，缺点是只能同步执行
const mutations = {
    // 在组件中是通过 this.$store.commit('updateUser',user); 方法来调用mutations
    updateUser(state,user){
        state.user = user;
    },
    updateGetChargeConf(state,getChargeConf){
        state.getChargeConf = getChargeConf;
    }
};

// 定义触发 mutations 里函数的方法，可以异步执行 mutations 里的函数
const actions = {
    // 在组件中是通过 this.$store.dispatch('asyncUpDateUser',user); 来调用 actions
    asyncUpDateUser(context,user) {
        context.commit('updateUser',user);
    },
    asyncUpDateGetChargeConf(context,getChargeConf) {
        context.commit('updateGetChargeConf',getChargeConf);
    }
};

// const plugins = [
//     createPersistedstate({
//     reducer(val) {
//         return {
//             user: val.user,
//             getChargeConf: val.getChargeConf
//         }
//     }
//     }) 
//   ]


export default new Vuex.Store({
    state,
    getters,
    mutations,
    actions,
    // plugins
});