<template>
    <div class="bgView" @click.self="hiddenCzList">
       <div class="czListBgView">
        <div class="czTitleBg">
            <div class="czTitleBg-t"></div>
            <div class="czTitle-close" @click="hiddenCzList"></div>
        </div>
        <div class="listView" >
            <div class="listItem" v-for="(item, index) in items" :key="index"  @click="itemClick(index)">
                <div class="l-left">
                    <div class="l-l-title" style="font-weight:800">
                        {{ item.type }}
                    </div>
                    <div class="l-l-time">
                        {{ item.created_at }}
                    </div>
                </div>
                <div :class="(item.num.indexOf('-') != -1 ? '' : 'c-p-red')" class="l-right">{{ item.num }}胶片</div>
            </div>
        </div>

        <div class="l-btns">
            <div class="l-btn-l" @click="hiddenCzList">
                取消
            </div>
            <div class="l-btn-r" @click="goczClick">
                充值
            </div>
            <!-- <div class="l-btn-s" @click="godyClick">
                订阅
            </div> -->
        </div>

    </div>
  </div>
</template>

<script>
  import  api  from "@/api/index";

export default {
    props:['hiddenCzList','goczClick'],
    data(){
        return {
            items:[],
        }
    },
    created(){
        this.getOrderList()
    },
    methods:{
        async getOrderList(){
            let res = await api.getOrderList()
            console.log('记录列表',res)
            this.items = res.data
        },
    }
}
</script>

<style>
    .czListBgView{
        position: fixed;
        background-color: #fff;
        width: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        /* padding: 30px 0; */
        padding-top: 10px;
        padding-bottom: 20px;
        border-radius: 20px;
    }
    .czTitleBg{
        width: 440px;
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .czTitleBg-t{
        background: url('/src/assets/img/jlTitle.png');
        background-size: cover;
        height:20px;
        width:100px;
    }
    .czTitle-close{
        background: url('/src/assets/img/textToPicture/close.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    }
     .listView{
        width: 500px;
        height: 400px;
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;
        overflow-y: scroll;
        align-content: flex-start;
    }
    .listItem{
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: #ededed 1px solid;
    }
    .l-left{
        margin-left: 30px;
    }
    .l-l-time{
        font-size: 12px;
        color: darkgray;
    }
    .l-right{
        margin-right: 30px;
        font-weight: 700;
    }
    .c-p-red{
        color: #F4C657;
    }
    .l-btns{
        height: 40px;
        width: 420px;
        margin-top: 20px;
        display: flex;
        font-size: 16px;
    }
    .l-btn-l{
        border-radius: 40px;
        flex: 1;
        margin: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: #C7C7C7 1px solid;
        color: #C7C7C7;
        cursor: pointer;
        /* border: 0; */
        box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        }

        .l-btn-l:hover {
        letter-spacing: 3px;
        /* background: #C7C7C7; */
        color: #000;
        /* box-shadow: #C7C7C7 0px 7px 29px 0px; */
        transform: scaleX(1.05) scaleY(1.05);
        }

        .l-btn-l:active {
        letter-spacing: 3px;
        /* background: #C7C7C7; */
        color: #000;
        /* box-shadow: #C7C7C7 0px 0px 0px 0px; */
        transform: scaleX(0.95) scaleY(1.15);
        transition: 100ms;
        }
    .l-btn-r{
        border-radius: 40px;
        flex: 1;
        margin: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F4C657;
        font-weight: 400;
        cursor: pointer;
        border: 0;
        box-shadow: rgb(0 0 0 / 5%) 0 0 8px;
        letter-spacing: 1.5px;
        text-transform: uppercase;
        transition: all 0.5s ease;
        }

        .l-btn-r:hover {
        letter-spacing: 3px;
        background: #f4c657;
        color: #000(0, 0%, 100%);
        /* box-shadow: #f4c657 0px 7px 29px 0px; */
        transform: scaleX(1.05) scaleY(1.05);
        }

        .l-btn-r:active {
        letter-spacing: 3px;
        background: #f4c657;
        color: hsl(0, 0%, 100%);
        /* box-shadow: #f4c657 0px 0px 0px 0px; */
        transform: scaleX(0.95) scaleY(1.15);
        transition: 100ms;
        }
    .l-btn-s{
        border-radius: 40px;
        flex: 1;
        margin: 0 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F4C657;
        font-weight: 400;
    }
</style>