<template>
    <div class="xycontent">
        <div style="padding:0 10px 10px;background-color: #fff;border-radius: 10px;">
            <p class="xy-title">PixMorph 用户隐私协议</p>
            <div class="closeXy" @click="close"></div>

            <div class="xieyiBg scroll">
                <p>生效日期：2023年11月</p>
                <p>1. 介绍</p>
                <p>欢迎使用PixMorph（以下简称“平台”）！PixMorph是一项为用户提供图像、视频处理以及其他相关功能的服务。本隐私协议旨在明确您的个人信息如何被收集、使用、共享和保护。</p>
                <p>2. 用户同意</p>
                <p>通过使用PixMorph，您同意遵守本隐私协议，表明您了解并同意我们的信息处理方式。</p>
                <p>3. 收集的信息</p>
                <p>用户提供的信息：在使用PixMorph的过程中，您可能需要提供个人信息，例如您的微信号、手机号、或者上传的照片和文字。这些信息将用于为您提供服务、创建内容。</p>
                <p>4. 信息使用</p>
                <p>a. 提供服务：我们使用您的信息来提供PixMorph的功能，包括动态编辑照片、创建文本和动画内容，以及其他相关服务。</p>
                <p>b. 改进和分析：我们使用信息来改进我们的服务、分析趋势、以及为您提供更好的服务。</p>
                <p>c. 与第三方分享：我们可能会与第三方服务提供商合作，以提供某些功能。您的信息可能会与这些服务提供商共享，但我们会采取适当措施保护您的隐私。</p>
                <p>5. 用户个人行为</p>
                <p>PixMorph不对用户上传的照片和文字内容承担责任。用户应确保他们遵守适用法律和规定，并不侵犯他人的知识产权或隐私权。</p>
                <p>6. 个人数据的保留期限</p>
                <p>我们会在您不再需要平台服务时，将信息删除或匿名化它们，或者在法律规定的最短保留期限后删除它们。</p>
                <p>7. 隐私权的权利</p>
                <p>根据适用法律，您有权访问、更正、删除或限制处理您的个人数据。</p>
                <p>8. 协议变更</p>
                <p>我们保留更改本协议的权利。</p>
                <p>9. 联系方式</p>
                <p>如果您有任何关于平台的问题或疑虑，请联系我们：service@pixmorph.cn</p>
                <p>感谢您使用PixMorph！</p>

            </div>
        </div>


    </div>
</template>

<script>
export default {
    props: ['close'],
    created() {

    }
}
</script>

<style>
.xycontent {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 12;
    position: fixed;
    top: 0;
    display: flex;
    justify-content: center;
    align-items: center;
}

.xieyiBg {
    width: 600px;
    height: 500px;
    /* border-radius: 10px; */
    /* background-color: #fff; */
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    /* align-items: center; */
    overflow-y: scroll;
    padding: 0 20px;
    padding-bottom: 20px;
    position: relative;
}

p {
    margin-top: 20px;
}

.xy-title {
    width: 100%;
    text-align: center;
    margin: 10px auto;
}

.closeXy {
    background: url('/src/assets/img/textToPicture/close.png');
    background-size: cover;
    width: 25px;
    height: 25px;
    /* position: absolute; */
    /* top: 10px; */
    /* right: 10px; */
    float: left;
    transform: translate3d(630px, -33px, 0);
    z-index: 10;
}

.scroll::-webkit-scrollbar {
    width: 0.45rem;
    height: 0.75rem;
    background-color: #F5F5F5;
}

/*定义滚动条轨道 内阴影+圆角*/
.scroll::-webkit-scrollbar-track {
    border-radius: 0.625rem;
    background-color: #F5F5F5;
}

/*定义滑块 内阴影+圆角*/
.scroll::-webkit-scrollbar-thumb {
    border-radius: 0.625rem;
    background-color: #ccc;
}
</style>