<template>
  <div>
    <div>
      <!-- <div class="bg"></div> -->
      <div id="header1">
        <div class="hmenu">
          <!-- <div class="logo">颜途<i>™</i>AI实验室</div> -->
          <div class="logo">
            <img src="/src/assets/img/title-black.png" alt="" />
          </div>
          <div class="menu">
            <li @click="homeClick">
              <router-link to="/home">首页</router-link>
            </li>
            <li>
              <router-link
                v-if="status.ACCESS_TOKEN.length > 0"
                to="/textToPicture"
                >妙笔生画</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">妙笔生画</div>
            </li>
            <!-- <li><a href="">AI写真</a></li> -->
            <li>
              <router-link v-if="status.ACCESS_TOKEN.length > 0" to="/AIxiezhen"
                >写真制作</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">写真制作</div>
            </li>
            <li>
              <router-link v-if="status.ACCESS_TOKEN.length > 0" to="/create"
                >图片会说话</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">图片会说话</div>
            </li>
            <!-- <li><router-link v-if="status.ACCESS_TOKEN.length > 0" to="/AIChat">AI陪练</router-link>
                        <div v-else class="menuLogin" @click="loginClick">AI陪练</div>
            </li> -->

            <li>
              <router-link v-if="status.ACCESS_TOKEN.length > 0" to="/AIAnswer"
                >AI问答</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">AI问答</div>
            </li>
            <li>
              <router-link
                v-if="status.ACCESS_TOKEN.length > 0"
                to="/videoLibrary"
                >我的作品</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">我的作品</div>
            </li>
            <!-- <li>
              <router-link
                v-if="status.ACCESS_TOKEN.length > 0"
                to="/IPreactor"
                >IP反应堆</router-link
              >
              <div v-else class="menuLogin" @click="loginClick">我的作品</div>
            </li> -->
            <li>
              <button class="button" @click="fkClick">反馈与建议</button>
            </li>
          </div>
          <div class="Vip" @click="godyClick">
            <div
              class="huiyuan"
              style="
                width: 115px;
                height: 20px;
                display: inline-block;
                text-align: center;
                font-size: 12px;
                color: #000;
                line-height: 32px;
                cursor: pointer;
              "
            >
              {{
                this.$store.state.user.level_id === 1
                  ? "基础会员"
                  : this.$store.state.user.level_id === 2
                  ? "进阶会员"
                  : this.$store.state.user.level_id === 3
                  ? "高级会员"
                  : this.$store.state.user.level_id === 4
                  ? "超级会员"
                  : "会员充值"
              }}
            </div>
          </div>
          <!-- <div class="Vip" @click="godyClick">{{this.vipone === 1 ? "基础" : 
            (this.vipone === 2 ? "进阶" : (this.vipone === 3? "高级" : (this.vipone === 4? "超级" : "超级")))}}</div> -->
          <div class="member">
            <div class="my-cash" v-show="denglu" @click="cashClick">
              <i></i>胶片：<b>{{ this.$store.state.user.brush_num || "0" }}</b>
            </div>

            <div class="member-info">
              <div class="nickname">{{ this.$store.state.user.name }}</div>
              <div v-if="this.$store.state.user.avatar" class="head-img">
                <el-dropdown
                  trigger="click"
                  placement="bottom-start"
                  style="width: 45px; height: 45px"
                >
                  <img
                    :src="this.$store.state.user.avatar"
                    width="100%"
                    height="100%"
                    style="object-fit: cover"
                  />
                  <el-dropdown-menu slot="dropdown" :split-button="true">
                    <el-upload
                      class="upload-demo"
                      action="api/user/updateAvatar"
                      :headers="{ ['Authorization']: 'Bearer ' + token2 }"
                      name="avatar"
                      :show-file-list="false"
                      :on-success="handleAvatarSuccess"
                      :on-error="handleAvatarError"
                      :before-upload="beforeAvatarUpload"
                    >
                      <el-button size="small" type="primary"
                        >修改头像</el-button
                      >
                    </el-upload>
                    <el-dropdown-item
                      @click.native="changeName"
                      >修改昵称</el-dropdown-item
                    >
                    <el-dropdown-item
                      @click.native="clearExit"
                      v-loading.fullscreen.lock="fullscreenLoading"
                      >退出登录</el-dropdown-item
                    >
                  </el-dropdown-menu>
                </el-dropdown>
              </div>
            </div>
          </div>
        </div>
      </div>
      <router-view
        v-if="updateViewStatus"
        @homeLoginClick="homeLoginClick"
      ></router-view>
    </div>

    <div class="login-cover" v-if="isShowLogin">
      <div class="login-box">
        <div class="login-ad"></div>
        <div class="login-content">
          <div class="close-login" @click="closeLogin">X</div>
          <div class="login-method">
            <li class="tab-selected">扫码登录</li>
            <!-- <li>手机登录</li> -->
          </div>
          <div class="login-area wechat">
            <div class="wechat-secode">
              <div
              v-loading="QRcodeloading"
                class="wechat-secodeurl"
                ref="qrCodeUrl"
                :style="{ backgroundImage: 'url(' + loginUrl + ')' }"
              ></div>
              <!-- <img :src="require('../../assets/img/yantoo-qrcode.png')" /> -->
            </div>
            <p>打开 微信 扫一扫登录</p>
          </div>
          <div class="agreement">
            <!-- <div
              :class="isXieyi ? 'icon-check' : 'icon-check-empty'"
              class="xieyi-K"
              @click="xieyiAgreeClick"
            ></div> -->
            登录即同意颜途AI实验室
            <div @click="xieyiClick()" class="color-a2">用户服务协议</div>
            <!-- <div @click="xieyiClick()" class="color-a2">隐私协议</div>、 -->
            <!-- <div @click="xieyiClick()" class="color-a2">知识产权声明</div> -->
          </div>
        </div>
      </div>
    </div>
    <fk
      v-show="isfkShow"
      :fkclose="fkclose"
      :fkSuccess="fkSuccess"
      class="fk"
    ></fk>
    <!-- <div v-show:="isShowLoginTips" style="width: 300px;height: 300px;background-color: rgba(red, green, blue, 0.5);">
      请先阅读并勾选协议后在进行登录
    </div> -->

    <cz-list
      v-if="isShowCzList"
      :hidden-cz-list="hiddenCzList"
      :gocz-click="goczClick"
      :gody-click="godyClick"
    ></cz-list>
    <cz
      v-show="isCz"
      :gody-click="godyClick"
      :cancel="czCancel"
      :czFinish="czFinish"
    ></cz>
    <czVip
      v-show="isCzVip"
      :cancel="czCancel"
      :czFinish="czFinish"
      :loginClick="loginClick"
    ></czVip>
    <xieyi v-show="isShowXieyi" :close="closeXieyi"></xieyi>
    <tip
      v-show="dialogVisible"
      :tiptext="tiptext"
      :torf="torf"
      :gbtip="gbtip"
    ></tip>
    <name
    v-show="isName"
    :cancel="czCancel"
    :getUserInfo="getUserInfo"
    ></name>
  </div>
</template>
  
  <script>
import QRCode from "qrcodejs2";
import api from "@/api/index";
import czList from "@/components/czList.vue";
import Cz from "@/components/cz.vue";
import xieyi from "@/components/xieyi.vue";
import czVip from "@/components/czVip.vue";
import fk from "@/components/fk.vue";
import tip from "@/components/tip.vue";
import name from "@/components/change.vue"
export default {
  components: { czList, Cz, xieyi, czVip, fk, tip ,name},
  data() {
    return {
      status: {
        isLoading: false,
        ACCESS_TOKEN: "",
      },
      ruleForm: {
        pass: "",
        checkPass: "",
        age: "",
      },
      isShow: false,
      isfkShow: false,
      isghAvatar: false,
      QRcodeloading: false,
      userInfo: {},
      name: "",
      avatar: "",
      token1: "",
      token2: "",
      timer: "",
      updateViewStatus: true,
      isShowCzList: false,
      isCz: false,
      isCzVip: false,
      isShowLogin: false,
      isShowXieyi: false,
      isShowLoginTips: false,
      isXieyi: true,
      denglu: false,
      isName:false,
      loginUrl: "",
      dialogVisible: false,
      tiptext: "",
      torf: 1,
      fullscreenLoading: false,
      aiChatindex:null,
      isMobile: navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      ),
    };
  },
  created() {
    localStorage.setItem("aiChat",0);
    this.aiChatindex = localStorage.getItem("aiChat");
    this.status.ACCESS_TOKEN = this.$store.state.token;
    // localStorage.removeItem('token')
    console.log("是否登录", localStorage.getItem("token"));
    var token = localStorage.getItem("token");
    this.token2 = token;
    this.status.ACCESS_TOKEN = token || "";
    if (token == "" || token == null) {
      this.denglu = false;
      this.getLoginToken();
    } else {
      this.getUserInfo();
      this.getChargeConf();
      this.denglu = true;
    }
    // console.log(!localStorage.getItem("aiChat"));
    this.isShowLogin = !this.status.ACCESS_TOKEN;

    var user = JSON.parse(localStorage.getItem("userInfo"));
    if (user) {
      this.name = user.name;
      this.avatar = user.avatar;
    }
    window.addEventListener('storage', this.onStorageChange);
    // setTimeout(() => {
    //     console.log('假装登录成功');
    //     this.login()
    // }, 50000);

    // window.addEventListener("message", this.receiveMessage, false);
  },
  methods: {
    onStorageChange(event) {
      if(event.key === "aiChat" && this.aiChatindex != localStorage.getItem("aiChat") ){
        this.getUserInfo();
        this.aiChatindex = localStorage.getItem("aiChat");
      }
    },
    changeName(){
      this.isName = true;
    },
    changeAvatar() {
      this.isghAvatar = true;
    },
    showLogin() {
      this.isShow = !this.isShow;
    },
    fkClick() {
      this.isfkShow = true;
      this.dialogVisible = false;
    },
    fkSuccess(text, torf) {
      this.dialogVisible = true;
      this.tiptext = text;
      this.torf = torf;
    },
    fkclose() {
      this.isfkShow = false;
    },
    submitLogin() {
      this.isShow = false;
    },
    homeLoginClick() {
      this.loginClick();
    },
    gbtip() {
      this.dialogVisible = false;
    },
    clearExit() {
      this.fullscreenLoading = true;
      const storage = window.localStorage;
      const keysToKeep = ["datalist", "chatStorage"];
      for (let i = 0; i < storage.length; i++) {
        const key = storage.key(i); // 获取当前索引的键
        // 检查键是否在保留列表中，如果不是，则删除它
        if (!keysToKeep.includes(key)) {
          storage.removeItem(key); // 删除该项
          i--; // 由于删除了一个项，所以索引需要减1以保持正确
        }
      }
      // localStorage.clear();
      setTimeout(() => {
        location.reload();
        this.fullscreenLoading = false;
      }, 1000);
    },
    loginClick() {
      this.isShowLogin = true;
      clearInterval(this.timer);
      this.getLoginToken();
    },
    closeLogin() {
      this.isShowLogin = false;
    },
    xieyiClick() {
      this.isShowXieyi = true;
    },
    closeXieyi() {
      console.log("点击了");
      this.isShowXieyi = false;
    },
    xieyiAgreeClick() {
      this.isXieyi = !this.isXieyi;
      if (!this.isXieyi) {
        this.isShowLoginTips = true;
      } else {
        this.isShowLoginTips = false;
      }
    },
    homeClick() {},
    async getLoginToken() {
      this.QRcodeloading = true
      let res = await api.getLoginToken();
      let data = await api.getLoginQr();
      console.log("请求登录token：", res, data);
      console.log(
        data.data.url + "&token=" + data.data.token,
        "data.data.url+ +data.data.token"
      );

      // this.token1 = data.data.token;
      // this.creatQrCode(
      //   "https://www.pixmorph.cn/api/wxLogin?token=" + res.data.token
      // );

      this.token1 = data.data.token;
      // let loginurl = data.data.url;
      this.loginUrl = data.data.url + "&token=" + data.data.token;
      this.QRcodeloading = false
      // this.creatQrCode(
      //   data.data.url+"&token=" +data.data.token
      // );
      this.checkToken();
    },
    creatQrCode(url) {
      // console.log("二维码链接", urls);
      // let url = urls.toString();
      var that = this;
      setTimeout(() => {
        new QRCode(that.$refs.qrCodeUrl, {
          text: url, //需要转换为二维码的内容
          width: 100, //二维码宽度
          height: 100, //二维码高度
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //后景色
          correctLevel: QRCode.CorrectLevel.L, //容错级别
        });
      }, 0);
    },

    checkToken() {
      var that = this;
      this.timer = setInterval(() => {
        that.checkTokenStatus();
      }, 3000);
    },
    async checkTokenStatus() {
      let res = await api.checkTokenStatus(this.token1);
      if (res.data.is_expire == true) {
        // 过期
        clearInterval(this.timer);
      }
      if (res.data.is_login == true) {
        // 登录
        this.isShowLogin = false;
        clearInterval(this.timer);
        this.login(res.data.user);
        this.dialogVisible = true;
        this.tiptext = "登录成功";
        this.torf = 1;
        this.denglu = true;
        this.updateViewStatus = false;
        var that = this;
        setTimeout(() => {
          that.updateViewStatus = true;
        }, 0);
      }
    },

    login(user) {
      // var data = {"ret":0,
      // "msg":"SUCCESS",
      // "data":{
      //     "name":"Health",
      //     "avatar":"https://thirdwx.qlogo.cn/mmopen/vi_32/Q0j4TwGTfTJxKfVict2Tzc7CLOmvvQsl54NYpmzDux2uMAoqqCYptQuibrpBticTqrPxf2w55ZricKGc1Ttu9MunMQ/132",
      //     "token":"TLnidBYFwPHVATdJ34aAKTlIBQRCjzc5HadhGEMTMB5Cu9geJQiKh14gz6lh"
      //     }
      // }
      this.status.ACCESS_TOKEN = user.token || "";
      // setLocal('token', this.status, 1000 * 60 * 60)
      localStorage.setItem("token", user.token);
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.name = user.name;
      this.avatar = user.avatar;

      this.getUserInfo();
      this.getChargeConf();
    },

    async getUserInfo() {
      let res = await api.getUserInfo();
      this.userInfo = res.data;
      localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
      this.$store.commit("updateUser", this.userInfo);
    },
    async getChargeConf() {
      let res = await api.getChargeConf();
      console.log("充值配置", res);

      this.$store.commit("updateGetChargeConf", res.data);

      console.log("获取保存的充值配置", this.$store.state.getChargeConf);
    },
    cashClick() {
      this.isShowCzList = true;
    },
    hiddenCzList() {
      this.isShowCzList = false;
    },
    goczClick() {
      this.isCz = true;
      this.isShowCzList = false;
    },
    async godyClick() {
      this.isShowCzList = false;
      this.isCzVip = true;
      const data = await api.levelList();
      console.log(data);
    },
    czCancel() {
      this.isCz = false;
      this.isCzVip = false;
      this.isName = false;
    },
    czFinish() {
      this.getUserInfo();
      // this.isCz = false;
      this.isCzVip = false;
    },
    async handleAvatarSuccess() {
      this.dialogVisible = true;
      this.tiptext = "修改成功";
      this.torf = 1;
      // this.uploadphotoId.push(response.data.aiImg)
      // let reader = new FileReader();
      // let imgResult = "";
      // reader.onload = function () {
      //   imgResult = reader.result;
      // };
      // reader.onloadend = function () {
      //   this.showPhoto = imgResult;
      // };
      // this.showPhoto = this.typePhoto.aiImg;
      this.avatar = "";
      this.getUserInfo();
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      if (!isJPG && !isPNG) {
        this.dialogVisible = true;
        this.tiptext = "上传图片只能是 JPG 或 PNG 格式!";
        this.torf = 0;
        this.isimg = false;
        return;
      }
      if (!isLt2M) {
        this.dialogVisible = true;
        this.tiptext = "上传图片大小不能超过 2MB!";
        this.torf = 0;
        this.isimg = false;
        this.fullscreenLoading = false;
      }
      return isLt2M;
    },

    handleAvatarError() {
      this.tiptext = "上传失败，请检查图片重新上传";
      this.torf = 0;
      this.isimg = false;
    },
  },
  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>
  
  <style>
@import "../../styles/layout_page.css";
</style>