<template>
  <div>
    <div class="nav">
        <div class="nav-icon" @click="back()"></div>
        <div class="nav-title">选择你的头像</div>
    </div>
    <div class="line"></div>

    <div class="headView">
        <div class="headView-left1">
                        <el-upload class="headView-addImg"
                        ref="upload"
                        action="https://www.pixmorph.cn/api/did/uploadStreamImage"
                        :headers="{'Authorization': 'Bearer ' + token}"
                        :data="{name:inputText2,desc:inputContent2,id:id}"
                        :on-change="getFile"
                        :show-file-list="false"
                        :on-success="handleAvatarSuccess"
                        :before-upload="beforeAvatarUpload"
                        :limit="1"
                        :auto-upload="false">
                        <div class="headView-left"></div>
                        </el-upload>
        </div>
        <div class="headCell"  v-for="(item, index) in items" :key="index" @click="itemClick(item)">
            <div class="headCell-img" :class="s_photo == item? 'headCell-selected2':''">
                <img :src="item.img_url" alt="">
            </div>
            <div :class="item.name? s_photo == item? 'headCell1-title-s':'' :'headCell1-title-n'" class="headCell-title">{{item.name}}</div>
        </div>
     </div>

     <input v-show="s_photo == null || s_photo.user_id != '0'" class="input" @input="clickAction()" ref="in" v-model="inputText2" type="text" placeholder="输入名称" />
     <textarea v-show="s_photo == null || s_photo.user_id != '0'" class="textarea" @input="click2Action()" ref="in2" v-model="inputContent2" placeholder="描述你的头像" maxlength="1200"></textarea>
     <span v-show="s_photo == null || s_photo.user_id != '0'" class="cell2-tv-length">{{ inputContent2.length }}/1200</span>

     <!-- <div class="sex"> -->
        <div class="sexView">
        <div class="sexView-sex"></div>
        <div class="sexView-text">男</div>
        <div class="sexView-icon"></div>
     </div>
     <div class="sh-lang-block">
        <div class="sh-ccselected" :class="s_sx == item? 'sh-ccselected-s':''" v-for="(item, index) in sx.male" :key="index">
        
                <li class="sh-ccselected-li" :class="s_sx == item? 'sh-selected':''" @click="sxClick(item)">{{ item.name }}
                    <span data-v-ba72652f="" class="play-demo-audio" @click.stop="audioClick(item)">
                        <img data-v-ba72652f="" v-show="!isPlayAudio || s_sx_play != item" src="https://www.kreadoai.com/img/business/sound-normal.png" alt="" class="sound-normal-hover">
                        <img data-v-ba72652f="" v-show="isPlayAudio && s_sx_play == item" src="https://www.kreadoai.com/img/business/audio-playing.png" alt="" class="sound-normal">
                       
                        <!-- <img data-v-ba72652f="" v-show="!isPlayAudio" src="https://www.kreadoai.com/img/business/sound-normal.png" alt="" class="sound-normal-hover">
                        <img data-v-ba72652f="" v-show="isPlayAudio && s_sx_play == item" src="https://www.kreadoai.com/img/business/audio-playing.png" alt="" class="sound-normal"> -->
                        <!-- <img data-v-ba72652f="" v-show="isPlayAudio" src="/img/business/sound-hover.png" alt="" class="sound-hover el-tooltip__trigger el-tooltip__trigger"> -->
                    </span>
                </li>
        </div>
                <audio src="https://aigc-cdn.kreadoai.com/default_voice/audio/2023/7/a48fb443259c4339875f0423be8e887b.mp3" ref="audioPlayer" @ended="audioEnded" id="eventAudio"></audio>
                <!-- <li>成熟男子</li> audio_url-->
    </div>
     <!-- </div> -->
     <div style="clear:both;"></div>
    <!-- <div class="sex"> -->
        <div class="sexView">
        <div class="sexView-sex-nv"></div>
        <div class="sexView-text">女</div>
        <div class="sexView-icon"></div>
     </div>
     <div class="sh-lang-block">
        <div class="sh-ccselected" :class="s_sx == item? 'sh-ccselected-s':''" v-for="(item, index) in sx.female" :key="index">
        
                <li class="sh-ccselected-li" :class="s_sx == item? 'sh-selected':''"  @click="sxClick(item)">{{ item.name }}
                    <span data-v-ba72652f="" class="play-demo-audio" @click.stop="audioClick(item)">
                        <img v-show="!isPlayAudio || s_sx_play != item" src="https://www.kreadoai.com/img/business/sound-normal.png" alt="" class="sound-normal-hover">
                        <img v-show="isPlayAudio && s_sx_play == item" src="https://www.kreadoai.com/img/business/audio-playing.png" alt="" class="sound-normal">
                       
                        <!-- <img data-v-ba72652f="" v-show="!isPlayAudio" src="https://www.kreadoai.com/img/business/sound-normal.png" alt="" class="sound-normal-hover">
                        <img data-v-ba72652f="" v-show="isPlayAudio && s_sx_play == item" src="https://www.kreadoai.com/img/business/audio-playing.png" alt="" class="sound-normal"> -->
                        <!-- <img data-v-ba72652f="" v-show="isPlayAudio" src="/img/business/sound-hover.png" alt="" class="sound-hover el-tooltip__trigger el-tooltip__trigger"> -->
                    </span>
                </li>
         </div>
                <audio src="https://aigc-cdn.kreadoai.com/default_voice/audio/2023/7/a48fb443259c4339875f0423be8e887b.mp3" ref="audioPlayer" @ended="audioEnded" id="eventAudio"></audio>
                <!-- <li>成熟男子</li> -->
    </div>
    <!-- </div> -->
    <div style="clear:both;"></div>
    <div class="saveBtn" @click="saveClick">
        <div class="saveTitle"></div>
    </div>
  </div>
</template>

<script>
import  api  from "@/api/index";
export default {
    props:['back','sx','inputText','inputContent'],
    data(){
        return{
            token:'',
            items:[],
            // items:['1','2','3','4','5','6','7','8','9'],
            s_photo:null,
            inputText2:'',
            inputContent2:'',
            // sx:['1','2','3','4','5','6','7','8','9'],
            s_sx:null,
            isPlayAudio:false,
            audioOpen:false,
            imageUrl:null,
            file:null,
            id:0,
        }
    },
    created(){
        this.token = localStorage.getItem('token')
        this.getStreamImage()
    },
    methods:{
        async getStreamImage(){
            let res = await api.getStreamImage()
            console.log('头像配置：', res)
            this.items = res.data
        },
        saveClick(){
            console.log('他提交');
            if (this.s_photo.user_id == '0') {
                this.$emit('saveClick',this.s_photo)
                return
            }
            if (this.id != 0) {
                this.uploadStreamImage()
                return
            }
            this.$refs.upload.submit();
        },
        getFile(file,fileList){
            console.log(file,fileList)
            this.file = file

            this.getBase64(file.raw)
            
            // this.uploadStreamImage()
        },
        getBase64(file) {
            var that = this
            return new Promise(function (resolve, reject) {
                let reader = new FileReader();
                let imgResult = "";
                reader.readAsDataURL(file);
                reader.onload = function () {
                    imgResult = reader.result;
                };
                reader.onerror = function (error) {
                    reject(error);
                };
                reader.onloadend = function () {
                    that.uploadPic = imgResult
                    that.items.splice(0, 0, {img_url:imgResult});
                    that.id = 0
                    that.s_photo = that.items[0]
                    resolve(imgResult);
                };
            });
        },
        async uploadStreamImage(){
            let res = await api.uploadStreamImage({name:this.inputText2,desc:this.inputContent2,id:this.id})
            console.log('上传图片--',res)
            if (res.ret == 0) {
                this.$emit('saveClick',res.data)
                this.getStreamImage()
            }
        },
        handleAvatarSuccess(res, file) {
            console.log(this, '上传图片', res, res.data)
            console.log(JSON.stringify(res.data))
            this.$emit('saveClick',res.data)
            this.imageUrl = URL.createObjectURL(file.raw);
            // console.log('地址：',imageUrl)
            this.imageUrl = res.data.image_url
            this.getStreamImage()
            this.$notify({
                title: '成功',
                message: '上传成功',
                type: 'success'
            });
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === 'image/jpeg';
            const isPNG = file.type === 'image/png';
            const isLt2M = file.size / 1024 / 1024 < 2;

            console.log('文件类型',file.type)
            if (!isJPG && !isPNG) {
              this.$notify.error('上传图片只能是 JPG 或 PNG 格式!');
              return
            }
            if (!isLt2M) {
                this.$notify.error('上传图片大小不能超过 2MB!');
            }
            return isLt2M;
        },
        clickAction() {
            this.$emit('inputTitle', this.$refs.in.value);
        },
        click2Action() {
            this.$emit('inputContent', this.$refs.in2.value);
        },

        itemClick(item){
            this.s_photo = item;
            this.s_head = item;
            console.log(item);
            this.$emit('headClick',item)
            this.id = item.id
            this.inputText2 = item.name;
            this.inputContent2 = item.desc || ''
        },
        sxClick(item){
            this.s_sx = item;
            this.s_sy = item;
            this.$emit('syClick',item)
        },
        audioClick(item){
            console.log('声音播放',item)
            if (this.audioOpen == true && this.s_sx_play == item) {
                console.log('暂停')
                this.isPlayAudio = false
                this.$refs.audioPlayer.pause();
                this.audioOpen = false
            }else{
                this.isPlayAudio = false
                this.audioOpen = false
                this.s_sx_play = item
                this.isPlayAudio = true
                this.$refs.audioPlayer.src = item.audio_url;
                this.$refs.audioPlayer.load();
                this.$refs.audioPlayer.play();
                this.audioOpen = true
            }
        },
         //监听播放完成的回调
        audioEnded() {
            this.isPlayAudio = false
            this.audioOpen = false
            console.log('end')
        },
    }   
}
</script>

<style>
.nav{
    display: flex;
    align-items: center;
    margin-top: 38px;
    margin-bottom: 40px;
}
.nav-icon{
    background: url('/src/assets/img/AIChat/fanhui.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
}
.nav-title{
    font-size: 18px;
    color: #000;
    font-weight: 800;
}
.line{
	height: 1px;
	background-image: linear-gradient(to right, rgb(188, 199, 232) 0%, rgb(238, 238, 238) 50%, transparent 50%);
	background-size: 20px 1px;
	background-repeat: repeat-x;
}



.headView{
    display: grid;
    grid-template-columns: repeat(auto-fill,110px);
    justify-content: space-between;
    gap: 0px;
    padding: 0 20px;
    padding-bottom: 28px;
    margin-top: 20px;
}
.headView-left{
    background: url('/src/assets/img/AIChat/点击上传.png');
    background-size: cover;
    height: 110px;
    width: 110px;
    margin-top: 10px;
}
/* .headView-addImg{

} */
.headCell{
    /* float: left; */
    width: 110px;
    /* background-color: #FAFAFA; */
    /* margin-right: 10px; */
    margin-top: 10px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.headCell-img{
    width: 110px;
    height: 110px;
    border-radius: 50%;
    /* background-color: yellow; */
    object-fit: cover;
}
.headCell img{
    margin-top: 2px;
    margin-left: 2px;
    width: 106px;
    height: 106px;
    border-radius: 50%;
    /* background-size: contain;
      background-repeat: no-repeat; */
      object-fit: cover;
      background-color: #fff;
}
.headCell-title{
    font-size: 12px;
    color:#000;
    background-color: #EDEDED;
    font-weight: 800;
    width: 80px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    line-height: 24px;
    margin-top: -15px;
}
.headCell1-title-s{
    background-color: #F4C657;
}
.headCell1-title-n{
    opacity: 0;
}
.headCell-selected2{
    /* border: #000 0px solid; */
    /* box-sizing: border-box; */
    overflow: hidden;
    background-image: linear-gradient(to bottom, #D6F2D6 0%, #FC7C7D 50%, transparent 100%);

}
/* .headCell-selected img{
    height: 108px;
    object-fit: cover;
} */

.input{
    margin-left: 6%;
    width: 75%;
    height: 60px;
    border-radius: 16px;
    border: 1px solid #979797;
    padding: 0 28px;
    outline: none;
    resize: none;
}
.input:focus{
    border: 1px solid #000;
}
.textarea{
    margin-top: 20px;
    margin-left: 6%;
    width: 75%;
    min-width: 75%;
    height: 150px;
    max-height: 150px;
    border-radius: 16px;
    border: 1px solid #979797;
    padding: 20px 28px;
    outline: none;
    resize: none;
}
.textarea:focus{
    border: 1px solid #000;
}
.cell2-tv-length{
    font-size:12px;
    float:right;
    position:relative;
    right:10px;
    top:-35px;
    margin-right:30px;
}


.sex{
    width: 86%;
}
.sexView{
    display: flex;
    align-items: center;
    margin-top: 30px;
    margin-left: 30px;
}
.sexView-sex{
    background: url('/src/assets/img/AIChat/nan.png');
    background-size: cover;
    width: 24px;
    height: 24px;
}
.sexView-sex-nv{
    background: url('/src/assets/img/AIChat/nv.png');
    background-size: cover;
    width: 24px;
    height: 24px;
}
.sexView-text{
    font-size: 14px;
    color: #000;
    margin-left: 7px;
    margin-right: 3px;
}
.sexView-icon{
    background: url('/src/assets/img/AIChat/syIcon.png');
    background-size: cover;
    width: 14px;
    height: 14px;
}

.sh-lang-block {padding:15px; display: block; position:relative;}
        .sh-lang-block h1 {font-size:13px; margin:0 0 7px 0; padding:0 0 0px 5px; border-left: 2px solid #000;}
        .sh-lang-block h3 {font-size:13px; margin:0; padding:0 0 7px 7px; font-weight: 400}
        /* .lang-block li {float: left; padding: 5px 15px; margin: 8px 5px; background: #f2f2f5; border-radius: 30px; font-size: 12px; list-style: none; border:1px solid #f2f2f5; color:#555} */
.sh-ccselected-li{
    display: inline-block;
    height: 40px;
    line-height: 40px;
    padding: 0 20px; 
    /* margin: 8px 10px;  */
    background: #FAFAFA; 
    border-radius: 16px; 
    font-size: 12px; 
    list-style: none; 
    border:0px solid #f2f2f5; 
    color:#000;
    position: relative;
    /* transform: translateY(-1px); */
    margin: 0;
} 

.saveBtn{
    height: 70px;
    border-radius: 70px;
    background-color: #F4C657;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}
.saveTitle{
    background: url('/src/assets/img/AIChat/save.png');
    background-size: cover;
    width: 48px;
    height: 20px;
}



.sh-selected{
    background-color: #000;
    color: #fff;
}
.sh-ccselected {
      position: relative;
      border-radius: 10px;
      background: #fff; 
      background-clip: padding-box;
      border: 5px solid transparent;
      z-index: 1;

      display: inline-block;
    height: 40px;
    line-height: 40px;
    /* padding: 0 20px;  */
    margin: 8px 10px; 
    background: #FAFAFA; 
    border-radius: 16px; 
    font-size: 12px; 
    list-style: none; 
}
.sh-ccselected-s::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      bottom: 0;
      z-index: -1;
      margin: -2px;
      border-radius: 16px;
      background: linear-gradient(to bottom, #D6F2D6, #FC7C7D);
}

</style>