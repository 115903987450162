<template>
    <div>

    <div class="nav">
        <div class="nav-icon" @click="back()"></div>
        <div class="nav-title">选择你的头像</div>
    </div>
    <div class="line"></div>

    <div class="sh-content">
        <div class="headCell2">
            <div class="headCell2-img">
                <img :src="s_head.img_url" alt="">
            </div>
            <div class="headCell-title-s">{{ text }}</div>
     </div>

     <div class="sh-des">替换头像将开始一个新的聊天。重新开始?</div>

     <div class="btn1" @click="newClick()">是的，重新开始新的聊天</div>
     <div class="btn2" @click="cancelClick()">不，继续聊天</div>
    </div>


    </div>
</template>

<script>
export default {
    props:['back','newClick','cancelClick','s_head','s_sy','text','content'],
    data(){
        return{

        }
    }
}
</script>

<style>

.nav{
    display: flex;
    align-items: center;
    margin-top: 38px;
    margin-bottom: 40px;
}
.nav-icon{
    background: url('/src/assets/img/AIChat/fanhui.png');
    background-size: cover;
    width: 24px;
    height: 24px;
    margin-right: 20px;
    cursor: pointer;
}
.nav-title{
    font-size: 18px;
    color: #000;
    font-weight: 800;
}
.line{
	height: 1px;
	background-image: linear-gradient(to right, rgb(188, 199, 232) 0%, rgb(238, 238, 238) 50%, transparent 50%);
	background-size: 20px 1px;
	background-repeat: repeat-x;
}



.sh-content{
    display: flex;
    align-items: center;
    flex-direction: column;
}


.headCell2{
    /* float: left; */
    width: 150px;
    /* background-color: #FAFAFA; */
    /* margin-right: 10px; */
    margin-top: 50px;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.headCell2-img{
    width: 100%;
    height: 150px;
    border-radius: 50%;
    /* background-color: yellow; */
   
}
.headCell2 img{
    width: 100%;
    height: 100%;
    border-radius: 50%;
}
.headCell-title-s{
    font-size: 12px;
    color:#000;
    background-color: #EDEDED;
    font-weight: 800;
    /* width: 100px; */
    padding: 0 20px;
    height: 24px;
    border-radius: 24px;
    text-align: center;
    line-height: 24px;
    margin-top: -15px;
    background-color: #F4C657;
}
.headCell-selected{
    border: #000 1px solid;
    box-sizing: border-box;
    overflow: hidden;
}
.headCell-selected img{
    height: 108px;
}

.sh-des{
    margin-top: 30px;
    color: #000;
    font-size: 14px;
}

.btn1{
    margin-top: 30px;
    height: 50px;
    border-radius: 50px;
    background-color: #F4C657;
    line-height: 50px;
    width: 70%;
    text-align: center;
}
.btn2{
    margin-top: 20px;
    height: 50px;
    border-radius: 50px;
    background-color: #EDEDED;
    line-height: 50px;
    width: 70%;
    text-align: center;
    margin-bottom: 100px;
}

</style>