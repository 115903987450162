import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/page/layout/index.vue'
import Login from '@/page/login.vue'
import create from "@/page/create.vue";
import videoLibrary from "@/page/videoLibrary.vue";
import home from "@/page/home.vue";
import textToPicture from "@/page/textToPicture.vue";
import AIChat from "@/page/AIChat.vue";
import AIAnswer from "@/page/AIAnswer.vue";
import AIXiezhen from "@/page/Xiezhensc.vue";
import IPreactor from "@/page/IPreactor.vue"
Vue.use(VueRouter)

const routes = [
    {
        path:'/',
        component:Layout,
        redirect:'/home',
        children:[
            {
              path :'/home',
              name : 'home',
              component :home
            },
            {
              path :'/textToPicture',
              name : 'textToPicture',
              component :textToPicture
            },
              {
                path :'/create',
                name : 'create',
                component :create
              },
              {
                path :'/AIxiezhen',
                name : 'AIxiezhen',
                component :AIXiezhen
              },
              {
                path :'/AIChat',
                name : 'AIChat',
                component :AIChat
              },
              {
                path :'/AIAnswer',
                name : 'AIAnswer',
                component :AIAnswer
              },
              {
                path :'/videoLibrary',
                name :'videoLibrary',
                component :videoLibrary,
              },
              {
                path :'/IPreactor',
                name :'IPreactor',
                component :IPreactor,
              },
              
        ]
    },
    {
        path:'/login',
        name:'login',
        component:Login
    }

]

const router = new VueRouter({
    mode: 'hash',
    base:process.env.BASE_URL,
    routes
})

export default router
