<template>
    <div class="bgView">
      <div class="czBgView">
        <div class="czTitle">
          <div class="czTitle-t cz-title"></div>
          <div class="czTitle-close" @click="gbtipclick"></div>
        </div>
        <div class="line2"></div>
        <div class="scSuccessView">
          <div :class="torf ===1?'scSuccessView-imgs':'scLoseView-imgs'"></div>
          <div class="scSuccessView-des">{{tiptext}}</div>
        </div>
        <div class="btnss">
          <div v-if="torf !== 3" class="btn-r" @click="gbtipclick">确定</div>
          <div v-if="torf === 3" class="btn-r" @click="gbtipclick">去充值</div>
        </div>
      </div>
    </div>
  </template>
    
    <script>
  export default {
    // props: {
    //     cancelBtn: {
    //         type: Function,
    //         default: () => { }
    //     }
    // },
    props: ["gbtip", "tiptext",'torf',"gocz"],
    data() {
      return {};
    },
    created() {},
    methods: {
      gbtipclick(){
        if(this.torf === 3){
        this.gocz()}else{
          this.gbtip()
        }
      }
    },
  };
  </script>
    
    <style>
  .bgView {
    position: fixed;
    top: 0;
    z-index: 10;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
  .czTitle {
    width: 80%;
  }
  .cz-title {
    background: url("/src/assets/img/tipsTitle.png");
    background-size: cover;
    height: 20px;
    width: 50px;
  }
  .line2 {
    width: 80%;
    height: 1px;
    background-color: #d0c8c8;
  }
  .czBgView {
    background-color: #fff;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
    border-radius: 16px;
  }
  .scSuccessView {
    width: 500px;
    display: flex;
    /* flex-wrap: wrap; */
    margin-top: 30px;
    margin-bottom: 30px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .scSuccessView-imgs {
    background: url("/src/assets/img/scSuccess.png");
    background-size: cover;
    width: 60px;
    height: 60px;
  }
  .scLoseView-imgs {
    background: url("/src/assets/img/sclose.png");
    background-size: cover;
    width: 75px;
    height: 60px;
  }
  .scSuccessView-des {
    font-size: 16px;
    font-weight: 800;
    margin-top: 20px;
  }
  .czItem {
    width: 150px;
    height: 80px;
    background-color: #fafafa;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 20px;
    border: #f4c657 1px dashed;
    box-sizing: border-box;
  }
  .czItem-s {
    width: 150px;
    height: 80px;
    background-color: #ffedc1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-left: 10px;
    margin-top: 10px;
    border-radius: 20px;
    border: #f4c657 2px solid;
    box-sizing: border-box;
  }
  .czJP {
    display: flex;
    align-items: center;
    font-size: 30px;
  }
  .czJP-icon {
    background: url("/src/assets/img/cash.png");
    background-size: cover;
    width: 25px;
    height: 24px;
  }
  .btnss {
    height: 50px;
    width: 500px;
    margin-top: 20px;
    display: flex;
    font-size: 16px;
  }
  .btn-l {
    border-radius: 50px;
    flex: 1;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: #c7c7c7 1px solid;
    color: #c7c7c7;
    cursor: default;
  }
  .btn-r {
    border-radius: 50px;
    flex: 1;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4c657;
    cursor: default;
  }
  .czqr {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  
    position: fixed;
    background-color: #fff;
    width: 600px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-bottom: 30px;
    border-radius: 16px;
  }
  .qrcode2 {
    width: 150px;
    height: 150px;
    margin-top: 40px;
  }
  .qrDesc {
    font-size: 16px;
    color: #000;
    font-weight: 800;
    margin-top: 20px;
  }
  .czBtn {
    position: relative;
    height: 50px;
    width: 150px;
    top: 20px;
    border-radius: 50px;
    margin: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f4c657;
    color: #000;
    margin-bottom: 30px;
    cursor: default;
  }
  .czBtn:hover {
    letter-spacing: 3px;
    background: #f4c657;
    color: #fff(0, 0%, 100%);
    /* box-shadow: #f4c657 0px 7px 29px 0px; */
    transform: scaleX(1.05) scaleY(1.05);
  }
  
  .czBtn:active {
    letter-spacing: 3px;
    background: #f4c657;
    color: #fff(0, 0%, 100%);
    /* box-shadow: #f4c657 0px 0px 0px 0px; */
    transform: scaleX(0.9) scaleY(1.15);
    transition: 100ms;
  }
  </style>