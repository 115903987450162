
const base = {
    getVoices:'api/did/getVoices',
    getAListOfThePresenters:'api/did/getAListOfThePresenters',
    createAClip:'api/did/createAClip',
    getClips:'api/work/getList',
}





export default base