<template>
    <div>
        <div class="bg"></div>
        <div class="title1">
            <div class="t11">多种语高效</div>
            <div class="t12">AI</div>
            <div class="t13">创作平台</div>
        </div>
        <div class="title2">
            <div class="t21">毫不费力，</div>
            <div class="t22">你就是下一个创作高手</div>
        </div>
        <div class="cs">
            <div class="c c1">
                <img src="../assets/img/icon1.png" alt="">
                <div class="ct c1t">AI妙笔生画</div>
            </div>
            <div class="c c2">
                <img src="../assets/img/icon2.png" alt="">
                <div class="ct c2t">数字人创作</div>
            </div>
            <div class="c c3">
                <img src="../assets/img/icon3.png" alt="">
                <div class="ct c3t">图片会说话</div>
            </div>
            <div class="c c4">
                <img src="../assets/img/icon4.png" alt="">
                <div class="ct c4t">AI制作大片感写真</div>
            </div>
        </div>
        <div class="startCell">
            <div class="start">
                <!-- <router-link to="/create">开始制作</router-link> -->
                <router-link v-if="status.ACCESS_TOKEN.length > 0" to="/textToPicture">开始制作</router-link>
                <div v-else class="menuLogin" @click="beginClick()" style="width:100%;text-align:center">开始制作</div>
                
            </div>
        </div>
        <div class="bottom">
           <div class="b3">
                <div class="b31">AI帮助您快速</div>
                <div class="b32"> 提升 </div>
                <div class="b33">营销效果</div>
            </div>
            <div class="b2">
                <div class="b21">丰富的 </div>
                <div class="b22">AI编辑能力</div>
            </div>
            
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {
            status: {
                ACCESS_TOKEN: ''
            },
        }
    },
    created() {
        var token = localStorage.getItem('token')
        this.status.ACCESS_TOKEN = token || ''
    },
    methods: {
        beginClick(){
            console.log('开始制作loginClick',this)

            // this.receiveMessage()

            this.$emit('homeLoginClick')

            // window.postMessage('message','*','')
            // window.postMessage(message, targetOrigin, [transfer])
        },
        // window.postMessage(message, targetOrigin, [transfer])
    }
}
</script>

<style scoped>
* {
    cursor: default;
}
    .title1{
        width: 1280px; margin:0 auto; padding-top: 130px;
        display: flex;
       justify-content: center;
    }
    .title1 div{
        font-size: 16px;
        color: #fff;
    }
    .t12{
        border: #fff 1px solid;
        border-radius: 3px;
        width: 25px;
        height: 25px;
        margin: 0 6px;
       text-align: center;
       line-height: 25px;
       margin-top: -3px;
    }
    .title2{
        width: 1280px; margin:0 auto; padding-top: 26px;
        display: flex;
       justify-content: center;
       color: #fff;
    }
    .t21{
        font-size: 56px;
    }
    .t22{
        font-size: 34px;
        line-height: 102px;
    }
    .cs{
        width: 1280px; margin:0 auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        background: yellow;
        padding-top: 0px;
    }
    .c{
        display: flex;
        align-items: center;
        color: #fff;
       position: absolute;
      
    }
    .c1{
        top: 0;
        margin-right: 470px;
    }
    .c2{
        top: 27px;
        margin-left: 400px;
    }
    .c3{
        top:110px;
        margin-right: 450px;
    }
    .c4{
        top: 170px;
        margin-left: 250px;
    }
    .c1 img{
        width: 60px;
        height: 60px;
    }
    .c2 img{
        width: 86px;
        height: 86px;
    }
    .c3 img{
        width: 99px;
        height: 99px;
    }
    .c4 img{
        width: 54px;
        height: 54px;
    }
    .ct{
        background: rgba(0, 0, 0, 0.3);
        /* border-radius: 50% */
        position: relative;
        background-size: 50% 100%;
        background-repeat: no-repeat;
        text-align: center;
    }
    .c1t{
        width: 150px;
        height: 60px;
        background: radial-gradient(circle at -10px 30px, transparent 33px, rgba(0, 0, 0, 0.5) 33px) top left, radial-gradient(circle at 100px 25px, transparent 15px, rgba(0, 0, 0, 0) 16px) right top;
        margin-left: -18px;
        border-radius: 0 30px 30px 0;
        line-height: 60px;
        font-size: 17px;
        padding-left: 10px;
    }
    .c2t{
        width: 237px;
        height: 86px;
        background: radial-gradient(circle at -10px 43px, transparent 46px, rgba(0, 0, 0, 0.5) 33px) top left, radial-gradient(circle at 100px 25px, transparent 15px, rgba(0, 0, 0, 0) 16px) right top;
        margin-left: -30px;
        border-radius: 0 43px 43px 0;
        line-height: 86px;
        font-size: 28px;
        padding-left: 15px;
    }
    .c3t{
        width: 250px;
        height: 99px;
        background: radial-gradient(circle at -10px 49px, transparent 52px, rgba(0, 0, 0, 0.5) 33px) top left, radial-gradient(circle at 100px 25px, transparent 15px, rgba(0, 0, 0, 0) 16px) right top;
        margin-left: -35px;
        border-radius: 0 54.5px 54.5px 0;
        line-height: 99px;
        font-size: 31px;
        padding-left: 20px;
    }
    .c4t{
        width: 192px;
        height: 54px;
        background: radial-gradient(circle at -10px 27px, transparent 30px, rgba(0, 0, 0, 0.5) 27px) top left, radial-gradient(circle at 100px 25px, transparent 15px, rgba(0, 0, 0, 0) 16px) right top;
        margin-left: -15px;
        border-radius: 0 27px 27px 0;
        line-height: 54px;
        font-size: 16px;
        padding-left: 10px;
    }

    .startCell{
        width: 1280px; margin:0 auto; padding-top: 0px;
        display: flex;
       justify-content: center;
       margin-top: 300px;
    }
    .start{
        width: 196px;
        height: 66px;
        background: #fff;
        border-radius: 66px;
        display: flex;
        justify-content: center;
        line-height: 66px;
        color:#0E151F;
        font-size:16px;
        font-weight: 600;
        background: linear-gradient(to bottom, #ffffff, #BDBDBD);
        cursor: pointer;
    }
    .bottom{
        width: 1280px; margin:0 auto; padding-top: 0px;
        display: flex;
       justify-content: center;
       font-size: 14px;
       color: #fff;
       margin-top: 60px;
    }
    .b1,.b2,.b3{
        display: flex;
        align-items: center;
    }
    .b2{
        margin: 0 87px;
    }
    .b11,.b21,.b32{
        font-size: 18px;
        margin: 0 5px;
        margin-bottom: 4px;
    }
</style>