import axios from 'axios';
 
// 创建一个axios实例
const instance = axios.create({
  baseURL: '', // 设置请求的基础URL
  timeout: 120000, // 请求超时时间
  // headers: {
  //   "Content-Type": "application/json;"
  // }
});

// request拦截器
instance.interceptors.request.use(config => {
  console.log('有陶肯吗？',localStorage.getItem('token'))

  const token = localStorage.getItem('token')
  // 如果 token 存在
  // 让每个请求携带自定义 token 请根据实际情况自行修改
  if (token) {
      config.headers['Authorization'] = 'Bearer ' + token 
      config.headers.token = token
  }

  return config
})
 
// 封装GET请求
export function get(url, params) {
    console.log('开始',url)
  return instance.get(url, {
    params: params
  })
    .then(res => {
      console.log('请求一个',res.data.data)
      return res.data;
    })
    .catch(err => {
      console.log('请求一个err',err.response.request.status)
      if (err.response.request.status == 401) {
        localStorage.removeItem('token')
      }
      console.error(err);
    });
}
 
// 封装POST请求
export function post(url, data) {
  return instance.post(url, data,)
    .then(res => {
      console.log(res);
      return res.data;
    })
    .catch(err => {
      if (err.response.request.status == 401) {
        localStorage.removeItem('token')
      }
      console.error(err);
      
    });
}
 
// 封装其他HTTP请求方法如PUT、DELETE等也是类似的
 
export default {
  get,
  post,
};
