import api from "@/api/index";
import QRCode from "qrcodejs2";
import czView from "@/components/cz.vue";
import xieyi from "@/components/xieyi.vue";
import scz from "@/components/shengchengzhongs.vue";
import scSuccess from "@/components/scSuccess.vue";
import deleteview from "@/components/deleteAlert.vue";
import tip from "@/components/tip.vue";
import czVip from "@/components/czVip.vue";
import _ from "lodash";

export default {
  components: {
    czView,
    xieyi,
    scz,
    scSuccess,
    deleteview,
    tip,
    czVip
  },
  data() {
    return {
      token: "",
      sczloadingcount: "~",
      sczloadingcounts: "~",
      timefloors: Math.floor(Math.random() * 6) + 15,
      timerandoms: null,
      thimers: null,
      form: {
        title: "",
        content: "",
        price: null,
      },
      items: [],
      selectedItem: {
        image_url: "",
        image_url_upload: "",
      },
      imageUrl: "",
      yz: [], // 语种
      sx: [], // 声线
      yd: [], // 语调
      s_yz: "",
      s_sx: "",
      s_yd: "",
      mansx: [],
      wmansx: [],
      allsx: [],
      sxindex: 1,
      fullscreenLoading: false,
      isPlayAudio: false,
      audioOpen: false,
      s_sx_play: "",
      isCz: false,
      isShowXieyi: false,
      isScz: false,
      isSuccess: false,
      isShowDelete: false,
      deleteItem: null,
      deleteIndex: 0,
      isCzVip: false,
      dialogVisible: false,
      tiptext: "",
      torf: 1,
      talk_clips_deductvalue: 10,
    };
  },
  created() {
    this.token = localStorage.getItem("token");
    this.getImages(0);
    this.getVoices();
    this.getChargeConfs();
    console.log(
      "配置图片会说话：",
      this.$store.state.getChargeConf.talk_clips_deduct
    );
    // this.form.price =
    // this.$store.state.getChargeConf.talk_clips_deduct.value || 10;
  },
  methods: {
    async getChargeConfs() {
      const data = await api.getChargeConf();
      this.form.price = data.data.talk_clips_deduct.value || 10;
    },
    gocz() {
      this.isCz = true;
      this.dialogVisible = false;
    },
    soundGender(value) {
      this.sxindex = value;
      if (value === 1) {
        this.sx = this.allsx;
      }
      if (value === 2) {
        this.sx = this.mansx;
        this.s_sx = this.sx[0];
      }
      if (value === 3) {
        this.sx = this.wmansx;
        this.s_sx = this.sx[0];
      }
    },
    async getVoices(dic) {
      let res = await api.getAllLang(dic);
      console.log("请求声音列表：", res);

      var arr = res.data;
      this.yz = arr;
      this.s_yz = this.yz[0];
      this.getVoiceList({ lang_id: this.s_yz.id });
    },
    async getVoiceList(dic) {
      let res = await api.getVoiceList(dic);
      var arr = res.data || null;
      this.allsx = [];
      this.wmansx = [];
      this.mansx = [];
      for (let index = 0; index < arr.length; index++) {
        this.allsx.push(arr[index]);
        if (arr[index].gender === "female") {
          this.wmansx.push(arr[index]);
        } else if (arr[index].gender === "male") {
          this.mansx.push(arr[index]);
        }
      }
      this.sxindex = 1;
      this.sx = arr;
      this.s_sx = this.sx[0];
      this.getLabelList({ id: this.s_sx.id });
    },
    async getLabelList(dic) {
      let res = await api.getLabelList(dic);
      var arr = res.data;
      this.yd = arr;
      this.s_yd = this.yd[0];
    },
    async godyClick() {
      this.isCz = false;
      this.isShowCzList = false;
      this.isCzVip = true;
      const data = await api.levelList();
      console.log(data);
    },
    async getAListOfThePresenters() {
      let res = await api.getAListOfThePresenters();
      console.log("请求人物列表：", res);
      this.items.push(...res.data);
      // this.getImages()
    },
    gbtip() {
      this.dialogVisible = false;
    },
    async getImages(type) {
      // let res = await api.getimages()
      let res = await api.getPhoto(type);
      console.log("请求自己图片列表：", res);

      this.items = [];
      // this.items.unshift(...res.data)
      let array = res.data;
      for (let index = 0; index < array.length; index++) {
        const element = array[index];
        var item = {
          type: -2,
          image_url: element.talk_url,
          image_url_upload: element.talk_url,
          id: element.id,
          talk_id: element.talk_id,
          user_id: element.user_id,
        };
        // this.items.splice(0, 0, item);
        this.items.push(item);
      }
      this.selectedItem = this.items[0];

      if (type == 1) {
        this.photoClick(this.items[0]);
      }

      // this.items.push(...res.data)
      // this.getAListOfThePresenters()
    },
    photoClick(res) {
      this.selectedItem = res;
    },
    deletePhoto(item, index) {
      this.isShowDelete = true;
      this.deleteItem = item;
      this.deleteIndex = index;

      // this.$confirm('此操作将永久删除该图片, 是否继续?', '提示', {
      //     confirmButtonText: '确定',
      //     cancelButtonText: '取消',
      //     type: 'warning'
      // }).then(() => {
      //     this.deleteImage(item.id,index)

      // }).catch(() => {
      //     this.$notify({
      //         type: 'info',
      //         message: '已取消删除'
      //     });
      // });
    },
    deleteClick() {
      this.deleteImage(this.deleteItem.id, this.deleteIndex);
    },
    async deleteImage(id, index) {
      this.isShowDelete = false;
      // let res = await api.deleteImage(id)
      let res = await api.deleteImageV3(id);
      if (res != undefined) {
        this.items.splice(index, 1);
        this.dialogVisible = true;
        this.tiptext = "删除成功";
        this.torf = 1;
      }
    },
    handleAvatarSuccess(res, file) {
      this.fullscreenLoading = false;
      this.imageUrl = URL.createObjectURL(file.raw);
      if (res.ret != 0) {
        this.dialogVisible = true;
        this.tiptext = "上传失败";
        this.torf = 0;
        return;
      }
      this.imageUrl = res.data.image_url;
      this.getImages(1);
      this.dialogVisible = true;
      this.tiptext = "上传成功";
      this.torf = 1;

      // setTimeout(() => {
      //   console.log(this,'更改')
      //   // this.items = [{'id':'-1'}]
      //   this.getImages();
      // }, 2000);
    },
    handleAvatarError() {
      this.fullscreenLoading = false;
      this.dialogVisible = true;
      this.tiptext = "上传失败";
      this.torf = 0;
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === "image/jpeg";
      const isPNG = file.type === "image/png";
      const isLt2M = file.size / 1024 / 1024 < 2;

      console.log("文件类型", file.type);
      if (!isJPG && !isPNG) {
        this.dialogVisible = true;
        this.tiptext = "上传图片只能是 JPG 或 PNG 格式!";
        this.torf = 0;
        return;
      }
      if (!isLt2M) {
        this.dialogVisible = true;
        this.tiptext = "上传图片大小不能超过 2MB!";
        this.torf = 0;
      }
      this.fullscreenLoading = false;
      return isLt2M;
    },

    yzClick(r) {
      this.s_yz = r;
      // this.getVoices2({ 'sound_ray':r.name })
      this.getVoiceList({ lang_id: r.id });
    },
    async getVoices2(dic) {
      let res = await api.getVoices(dic);
      var arr = res.data.sound_ray;
      this.sx = arr;
      this.s_sx = this.sx[0];
      this.getVoices3({ sound_ray: this.s_sx.name });
    },
    sxClick(r) {
      this.s_sx = r;
      // this.getVoices3({ 'sound_ray':r.name })
      this.getLabelList({ id: r.id });
    },
    audioClick(item) {
      if (this.audioOpen == true && this.s_sx_play == item) {
        this.isPlayAudio = false;
        this.$refs.audioPlayer.pause();
        this.audioOpen = false;
      } else {
        this.$refs.audioPlayer.src = item.demo_voice;
        this.isPlayAudio = true;
        this.$refs.audioPlayer.load();
        this.$refs.audioPlayer.play();
        this.audioOpen = true;
      }
      this.s_sx_play = item;
    },
    //监听播放完成的回调
    audioEnded() {
      this.isPlayAudio = false;
      this.audioOpen = false;
    },

    async getVoices3(dic) {
      let res = await api.getVoices(dic);
      var arr = res.data.sound_ray;
      this.yd = arr;
      this.s_yd = this.yd[0];
    },
    ydClick(r) {
      this.s_yd = r;
    },

    createVideo: _.throttle(function () {
      if (this.selectedItem.image_url.length == 0) {
        this.dialogVisible = true;
        this.tiptext = "请选择一个图片";
        this.torf = 0;
        return;
      }
      if (this.form.content.length == 0) {
        this.dialogVisible = true;
        this.tiptext = "请输入文字内容";
        this.torf = 0;
        return;
      }
      if (this.form.title === "") {
        this.dialogVisible = true;
        this.tiptext = "请输入作品名称";
        this.torf = 0;
        return;
      }
      if (this.$store.state.user.brush_num > 1) {
        this.getDriverListOfAnActor();
      } else {
        this.dialogVisible = true;
        this.tiptext = "哦，糟糕！您的胶片不足了，快去充值吧";
        this.torf = 3;
      }

      // this.getCzQrcode()
      // this.getDriverListOfAnActor()
    }, 3000),

    async getCzQrcode() {
      let res = await api.pay("1");

      var url = res.data.url;
      this.isCz = true;

      var that = this;
      setTimeout(() => {
        new QRCode(that.$refs.qrCodeUrl, {
          text: url, //需要转换为二维码的内容
          width: 200, //二维码宽度
          height: 200, //二维码高度
          colorDark: "#000000", //前景色
          colorLight: "#ffffff", //后景色
          correctLevel: QRCode.CorrectLevel.L, //容错级别
        });
      }, 0);
    },

    async getDriverListOfAnActor() {
      // this.fullscreenLoading = true
      var data = {
        script: {
          type: "text",
          input: this.form.content,
          ssml: "false",
          provider: {
            voice_id: this.s_yd,
            voice_name: this.s_sx,
            voice_language: this.s_yz,
          },
        },
        title: this.form.title,
        presenter_id: this.selectedItem.presenter_id,
        driver_id: this.selectedItem.driver_id,
      };
      if (this.selectedItem.type == -2) {
        // 用上传自己的图片
        data = {
          script: {
            type: "text",
            input: this.form.content,
            ssml: "false",
            provider: {
              voice_id: this.s_yd,
              voice_name: this.s_sx,
              voice_language: this.s_yz,
            },
          },
          title: this.form.title,
          // "presenter_id": this.selectedItem.presenter_id,
          // "driver_id": this.selectedItem.driver_id,
          source_url: this.selectedItem.image_url_upload,

          // 'source_url':'https://create-images-results.d-id.com/auth0|64e2bb572e263855d3486631/upl_CgZsipUdGwT42tqxcyPQ5/thumbnail.jpeg'
        };
        data = {
          voice_id: this.s_sx.voice_id,
          input: this.form.content,
          title: this.form.title,
          talk_id: this.selectedItem.talk_id,
          style: this.s_yd.name,
        };
      }
      this.isScz = true;
      let prams = null;
      if (this.selectedItem.type == -2) {
        // 用上传自己的图片
        // res = await api.createATalk(data)
        prams = await api.createPhotoVideo(data);
      } else {
        prams = await api.createAClip(data);
      }
      this.fullscreenLoading = false;
      if (prams == undefined) {
        this.dialogVisible = true;
        this.tiptext = "生成失败";
        this.torf = 0;
        this.isScz = false;
        clearInterval(this.thimers);
        this.isSuccess = false;
        return;
      }
      if (prams.msg == "SUCCESS" || prams.msg == "success") {
        console.log(prams)
        this.sczloadingcount = prams.data.time.toFixed();
        this.thimers = setInterval(() => {
          // 每秒将this.sczloadingcount减1
          this.sczloadingcount -= 1;
          console.log(this.sczloadingcount);
          // 如果this.sczloadingcount大于或等于0
          if (this.sczloadingcount >= 0) {
            // 计算剩余分钟数和秒数
            const minutes = Math.floor(this.sczloadingcount / 60);
            const seconds = this.sczloadingcount % 60;
            // 在这里更新你的UI来显示分钟和秒数
            this.sczloadingcounts = `剩余时间: ${minutes}分钟 ${seconds}秒`
            console.log(`剩余时间: ${minutes}分钟 ${seconds}秒`);
          } else {
            // 当倒计时结束时，清除定时器
            clearInterval(this.thimers);
            // 在这里处理倒计时的结束
            this.cancelClick();
          }
        }, 1000);

        this.timerandoms = setInterval(() => {
          this.timefloors = this.timefloors - (Math.floor(Math.random() * 3) + 1);
          this.timefloors <= 1 ? clearInterval(this.timerandoms) : '';
          this.timefloors == 1 ? this.timefloors = 0 : '';
          // timenum = timenum-1;
          if (this.timefloor <= 1) {
            this.timefloors = Math.floor(Math.random() * 6) + 15
            this.cancelClick();
          }
        }, 5000);
        let res = await api.getWorkList({});
        if (res.data[0].error_info === "" && res.data[0].video_url != "") {
          this.getUserInfo();
          this.isScz = false;
          clearInterval(this.thimers);
          this.isSuccess = true;
        } else if (res.data[0].error_info === "" && res.data[0].video_url === "") {
          console.log("生成中");
        }
        else {
          this.isScz = false;
          clearInterval(this.thimers);
          this.isSuccess = false;
          this.dialogVisible = true;
          this.tiptext = "生成失败，请重新尝试";
          this.torf = 0;
        }
        // this.$confirm('生成成功，是否去个人中心查看?', '提示', {
        //     confirmButtonText: '确定',
        //     cancelButtonText: '取消',
        //     type: 'success',
        //     center: true
        // }).then(() => {
        //     this.$router.push({ name: 'videoLibrary', query: {} })
        // }).catch(() => {

        // });
      } else {
        this.dialogVisible = true;
        this.tiptext = "哦，糟糕！您的胶片不足了，快去充值吧";
        this.isScz = false;
        clearInterval(this.thimers);
        this.isSuccess = false;
        this.torf = 3;
      }
      // this.items = res.data
    },
    formatTime(time) {
      const minutes = Math.floor(time / 60);
      const seconds = time % 60;
      return `${minutes}分钟 ${seconds}秒`;
    },
    updateCountdown() {
      this.remainingTime -= 1;
      if (this.remainingTime < 0) {
        this.remainingTime = 0;
      }
    },
    scSussessClick() {
      this.isSuccess = false;
      this.$router.push({ name: "videoLibrary", query: { id: 1 } });
    },
    cancelClick() {
      this.isScz = false;
      clearInterval(this.timefloors)
      clearInterval(this.thimers);
    },
    okClick() {
      this.isScz = false;
      this.$router.push({ name: "videoLibrary", query: { id: 1 } });
    },

    async getUserInfo() {
      let res = await api.getUserInfo();
      var user = res.data;
      localStorage.setItem("userInfo", JSON.stringify(user));
      this.$store.commit("updateUser", user);
    },

    czCancel() {
      this.isCz = false;
      this.isCzVip = false;
      this.isShowXieyi = false;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "auto";
      this.isSuccess = false;
      this.isShowDelete = false;
    },
    czFinish() {
      console.log("充值完成");
    },
    xieyiClick() {
      this.isShowXieyi = true;
      var body = document.getElementsByTagName("body")[0];
      body.style.overflow = "hidden";
    },
  },
};